import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Button } from 'reactstrap';
import { userLogout, setAuthUser } from '../../actions/userActions';
import {
  clearMemberPortalData,
  isClientLogout,
} from '../../actions/memberPortalActions';
import { ROUTE_LOGIN, ROUTE_HOME } from '../../constants/routes';
import DentiModal from '../common/DentiModal';
import { strings } from '../../constants/localization';
import {
  timeoutMinutesSessionExpiration,
  timeoutMinutesSessionWarningShow,
  clientPortalTimeoutSessionExpiration,
  config
} from '../../config';
import { isAuthenticated } from '../../constants/utils';
interface SessionTimeoutProps {
  clientPortal?: boolean;
}
interface SessionTimeoutState {
  showWarning?: boolean;
  isSessionExpired?: boolean;
}
interface leadClientInformationProps {
  leadClientInformation: {
    cpr: string;
  };
}
interface activeCompaniesOnExpiredSessionProps {
  referenceNumber: string;
}
interface activeChainOnExpiredSessionProps {
  cvrNumber: string;
}
interface ativeCreditApplicationsOnExpiredSessionProps {
  creditApplication: {
    creditApplicationUuid: string;
  };
}
interface activeDocumentationOnExpiredSessionProps {
  documents: [
    {
      documentationUuid: string;
    }
  ];
}
const SessionTimeout: React.FC<SessionTimeoutProps> = ({
  clientPortal = false,
}) => {

  const [state, setState] = useState<SessionTimeoutState>({
    showWarning: false,
    isSessionExpired: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const activeLeadsOnExpiredSession = useSelector(
    (state: { lead: { activeLead: leadClientInformationProps } }) =>
      state.lead?.activeLead
  );
  const activeCompaniesOnExpiredSession = useSelector(
    (state: {
      company: { activeCompany: activeCompaniesOnExpiredSessionProps };
    }) => state.company?.activeCompany
  );
  const activeChainOnExpiredSession = useSelector(
    (state: {
      companyChain: { activeChain: activeChainOnExpiredSessionProps };
    }) => state.companyChain?.activeChain
  );
  const activeCreditApplicationsOnExpiredSession = useSelector(
    (state: {
      onboardingApplication: {
        activeApplicationListItem: ativeCreditApplicationsOnExpiredSessionProps;
      };
    }) => state.onboardingApplication?.activeApplicationListItem
  );
  const activeDocumentationOnExpiredSession = useSelector(
    (state: {
      onboardingDocumentation: {
        activeDocListItem: activeDocumentationOnExpiredSessionProps;
      };
    }) => state.onboardingDocumentation?.activeDocListItem
  );
  // eslint-disable-next-line no-undef
  let warningSessionExpiredInterval = useRef<NodeJS.Timeout>();
  let timeoutAfterClearingStorage = useRef<number>();
  const sessionExpirationTime = clientPortal
    ? clientPortalTimeoutSessionExpiration
    : timeoutMinutesSessionExpiration;

  useEffect(() => {
    warningSessionExpiredInterval.current = setInterval(() => {
      if (!state.showWarning || !state.isSessionExpired) {
        let lastLoginTimestamp: string = localStorage.getItem('loginTime');
        if (clientPortal) {
          lastLoginTimestamp = localStorage.getItem('loginTimeMit');
        }
        const now = moment(new Date());
        const lastLoginDate = moment.unix(Number(lastLoginTimestamp) / 1000);
        const diff = moment.duration(now.diff(lastLoginDate));
        const minPast =
          diff.days() * 24 * 60 + diff.hours() * 60 + diff.minutes();
        const minutesBeforeExpiration = sessionExpirationTime - minPast;

        if (
          minutesBeforeExpiration <= timeoutMinutesSessionWarningShow &&
          minutesBeforeExpiration > 0
        ) {
          if (!clientPortal) {
            storeSession();
          }
          setState((prevState) => ({
            ...prevState,
            showWarning: true,
            isSessionExpired: false,
          }));
        } else if (minutesBeforeExpiration < 0) {
          setState((prevState) => ({
            ...prevState,
            showWarning: true,
            isSessionExpired: true,
          }));
        }
      }
    }, 60 * 1000);
    return () => {
      clearTimeout(timeoutAfterClearingStorage.current);
      clearInterval(warningSessionExpiredInterval.current);
      setState({});
    };
  }, []);

  useEffect(() => {
    if (!clientPortal) {
      if (isAuthenticated()) {
        dispatch(setAuthUser());
      } else {
        setTimeout(() => {
          history.push(ROUTE_LOGIN);
        }, 0);
      }
    }
    const handleStorageEvent = (event: StorageEvent) => {
      switch (event.key) {
        case 'loginTime':
          if (event.newValue && event.newValue !== event.oldValue) {
            localStorage.setItem('loginTime', event.newValue);
          }
          break;
        case 'isAuth':
          if (event.newValue && event.newValue !== event.oldValue) {
            localStorage.setItem('isAuth', event.newValue);
            if (JSON.parse(event.newValue) === true) {
              dispatch(setAuthUser(true));
            }
          }
          break;
        case 'isSessionExpiredModalDismissed':
          if (event.newValue && event.newValue !== event.oldValue) {
            localStorage.setItem(
              'isSessionExpiredModalDismissed',
              event.newValue
            );
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('storage', handleStorageEvent);
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    // Check if the modal has been dismissed in any tab
    const handleVisibilityChange = () => {
      const isModalDismissed = JSON.parse(
        localStorage.getItem('isSessionExpiredModalDismissed') || 'false'
      );
      if (!document.hidden && state.showWarning && isModalDismissed) {
        const now = moment.now();
        const isAuth = JSON.parse(localStorage.getItem('isAuth') || 'false');
        const loginTime = JSON.parse(localStorage.getItem('loginTime') || '0');
        const isUserAuthenticated =
          isAuth === true &&
          loginTime &&
          now - loginTime < sessionExpirationTime * 60 * 1000;
        if (isUserAuthenticated) {
          setState({
            showWarning: false,
            isSessionExpired: false,
          });
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [state.showWarning]);

  const storeSession = useCallback(() => {
    const locationPathName = history.location.pathname;
    switch (locationPathName) {
      case '/leads':
        if (activeLeadsOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeLeadsOnExpiredSession?.leadClientInformation?.cpr}`
          );
        }
        break;
      case '/companies':
        if (activeCompaniesOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeCompaniesOnExpiredSession?.referenceNumber}`
          );
        }
        break;
      case '/companyChains':
        if (activeChainOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeChainOnExpiredSession?.cvrNumber}`
          );
        }
        break;
      case '/creditApplications':
        if (activeCreditApplicationsOnExpiredSession) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeCreditApplicationsOnExpiredSession?.creditApplication?.creditApplicationUuid}`
          );
        }
        break;
      case '/documentation':
        if (
          activeDocumentationOnExpiredSession?.documents?.length > 0 &&
          activeDocumentationOnExpiredSession.documents[0]?.documentationUuid
        ) {
          sessionStorage.setItem(
            'lastVisitedPath',
            `${locationPathName}?q=${activeDocumentationOnExpiredSession.documents[0]?.documentationUuid}`
          );
        }
        break;
      default:
        sessionStorage.setItem('lastVisitedPath', `${locationPathName}`);
        break;
    }
  }, [
    activeLeadsOnExpiredSession,
    activeCompaniesOnExpiredSession,
    activeChainOnExpiredSession,
    activeCreditApplicationsOnExpiredSession,
    activeDocumentationOnExpiredSession,
    history.location.pathname,
  ]);

  const logoutClientPortal = () => {
    dispatch(isClientLogout(true));
    localStorage.removeItem('loginTimeMit')
    if (window.location.hostname === 'localhost') {
      history.push(ROUTE_HOME);
      dispatch(clearMemberPortalData());
    } else {
      dispatch(clearMemberPortalData());
      setTimeout(() => {
        window.location.href = config.damAppMainPageUrl;
      }, 200);
    }
  };

  const handleClose = () => {
    localStorage.setItem('isSessionExpiredModalDismissed', 'true');
    setState({
      showWarning: false,
    });

    if (clientPortal) {
      logoutClientPortal();
    } else if (!state.isSessionExpired) {
      dispatch(userLogout());
      // Delay navigation to ensure it happens after the render phase
      setTimeout(() => {
        history.push(ROUTE_LOGIN);
      }, 0);
    } else {
      if (warningSessionExpiredInterval.current) {
        clearInterval(warningSessionExpiredInterval.current);
      }
      dispatch(setAuthUser(false));
      // Delay navigation to ensure it happens after the render phase
      setTimeout(() => {
        history.push(ROUTE_LOGIN);
      }, 0);
    }

  };

  if (state.showWarning) {
    return (
      <DentiModal
        close={handleClose}
        modalClassName='server-error'
        modalClass='warning-modal modal-dialog-centered'
      >
        <div>
          <div className='icon'>
            <i className='lnil lnil-warning' />
          </div>
          <div>
            <h3 className='headline'>{strings.sessionExpiredHeadline}</h3>
            <p className='warning-message'>{strings.sessionExpiredText}</p>
          </div>
          <div className='bottom-section action-btn cf'>
            <Button onClick={handleClose} className='login-button btn btn-blue'>
              {strings.goToLogin}
            </Button>
          </div>
        </div>
      </DentiModal>
    );
  } else {
    return null;
  }
};
export default SessionTimeout;
