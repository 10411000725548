import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  error as notifError,
  removeAll,
} from 'react-notification-system-redux';
import Fade from '../common/Fade';
import { Card } from 'reactstrap';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { strings } from '../../constants/localization';
import { ROUTE_ONBOARDING } from '../../constants/routes';
import './style.scss';
import {
  storeOnboardingData,
  storeOnboardingStep,
  storeOnboardingVisitedSteps,
  populateOnboardinDataBasedCreditApplication,
  getPublicCreditApplicationDataForMonthio,
  clearOnboardingData,
  storeTriggeredPopAllowedAction,
} from '../../actions/onboardingActions';
import { setServerModalError } from '../../actions/uiElementsActions';
import { connectWebsocket } from '../../actions/websocketActions';
import Notifications from 'react-notification-system-redux';
import styleNotification from '../../constants/styleNotification';
import {
  getErrorEndpointName,
  getOnboardingErrorReasonString,
} from '../../constants/utils';

import JoinSundhed from './JoinSundhed';
import ExistingMember from './ExistingMember';
import ExistingMemberStartOnboarding from './ExistingMemberStartOnboarding';
import CprNumber from './CprNumber';
import Acommodation from './monthioFlow/Acommodation';
import HowManyKids from './HowManyKids';
import HowTheApplicationWorks from './HowTheApplicationWorks';
import ConditionsForCredit from './ConditionsForCredit';
import MembershipConditions from './MembershipConditions';
import MembershipCreated from './MembershipCreated';
import TechnicalError from './TechnicalError';
import DeniedCreditOffer from './DeniedCreditOffer';
import LoadingSpinner from './common/LoadingSpinner/LoadingSpinner';
import ConfirmEmail from './ConfirmEmail';
import RequestedAmount from './RequestedAmount';
import CheckEskatConsent from './CheckEskatConsent';
import InvalidLinkForOnboarding from './InvalidLinkForOnboarding';
import RestartOnboardingModal from './RestartOnboardingModal';
import CivilStatus from './monthioFlow/CivilStatus';
import RedirectToMonthio from './monthioFlow/RedirectToMonthio';
import EvaluateBudget from './monthioFlow/EvaluateBudget';
import CheckDebtorRegister from './monthioFlow/CheckDebtorRegister';
import ManualIntervention from './monthioFlow/ManualIntervention';
import ServerUnavailableModal from './ServerUnavailableModal';
import { notificationDismissDuration } from '../../constants';
import ConfirmEmailAlreadyConfirmed from './stepErrors/ConfirmEmailAlreadyCofirmed';
import ConfirmEmailInvalidLink from './stepErrors/ConfirmEmailInvalidLink';
import CreateMemberError from './stepErrors/CreateMemberError';
import UploadDocumentation from './upload-documentation/UploadDocumentation';
import PowerOfAttorney from './PowerOfAttorney';

export default function Onboarding() {
  const currentDomain = window.location.hostname;
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const savedData = useSelector((state) => state.onboarding?.data);
  const savedStep = useSelector((state) => state.onboarding?.currentStep);
  const previousVisitedStep = useSelector(
    (state) => state.onboarding?.previousVisitedStep
  );
  const [step, setStep] = useState(
    savedStep || savedStep === 0 ? savedStep : 1
  );
  const [showServerUnavailableModal, setShowServerUnavailableModal] =
    useState(false);
  const serverErrorDetails = useSelector(
    (state) => state.ui?.serverErrorDetails
  );
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const lastStepStored =
    visitedSteps && visitedSteps.length > 0
      ? visitedSteps[visitedSteps.length - 1]
      : 0;
  const manualInterventionSentEmail = useSelector(
    (state) => state.onboarding?.manualInterventionSentEmail
  );
  const creditApplicationAbortedApplication = useSelector(
    (state) => state.onboarding?.creditApplicationAbortedApplication
  );
  const creditApplicationDeniedApplication = useSelector(
    (state) => state.onboarding?.creditApplicationDeniedApplication
  );
  const finishedApplication =
    manualInterventionSentEmail ||
    creditApplicationDeniedApplication ||
    creditApplicationAbortedApplication;
  const triggeredPopAllowed = useSelector(
    (state) => state.onboarding?.triggeredPopAllowed
  );

  const [linkTag, setLinkTag] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [loadingCreditApplicationData, setLoadingCreditApplicationData] =
    useState(false);
  const [showInvalidLink, setShowInvalidLink] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [reloadStep, setReloadStep] = useState(false);
  const [showEmailAlreadyConfirmed, setShowEmailAlreadyConfirmed] =
    useState(false);
  const [showEmailInvalidLink, setShowEmailInvalidLink] = useState(false);
  const [createMemberError, setCreateMemberError] = useState(false);
  const [websocketClient, setWebsocketClient] = useState(null);
  const [creditExpirationDate, setCreditExpirationDate] = useState(null);
  const [isOnboardingExistingMember, setIsOnboardingExistingMember] =
    useState(false);

  const notAllowedToReturnSteps = [
    4,
    5,
    7,
    10,
    11,
    12,
    14,
    15,
    16,
    17,
    'denied',
    'error',
  ];

  const handleBlockedNavigation = (location, action) => {
    if (action === 'POP' && notAllowedToReturnSteps.includes(step)) {
      dispatch(
        notifError({
          title: '',
          message: strings.withBackCheckMessage,
          position: 'tl',
          autoDismiss: notificationDismissDuration,
        })
      );
      dispatch(storeTriggeredPopAllowedAction(false));
      if (!finishedApplication && step > 7 && step !== 9) {
        setShowRestartModal(true);
      }
      return false; // Prevent navigation
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        if (notAllowedToReturnSteps.includes(step)) {
          history.push({
            pathname: location.pathname,
            search: location.search,
          });

          setStep(lastStepStored);
        } else {
          dispatch(storeTriggeredPopAllowedAction(true));
          const stepNumberFromUrl = parseInt(
            new URLSearchParams(location.search).get('step'),
            10
          );
          // set the number from url to match the step
          setStep(stepNumberFromUrl);
        }
      } else {
        dispatch(storeTriggeredPopAllowedAction(false));
      }
    });

    return () => {
      unlisten(); // Cleanup the listener
    };
  }, [step, history]);

  useEffect(() => {
    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');
    document.body.classList.add('onboarding-flow');
    document.body.classList.add('loading');

    // detect if the user are using a IOS device
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      // Add the class name 'onboarding' with height 100vh and remove 'height: auto'
      const onboardingElement = document.querySelector('.onboarding');
      const actionsButton = document.querySelector('.actions-buttons ');

      if (onboardingElement) {
        onboardingElement.style.height = '100vh';
      }
      if (actionsButton) {
        // add margin bottom 80px to the actions button
        actionsButton.style.marginBottom = '80px';
      }
    }

    // window.addEventListener('popstate', handleNavigation);

    return () => {
      const onboardingElement = document.querySelector('.onboarding');
      const actionsButton = document.querySelector('.actions-buttons ');

      if (onboardingElement) {
        onboardingElement.style.height = 'auto';
      }
      if (actionsButton) {
        actionsButton.style.marginBottom = '0px';
      }
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
      document.body.classList.add('onboarding-flow');
      // window.removeEventListener('load', handleNavigation);
      // disconnect websocket connection after unmount
      if (websocketClient && websocketClient?.connected) {
        websocketClient.disconnect();
      }
    };
  }, []);

  const getMonthioPublicCreditApplicationData = (applicationUuid) => {
    setLoadingCreditApplicationData(true);
    dispatch(getPublicCreditApplicationDataForMonthio(applicationUuid))
      .then((res) => {
        const creditApplicationData = res.payload?.data;
        if (creditApplicationData) {
          dispatch(
            populateOnboardinDataBasedCreditApplication(creditApplicationData)
          );
        }
      })
      .finally(() => {
        setLoadingCreditApplicationData(false);
      });
  };

  const loadInitialStepBasedOnPath = useCallback(() => {
    const url = new URL(window.location.href);
    const stepNumberFromUrl = parseInt(url.searchParams.get('step'), 10) || 0;
    const tagFromUrl =
      url.searchParams.get('tag') ||
      url.searchParams.get('source') ||
      savedData?.linkTag ||
      'sundhedplus.dk';
    const companyIdFromUrl =
      url.searchParams.get('companyId') ||
      url.searchParams.get('clinic') ||
      savedData?.companyId ||
      'X001';
    const leadUuidFromUrl = url.searchParams.get('leadUuid');
    const memberUuidFromUrl = url.searchParams.get('memberUuid');
    const creditApplicationUuidFromUrl = url.searchParams.get('caUuid');
    const codeFromUrl = url.searchParams.get('code');
    const userIdFromUrl = url.searchParams.get('userId');
    const memberStatus = decodeURIComponent(
      url.searchParams.get('memberStatus')
    );
    const creditExpirationDate = url.searchParams.get('creditExpirationDate');
    setCreditExpirationDate(creditExpirationDate);

    let confirmEmailData = null;

    const handleDeniedOrErrorUrl = () => {
      // we don't have a step into the url and the application was finished, then clear redux data and go to step 1
      if (finishedApplication) {
        dispatch(clearOnboardingData());
        setStep(1);
      } else if (step > 7) {
        setStep(step);
        setShowRestartModal(true);
      }
    };

    const handleStepUpdateUrl = (stepNumberFromUrl) => {
      // step from url is updated but differnt that the one stored in redux  and there's onboarding data stored, then show restart modal
      if (stepNumberFromUrl === 1 && step !== 1 && !finishedApplication) {
        setShowRestartModal(true);
      }
      // these steps are allowed to be accessed directly from the url
      if ([1, 3, 4, 10, 15, 18].includes(stepNumberFromUrl)) {
        setStep(stepNumberFromUrl);
      }
    };

    //if creditApplicationUuidFromUrl is present and no saved data, then we need to fetch the credit application data
    const handleCreditApplicationUrl = () => {
      if (
        creditApplicationUuidFromUrl &&
        stepNumberFromUrl === 10 &&
        !savedData?.creditApplicationUuid
      ) {
        getMonthioPublicCreditApplicationData(creditApplicationUuidFromUrl);
        setShowRestartModal(false);
        setStep(10);
      } else if (stepNumberFromUrl === 10 && savedData?.creditApplicationUuid) {
        setStep(10);
      } else if (
        !stepNumberFromUrl ||
        stepNumberFromUrl === 'denied' ||
        stepNumberFromUrl === 'error'
      ) {
        handleDeniedOrErrorUrl();
      } else {
        handleStepUpdateUrl(stepNumberFromUrl);
      }
    };

    const handleUserIdUrl = () => {
      const fetchedUserId = userIdFromUrl?.match(/\[([^\]]+)\]/)?.[1];
      if (fetchedUserId && !savedData?.creditApplicationUuid) {
        getMonthioPublicCreditApplicationData(fetchedUserId);
      }
      setShowRestartModal(false);
      setStep(15);
    };

    const handleConfirmEmailCodeUrl = () => {
      const firstName = decodeURIComponent(url.searchParams.get('firstname'));
      const lastName = decodeURIComponent(url.searchParams.get('lastname'));
      const email = decodeURIComponent(url.searchParams.get('email'));
      const phoneNumber = decodeURIComponent(url.searchParams.get('phone'));
      const uuid = decodeURIComponent(url.searchParams.get('uuid'));

      confirmEmailData = {
        firstName,
        lastName,
        email: decodeURIComponent(email),
        phoneNumber,
        memberStatus,
        emailIsConfirmed: savedData?.emailIsConfirmed || false, // if the email is already confirmed, then use that
        confirmEmailCode: decodeURIComponent(codeFromUrl),
        uuid,
        linkTag: decodeURIComponent(tagFromUrl),
        companyId: decodeURIComponent(companyIdFromUrl),
      };
      dispatch(
        storeOnboardingData({
          ...savedData,
          confirmEmailData,
        })
      );
    };

    // this function is handling the onboarding for existing clients that are renewing their credit and redirecting to step 4
    const handleExistingClientLinkOnboarding = async () => {
      try {
        // Dispatch the initial action and await its result
        const { payload } = await dispatch(
          storeOnboardingData({
            ...savedData,
            uuid: leadUuidFromUrl,
            linkTag: decodeURIComponent(tagFromUrl),
            companyId: decodeURIComponent(companyIdFromUrl),
            emailIsConfirmed: savedData?.emailIsConfirmed || true,
            memberStatus: 'EXISTING_CLIENT',
          })
        );

        // If there is a valid payload, dispatch the action again with updated data
        if (payload) {
          await dispatch(
            storeOnboardingData({
              ...savedData,
              uuid: payload.uuid,
              linkTag: payload.linkTag,
              companyId: payload.companyId,
              emailIsConfirmed: payload.emailIsConfirmed,
              memberStatus: 'EXISTING_CLIENT',
            })
          );
        }

        // Finally, set the step to 4
        setStep(4);
      } catch (error) {
        console.error('An error occurred during onboarding:', error);
      }
    };

    const handleExistingMemberLinkOnboarding = () => {
      try {
        dispatch(
          storeOnboardingData({
            ...savedData,
            uuid: memberUuidFromUrl,
            emailIsConfirmed: true,
            memberStatus: 'EXISTING_MEMBER',
          })
        );
        setIsOnboardingExistingMember(true);
        // Finally, set the step to 4
        setStep(4);
      } catch (error) {
        console.error(
          'An error occurred during onboarding existing member:',
          error
        );
      }
    };

    const updateLinkTagAndCompanyId = () => {
      setLinkTag(tagFromUrl);
      setCompanyId(companyIdFromUrl);

      dispatch(
        storeOnboardingData({
          ...(confirmEmailData || savedData),
          linkTag: decodeURIComponent(tagFromUrl),
          companyId: decodeURIComponent(companyIdFromUrl),
        })
      );
    };

    // if we have the code from url, then we need to confirmEmail by url redirect
    // onboarding?step=3&firstname=xx&lastname=xx&phone=xx&email=xx&code=xxxx&uuid=xxxxx
    if (codeFromUrl) {
      handleConfirmEmailCodeUrl();
    }

    // if is credit to renew, then we need to handle it differently the customer will always have access trought a link called forny.sundhedplus....
    // but we handle that on APP that or at client portal that we redirect to https://kredit.sundhedplus.dk/onboarding?step=4&tag=forny&companyId=X001&leadUuid=xxxxxx&creditExpirationDate=xxxx-xx-xx
    if (
      (tagFromUrl === 'forny' && leadUuidFromUrl) ||
      (stepNumberFromUrl === 4 && tagFromUrl === 'forny' && leadUuidFromUrl)
    ) {
      handleExistingClientLinkOnboarding();
    } else if (
      (tagFromUrl === 'kredit' && memberUuidFromUrl) ||
      (stepNumberFromUrl === 4 && tagFromUrl === 'kredit' && memberUuidFromUrl)
    ) {
      handleExistingMemberLinkOnboarding();
    } else if (
      (step === 1 || stepNumberFromUrl === 1) &&
      tagFromUrl === 'forny' &&
      !leadUuidFromUrl
    ) {
      // if we do not have the leadUuid in the url, then we need to handle it sending to step 1 clearing redux and setting the tag and companyId to not be forny
      setLinkTag('sundhedplus.dk');
      setCompanyId(companyIdFromUrl);
      setTimeout(() => {
        dispatch(
          storeOnboardingData({
            linkTag: 'sundhedplus.dk',
            companyId: companyIdFromUrl,
          })
        );
      }, 10);
      setStep(1);
      dispatch(
        storeOnboardingData({
          ...(confirmEmailData || savedData),
          linkTag: 'sundhedplus.dk',
          companyId: companyIdFromUrl,
        })
      );
      // reload the url with the correct tag and companyId
      history.push({
        pathname: '/onboarding',
        search: `?step=${step}&tag=sundhedplus.dk&companyId=${companyIdFromUrl}`,
      });

      return;
    }

    // if we have the credit application uuid in the url, then we need to handle it
    handleCreditApplicationUrl();

    // if step after Monthio we need to handle the userId from url
    if (userIdFromUrl) {
      handleUserIdUrl();
    }

    // if we have tag and companyId in the url, we need to store them
    if (
      tagFromUrl !== savedData?.linkTag ||
      companyIdFromUrl !== savedData?.companyId
    ) {
      updateLinkTagAndCompanyId();
    }
  }, []);

  useEffect(() => {
    //load this only on the inital render
    loadInitialStepBasedOnPath();
  }, [loadInitialStepBasedOnPath]);

  useEffect(() => {
    if (serverErrorDetails) {
      const status = serverErrorDetails?.status;
      const { message, cause } = serverErrorDetails.data || {};
      const serverIsDown =
        typeof serverErrorDetails !== 'object' ||
        serverErrorDetails === 'Network Error';
      const endpointName = getErrorEndpointName(serverErrorDetails);
      const simpleStringError = getOnboardingErrorReasonString(
        cause ?? message
      );

      if (status === 400) {
        if (
          step === 15 ||
          (endpointName === 'checkEskatConsent' &&
            simpleStringError === 'Cpr missmatch')
        ) {
          // Do nothing in this case, because is handled inside component

          return;
        }
        if (
          (step === 3 && cause?.includes('Incorrect confirmation code')) ||
          step === 8
        ) {
          // Do nothing in this case, because is handled inside component
        } else if (step >= 10) {
          // we only redirect to credit denied step after the member has been converted to client
          // Credit Denied

          if (!endpointName || endpointName !== 'denied') {
            setStep(0);
          } else {
            setStep(null);
          }
        } else if (endpointName === 'createMember') {
          setCreateMemberError(true);
        } else {
          setStep(null);
        }
      } else if (
        serverIsDown ||
        (status === 500 &&
          (message?.includes('EntityManagerFactory is closed') ||
            cause?.includes('EntityManagerFactory is closed')))
      ) {
        // for these steps we need to reload in order to trigger again the request that happen on mount
        if ([10, 15, 16, 17].includes(step)) {
          setReloadStep(true);
        } else {
          setReloadStep(false);
        }
        setShowServerUnavailableModal(true);
      } else if (status === 500 && endpointName === 'confirmEmail') {
        // this means the code was already confirmed by email link, then we show ConfirmEmailAlreadyConfirmed
        const emailAlreadyConfirmedByTyping =
          localStorage.getItem('emailConfirmedByTyping') === 'true';
        const emailConfirmedByLink =
          localStorage.getItem('emailConfirmedByLink') === 'true';
        if (emailConfirmedByLink && !emailAlreadyConfirmedByTyping) {
          setShowEmailAlreadyConfirmed(true);
        } else if (
          (!emailAlreadyConfirmedByTyping && !emailConfirmedByLink) ||
          (!emailConfirmedByLink && emailAlreadyConfirmedByTyping)
        ) {
          setShowEmailInvalidLink(true);
        }
      } else if (status === 500 && endpointName === 'createMember') {
        setCreateMemberError(true);
      } else {
        // Credit Aborted
        setStep(null);
      }
    }
  }, [serverErrorDetails]);

  const redirectToOnboardingCurrentStep = (urlStepParam) => {
    // for redirect domain, don't add pathname 'onboarding' to url
    if (currentDomain === process.env.REACT_APP_ONBOARDING_DOMAIN) {
      history.push({
        pathname: '/',
        search: urlStepParam,
      });
    } else {
      history.push({
        pathname: ROUTE_ONBOARDING,
        search: urlStepParam,
      });
    }
  };

  useEffect(() => {
    let urlStepParam;
    // Clear notifications when the step changes
    dispatch(removeAll());
    if (step) {
      if (step === 1 && linkTag && companyId) {
        urlStepParam = `?step=1&tag=${linkTag}&companyId=${companyId?.toString()}`;
      } else if (
        ![10, 15, 'denied', 'error'].includes(step) &&
        lastStepStored > step &&
        triggeredPopAllowed &&
        !finishedApplication &&
        step > 7 &&
        lastStepStored !== 9
      ) {
        urlStepParam = `?step=${
          lastStepStored ? lastStepStored?.toString() : ''
        }`;
        // show restart modal if the user didn't finished the application and wants to go back
        setShowRestartModal(true);
      } else {
        urlStepParam = `?step=${step ? step?.toString() : ''}`;
      }

      dispatch(storeOnboardingStep(step));
      let visitedStepsArray = [];
      if (visitedSteps && typeof visitedSteps[Symbol.iterator] === 'function') {
        visitedStepsArray = [...visitedSteps];
      } else if (
        (!visitedSteps || visitedSteps?.length === 0) &&
        !savedData?.creditApplicationUuid
      ) {
        // add the start onboarding dataLayes before adding the first step to the visitedSteps (meaning the user has not visited any step yet)
        // make sure donesn't happen if you came back after monthio and redux is cleared
        window.dataLayer = window?.dataLayer || [];
        window.dataLayer.push({
          customPageUrl: '/onboarding-start',
          stepTitle: 'onboarding - start',
          event: 'onboarding',
        });
      }
      if (!visitedStepsArray.includes(step)) {
        visitedStepsArray.push(step);
      }
      dispatch(storeOnboardingVisitedSteps(visitedStepsArray));
    } else if (step === 0) {
      urlStepParam = `?step=denied`;
      setShowRestartModal(false);
    } else {
      urlStepParam = `?step=error`;
      setShowRestartModal(false);
    }

    redirectToOnboardingCurrentStep(urlStepParam);
  }, [step, linkTag, companyId]);

  const goBack = () => {
    const stepsStored = [...visitedSteps];

    const currentStepIndex = stepsStored.indexOf(step);
    if (currentStepIndex !== -1) {
      stepsStored.splice(currentStepIndex + 1);
    }

    const previousStep =
      stepsStored.length > 1 ? stepsStored[stepsStored.length - 2] : null;
    if (previousStep !== null) {
      setStep(previousStep);
    }
  };

  console.log(savedData);

  const handlingSteps = () => {
    const newMember = savedData?.memberStatus === 'NEW_MEMBER';
    const existingMember = savedData?.memberStatus === 'EXISTING_MEMBER';
    const existingClient = savedData?.memberStatus === 'EXISTING_CLIENT';
    switch (step) {
      case 0:
        return <DeniedCreditOffer step={step} />;
      case 1:
        return <JoinSundhed step={step} setNextStep={setStep} />;

      case 2:
        return (
          <MembershipConditions
            step={step}
            setNextStep={setStep}
            previousStep={goBack}
          />
        );

      case 3:
        if (!savedData?.emailIsConfirmed) {
          return (
            <ConfirmEmail
              step={step}
              setNextStep={setStep}
              previousStep={goBack}
            />
          );
        }
        setStep(step + 1);
        break;

      case 4:
        if (isOnboardingExistingMember) {
          return (
            <ExistingMemberStartOnboarding step={step} setNextStep={setStep} />
          );
        } else if (
          (existingMember || existingClient) &&
          savedData?.emailIsConfirmed
        ) {
          return (
            <ExistingMember
              step={step}
              setNextStep={setStep}
              creditExpirationDate={creditExpirationDate}
            />
          );
        }
        setStep(step + 1);
        break;

      case 5:
        // if member is new and email is confirmed, show MembershipCreated
        if (newMember) {
          return <MembershipCreated step={step} setNextStep={setStep} />;
        }
        //if member exists/client existsthen skip step
        setStep(step + 1);
        break;

      case 6:
        // this component the step back is returning to the step 3 because to return to step 4 need to have a validated email
        return (
          <HowTheApplicationWorks
            step={step}
            setNextStep={setStep}
            previousStep={goBack}
          />
        );
      case 7:
        return <ConditionsForCredit step={step} setNextStep={setStep} />;

      case 8:
        if (newMember || existingMember) {
          return (
            <CprNumber
              step={step}
              setNextStep={setStep}
              previousStep={goBack}
            />
          );
        } else {
          setStep(step + 1);
        }
        break;

      case 9:
        return (
          <RequestedAmount
            step={step}
            setNextStep={setStep}
            previousStep={goBack}
          />
        );
      case '9a':
        if (savedData?.requestedAmount) {
          return <PowerOfAttorney step={step} previousStep={goBack} />;
        } else {
          setStep(9);
        }
        break;
      case 10:
        return <CheckEskatConsent step={step} setNextStep={setStep} />;
      case 11:
        return <Acommodation step={step} setNextStep={setStep} />;

      case 12:
        return (
          <CivilStatus
            step={step}
            setNextStep={setStep}
            previousStep={goBack}
          />
        );

      case 13:
        return (
          <HowManyKids
            step={step}
            setNextStep={setStep}
            previousStep={goBack}
          />
        );
      case 14:
        return (
          <RedirectToMonthio
            step={step}
            setNextStep={setStep}
            previousStep={goBack}
          />
        );

      case 15:
        return savedData?.creditApplicationUuid ? (
          <EvaluateBudget step={step} setNextStep={setStep} />
        ) : (
          setStep()
        );

      case 16:
        return <CheckDebtorRegister step={step} setNextStep={setStep} />;

      case 17:
        return <ManualIntervention step={step} setNextStep={setStep} />;
      case 18:
        // THIS SHOULD GO FOR STEP UPLOAD DOCUMENTS
        // connect websocket and save the ws client, in order to be able to disconnet on unmount
        const uuid = savedData?.uuid;
        if (uuid && !websocketClient) {
          const wsConnection = dispatch(connectWebsocket(uuid));
          setWebsocketClient(wsConnection.payload);
        }
        return (
          <UploadDocumentation
            step={step}
            setNextStep={setStep}
            //toggleGuide={() => setShowGuide(!showGuide)}
          />
        );
      default:
        return <TechnicalError />;
    }
  };

  if (createMemberError) {
    return <CreateMemberError />;
  } else if (showEmailAlreadyConfirmed) {
    return <ConfirmEmailAlreadyConfirmed />;
  } else if (showEmailInvalidLink) {
    return <ConfirmEmailInvalidLink />;
  } else {
    return (
      <>
        <Prompt
          when={notAllowedToReturnSteps.includes(step)}
          message={handleBlockedNavigation}
        />

        <Notifications
          notifications={notifications}
          style={styleNotification}
        />
        <div id='onboarding-flow' className='flow-wrapper onboarding'>
          <TransitionGroup
            appear
            timeout={600}
            className={`slide-group ${
              parseInt(savedStep, 10) >= previousVisitedStep
                ? 'move-next'
                : 'move-prev'
            } step-${step}`}
          >
            <CSSTransition
              key={step}
              appear
              timeout={300}
              classNames='slide'
              className='transition-container'
            >
              <Fade show={true}>
                {/* show the other steps normally */}

                {
                  loadingCreditApplicationData && (
                    <LoadingSpinner title={strings.onboardingSpinnerTitle} />
                  )
                  // show the invalid link page
                }
                {/* for step 30 don't show gude if the user has already visited it or if all required documents are uploaded */}
                {!loadingCreditApplicationData && (
                  <div className='sundhed-wrapper'>
                    <div className='sundhed-plus-logo'>
                      <img
                        className='sundhed-logo'
                        src={sundhedLogo}
                        alt='Sundhed+'
                      />
                    </div>

                    <Card className='wrapper'>
                      <div className='step'>
                        {showInvalidLink && !loadingCreditApplicationData ? (
                          <InvalidLinkForOnboarding
                            setNextStep={setStep}
                            clearInvalidLink={() => setShowInvalidLink(false)}
                          />
                        ) : (
                          handlingSteps(step)
                        )}
                      </div>
                    </Card>
                  </div>
                )}

                {/* show option to restart */}
                {showRestartModal && (
                  <RestartOnboardingModal
                    closeModal={() => setShowRestartModal(false)}
                    setNextStep={setStep}
                  />
                )}

                {/* show server unavailable modal */}
                {showServerUnavailableModal && (
                  <ServerUnavailableModal
                    closeModal={() => {
                      dispatch(setServerModalError(null));
                      if (reloadStep) {
                        window.location.reload(false);
                      }
                      setShowServerUnavailableModal(false);
                    }}
                  />
                )}
              </Fade>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </>
    );
  }
}
