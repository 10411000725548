import React, { useEffect, useState, useRef } from 'react';
import Sidebar from '../sidebar/Sidebar';
import {
  clearMemberPortalData,
  clientIsAuthenticated,
  createMitIdSession,
  getClientCreditData,
  loadingClientPortalData,
  isNotClient as setIsNotClient
  // eslint-disable-next-line import/no-unresolved
} from 'actions/memberPortalActions';
import { clearClientPortalWebsocketMessage } from '../../actions/websocketActions';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import Navbar from '../navbar/Navbar';
import ErrorBoundary from '../ErrorBoundary';
import './style.scss';
import {
  ROUTE_MIT_SUNDHEDPLUS,
  ROUTE_MIT_SUNDHEDPLUS_HELP,
  ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE,
} from '../../constants/routes';
import MitDashboard from './Dashboard';
import MitMemberProfile from './MemberProfile';
import { useCriiptoVerify } from '@criipto/verify-react';
import LoadingMessage from '../../components/statusMessages/LoadingMessage';
import { useSelector, useDispatch } from 'react-redux';

// eslint-disable-next-line import/no-unresolved
import { AppDispatch } from 'redux-custom';
import ErrorPageForClientPortal from './common/ErrorPageForClientPortal';
import { config } from '../../config';
import SessionTimeout from '../common/SessionTimout';
import { connectWebsocket } from '../../actions/websocketActions';
import DentiModal from '../common/DentiModal';
import { strings } from '../../constants/localization';

interface clientDataProps {
  clientData: {
    firstName: string;
    lastName: string;
    wsUuid: string;
  };
}

const SundhedplusMain: React.FC = () => {
  const { result, error } = useCriiptoVerify();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalAcceptanceFlow, setModalAcceptanceFlow] =
    useState<boolean>(false);
  const [paymentPlanModal, setPaymentPlanModal] = useState<boolean>(false);
  const [partialRedemptionModal, setPartialRedemptionModal] =
    useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [errorEndpoint, setErrorEndpoint] = useState<boolean>(false);
  const [showWSMessage, setShowWSMessage] = useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const loading = useSelector(
    (state: { memberPortal: { loadingClientPortalData: boolean } }) =>
      state.memberPortal?.loadingClientPortalData
  );
  const mitIdState = useSelector(
    (state: { memberPortal: { mitIdState: string } }) =>
      state.memberPortal?.mitIdState
  );
  const memberData = useSelector(
    (state: { memberPortal: { clientDataInformation: clientDataProps } }) =>
      state.memberPortal?.clientDataInformation
  );
  const { wsUuid } = memberData?.clientData || {
    wsUuid: '',
  };
  const authenticated = useSelector(
    (state: { memberPortal: { clientIsAuthenticated: boolean } }) =>
      state.memberPortal?.clientIsAuthenticated
  );

  const isClientLogout = useSelector(
    (state: { memberPortal: { isClientLogout: boolean } }) =>
      state.memberPortal?.isClientLogout
  );

  const isNotClient = useSelector(
    (state: { memberPortal: { isNotClient: boolean } }) =>
      state.memberPortal?.isNotClient
  );

  const clientPortalWsMessages = useSelector(
    (state: { websocketMessages: { clientPortalWs: [] } }) => state.websocketMessages?.clientPortalWs
  );
  const { firstName, lastName } = memberData?.clientData || {
    firstName: '',
    lastName: '',
  };
  const websocketClientRef = useRef(null);

  useEffect(() => {
    if (authenticated) {
      // Connect the WebSocket and store it in the ref
      websocketClientRef.current = dispatch(connectWebsocket(wsUuid, true));

      // Cleanup function
      return () => {
        // Disconnect the WebSocket connection after logout or when dependencies change
        if (websocketClientRef.current && websocketClientRef.current.connected) {
          websocketClientRef.current.disconnect();
        }
      };
    }
  }, [authenticated, wsUuid]);

  useEffect(() => {
    if (clientPortalWsMessages?.length > 0) {
      setShowWSMessage(true);
    }

  }, [clientPortalWsMessages]);


  useEffect(() => {
    if (result && !memberData) {
      if ('id_token' in result && !errorEndpoint && !isClientLogout) {
        dispatch(createMitIdSession({ id_token: result.id_token, error: '' }))
          .then((response) => {
            dispatch(setIsNotClient(false));
            if (response.status === 200) {
              dispatch(getClientCreditData())
                .then(() => {
                  dispatch(clientIsAuthenticated(true));
                  dispatch(loadingClientPortalData(false));
                })
                .catch((e) => {
                  console.log('Error', e);
                  setErrorEndpoint(true);
                  dispatch(clearMemberPortalData());
                  dispatch(loadingClientPortalData(false));
                });
            } else {
              setErrorEndpoint(true);
              dispatch(clearMemberPortalData());
              dispatch(loadingClientPortalData(false));
            }
          })
          .catch((e) => {
            console.log('Error', e);
            setErrorEndpoint(true);
            dispatch(clearMemberPortalData());
            dispatch(loadingClientPortalData(false));
          });
      } else {
        dispatch(clearMemberPortalData());
        dispatch(loadingClientPortalData(false));
      }
    } else {
      dispatch(loadingClientPortalData(false));
    }
  }, [authenticated, loading, error, result, dispatch, errorEndpoint]);

  //every time user refresh the page and are already authenticated call the endpoint again to get the data
  useEffect(() => {
    loadingClientPortalData(true);
    if (authenticated) {
      dispatch(getClientCreditData())
        .then(() => {
          dispatch(loadingClientPortalData(false));
        })
        .catch(() => {
          dispatch(clearMemberPortalData());
          dispatch(loadingClientPortalData(false));
          setErrorEndpoint(true);
        });
    }
  }, []);

  useEffect(() => {
    if (
      mitIdState === '' &&
      !authenticated &&
      !loading &&
      !error &&
      !errorEndpoint
    ) {
      dispatch(loadingClientPortalData(false));
      window.location.href = config.damAppMainPageUrl;
    }
  }, [mitIdState, history, error, dispatch]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  useEffect(() => {
    document.body.classList.add('private-content');

    return () => {
      document.body.classList.remove('private-content');
    };
  }, []);

  // detect if the user are using a IOS device and prevent to zoon in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        true
      );
    }

    document.body.classList.add('private-content');
    return () => {
      document.body.classList.remove('private-content');
    };
  }, []);

  useEffect(() => {
    // Function to set the vh unit exactly to the viewport height, accounting for the mobile browser bar
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set vh unit on load
    setVh();

    // Set vh unit on resize
    window.addEventListener('resize', setVh);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', setVh);
  }, []);


  const closeWsNotificationModal = () => {
    setShowWSMessage(!showWSMessage);
    dispatch(clearClientPortalWebsocketMessage);
    window.location.reload();
  };

  const hasErrors = error || (errorEndpoint && !loading && !authenticated);

  return (
    <>
      <ErrorBoundary>
        {authenticated && (
          <>
            <SessionTimeout clientPortal />
            <Container
              fluid
              id='mitSundhedPlus'
              className='app-container-height'
            >
              <Row>
                <Col xs='12' md='12' lg='12' className='navbar-wrapper'>
                  <Navbar
                    isDashboard
                    toggleModal={toggleModal}
                    modalShow={modalShow}
                    toggleCreateNewInvoice={null}
                    createNewInvoice={null}
                    userDashboard={`${firstName} ${lastName}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  md='12'
                  lg='auto'
                  className={`sidebar-wrapper dashboard-sidebar ${menuOpen ? 'open' : ''
                    }`}
                >
                  <Sidebar
                    toggleModal={toggleModal}
                    modalIsOpened={menuOpen}
                    toggleMenu={toggleMenu}
                    isDashboard
                    toggleCreateNewInvoice={null}
                    createNewInvoice={null}
                  />
                </Col>
                <Col xs='12' md='12' lg className='main-wrapper'>
                  <Switch>
                    <Route
                      exact
                      path={ROUTE_MIT_SUNDHEDPLUS}
                      render={() => (
                        <MitDashboard
                          toggleMenu={toggleMenu}
                          setModalAcceptanceFlow={setModalAcceptanceFlow}
                          setPaymentPlanModal={setPaymentPlanModal}
                          setPartialRedemptionModal={setPartialRedemptionModal}
                          setWarningModal={setWarningModal}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={ROUTE_MIT_SUNDHEDPLUS_MEMBER_PROFILE}
                      render={() => (
                        <MitMemberProfile toggleMenu={toggleMenu} />
                      )}
                    />
                    <Route
                      exact
                      path={ROUTE_MIT_SUNDHEDPLUS_HELP}
                      render={() => <div>Help</div>}
                    />
                  </Switch>
                </Col>
              </Row>
            </Container>
          </>
        )}
        {hasErrors ? (
          <ErrorPageForClientPortal
            setErrorEndpoint={setErrorEndpoint}
            isNotClient={isNotClient}
          />
        ) : (
          <LoadingMessage />
        )}

        {/* check if if we need to show WS modal, but only if we don't have already an opened modal */}
        {showWSMessage &&
          !(
            modalAcceptanceFlow ||
            paymentPlanModal ||
            partialRedemptionModal ||
            warningModal
          ) && (
            <DentiModal
              key={1}
              close={closeWsNotificationModal}
              modalClass='notification-modal'
              modalClassName='confirm-action'
              closeBtn={false}
            >
              <div>
                <div className='icon'>
                  <i className='lnil lnil-reload' />
                </div>
                <div>
                  <h3 className='headline'>
                    {strings.wsNotifPatientUpdateHeadline}
                  </h3>
                  <p className='notification-message'>
                    {strings.formatString(
                      strings.wsNotifPatientUpdateText,
                      `${firstName || ''} ${lastName || ''}`
                    )}
                  </p>
                </div>
                <div className='bottom-section action-btn cf'>
                  <button
                    className='submit-btn btn btn-blue center-block'
                    type='button'
                    onClick={closeWsNotificationModal}
                  >
                    <span>{strings.wsNotifPatientUpdateButton}</span>
                  </button>
                </div>
              </div>
            </DentiModal>
          )}
      </ErrorBoundary>
    </>
  );
};

export default SundhedplusMain;
