import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { strings } from '../../constants/localization';
import { getUserSessionIDReepay } from '../../actions/installmentLoanActions';
import { useDispatch } from 'react-redux';

const StepSix = (props) => {
  const dispatch = useDispatch();
  const registerLater = (flag) => {
    props.nextStep({
      registerLater: flag,
    });
  };

  const registerPaymentCard = (data) => {
    props.nextStep({
      paymentMethod: data.payment_method,
    });
  };

  useEffect(() => {
    const initializeReepay = async () => {
      try {
        const result = await dispatch(
          getUserSessionIDReepay(props.acceptLoan.loanNumber)
        );
        const sessionData = result;

        if (!sessionData || !sessionData.id) {
          return;
        }
        // Initialize Reepay Embedded Checkout
        // eslint-disable-next-line
        const rp = new Reepay.EmbeddedSubscription(sessionData.id, {
          html_element: 'rp_container',
        });
        // Add event handler for payment acceptance
        // eslint-disable-next-line
        rp.addEventHandler(Reepay.Event.Accept, (data) => {
          registerPaymentCard(data);
        });
      } catch (error) {
        console.error('Failed to initialize Reepay:', error);
      }
    };

    initializeReepay();
  }, [dispatch, props.acceptLoan.loanNumber]);

  const shouldRegisterLater = () => {
    const { acceptLoan } = props;
    if (acceptLoan.restructured) {
      return false;
    }

    if (
      acceptLoan.creditCardNumber === null &&
      acceptLoan.remainingBalanceOnExistingLoans === 0
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className='step-content step-six'>
      <h1 className='step-title'>{strings.step6Title}</h1>
      <div className='register-card'>{strings.registerCardText}</div>
      {shouldRegisterLater() && (
        <div className='register-card-later'>
          <Button color='link' onClick={() => registerLater(true)}>
            {strings.registerCardLater}
          </Button>
        </div>
      )}
      <div className='reepay-form-container'>
        <div id='rp_container' />
      </div>
    </div>
  );
};

export default connect(null, { getUserSessionIDReepay })(StepSix);
