import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { strings } from '../../../constants/localization';
import { applicationStatusFilter, momentFormat } from '../../../constants';
import { applicationStatusMap } from '../../../constants/mappings';
import ActiveFilterDisplayApplication from './ActiveFilterDisplayApplication';
import {
  getAllApplications,
  updateApplicationFilterParams,
} from '../../../actions/onboardingApplicationsActions';
import RadioButtonList from '../../common/RadioButtonList/RadioButtonList';

const ApplicationFilter = ({ filterSubmit }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const filters = useSelector(
    (state) => state.onboardingApplication?.filterParams
  );
  const [filterParams, setFilterParams] = useState({
    creditApplicationStatus: filters?.creditApplicationStatus || '',
    dateLower: filters?.dateLower || '',
    dateUpper: filters?.dateUpper || '',
    searchInput: filters?.searchInput || '',
  });
  const [activeFilters, setActiveFilters] = useState(filters || {});
  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterRef]);

  useEffect(() => {
    if (filterParams !== filters) {
      dispatch(updateApplicationFilterParams(filterParams));
    }
  }, [filterParams]);

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleRadioChange = (value) => {
    setFilterParams({
      ...filters,
      creditApplicationStatus: value || '',
    });
  };

  const handleDateChange = (event) => {
    const inputEl = event.target;
    const { name, value } = inputEl;

    setFilterParams({
      ...filters,
      [name]: value,
    });
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      creditApplicationStatus: '',
      dateLower: '',
      dateUpper: '',
    };
    setFilterParams(clearedFilters);
    setIsOpen(false);
    dispatch(getAllApplications());
    setActiveFilters(clearedFilters);
  };

  const handleApplyFilters = (event) => {
    event.preventDefault();
    const { creditApplicationStatus, dateLower, dateUpper } = filterParams;
    if (creditApplicationStatus || dateLower || dateUpper) {
      filterSubmit(filterParams);
    } else {
      dispatch(getAllApplications());
    }

    // close the filters dropdown
    toggle();
    setActiveFilters(filterParams);
  };

  const removeFilterStatus = () => {
    setFilterParams((prevState) => {
      const updatedFilters = {
        ...prevState,
        creditApplicationStatus: '',
      };
      filterSubmit(updatedFilters);
      return updatedFilters;
    });

    setActiveFilters((prevState) => ({
      ...prevState,
      creditApplicationStatus: '',
    }));
  };

  const clearDate = () => {
    setFilterParams((prevState) => {
      const updatedFilters = {
        ...prevState,
        dateLower: '',
        dateUpper: '',
      };
      filterSubmit(updatedFilters);
      return updatedFilters;
    });

    setActiveFilters((prevState) => ({
      ...prevState,
      dateLower: '',
      dateUpper: '',
    }));
  };

  return (
    <div className='filter-container companies' ref={filterRef}>
      <Button color='light' className='btn-filter' onClick={toggle}>
        <i className='di di-controls' />
        <span>{strings.filterTitle}</span>
      </Button>
      <ActiveFilterDisplayApplication
        filterParams={activeFilters}
        removeFilterStatus={removeFilterStatus}
        clearDate={clearDate}
      />
      <div className={`filter-content ${isOpen ? 'opened' : ''}`}>
        <button
          type='button'
          className='btn btn-white menu-toggler close-action'
          onClick={toggle}
        >
          <div className='close-toggler-icon'>
            <span />
            <span />
          </div>
        </button>
        <Form onReset={handleClearFilters} onSubmit={handleApplyFilters}>
          <Row>
            <Col xs='12' className='form-group'>
              <div className='filter-title'>
                {strings.applicationFilterStatus}
              </div>
              <RadioButtonList
                options={applicationStatusFilter.reduce((filtered, item) => {
                  const newItem = {
                    value: item,
                    label: strings[applicationStatusMap[item]?.statusText],
                  };
                  filtered?.push(newItem);
                  return filtered;
                }, [])}
                selectedOption={filterParams.creditApplicationStatus}
                handleClick={handleRadioChange}
              />
            </Col>
          </Row>

          <Row className='form-row'>
            <Col xs='12'>
              <Label for='date-interval'>{strings.dateInterval}</Label>
            </Col>
            <Col xs='6' md='6' className='form-group date-input'>
              <Input
                type='date'
                name='dateLower'
                data-date={momentFormat}
                data-date-format={momentFormat}
                onChange={handleDateChange}
                value={filterParams.dateLower}
              />
            </Col>
            <Col xs='6' md='6' className='form-group date-input'>
              <Input
                type='date'
                name='dateUpper'
                className='dash-line'
                data-date={momentFormat}
                data-date-format={momentFormat}
                onChange={handleDateChange}
                value={filterParams.dateUpper}
              />
            </Col>
          </Row>
          {/* ACTION BUTTONS */}
          <Row>
            <Col xs='6' md='6' className='mt-3'>
              <Button type='reset' color='light' block>
                <i className='di di-close' />
                &ensp;
                {strings.resetFilter}
              </Button>
            </Col>
            <Col xs='6' md='6' className='mt-3'>
              <Button type='submit' color='light' className='btn-blue' block>
                <i className='di di-check' />
                &ensp;
                {strings.submitFilter}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ApplicationFilter;
