import _ from 'lodash';
import {
  formatCurrencyDaStandard,
  numberWithDotAsThousandsSeparator,
  removeCurrencyFormating,
  formatCurrency
} from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import React, { useEffect, useState } from 'react';
import { Button, Label } from 'reactstrap';

type AdditionalPaymentProps = {
  onNextStep: () => void;
  setAdditionalPayment: (value: string) => void;
  remainingBalance: number;
  hasCancelButton?: boolean;
  hasRedirect?: boolean;
  closeModal?: () => void;
  additionalPayment?: string;
};

const AdditionalPayment: React.FC<AdditionalPaymentProps> = ({
  onNextStep,
  setAdditionalPayment,
  remainingBalance,
  hasCancelButton,
  hasRedirect,
  closeModal,
  additionalPayment
}) => {
  const [amountToPay, setAmountToPay] = useState<string>('');
  const [rawAmountToPay, setRawAmountToPay] = useState<number>();
  const [remainingDebtAfterAmountPaid, setRemainingDebtAfterAmountPaid] = useState<number>(_.ceil(remainingBalance, 2));
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    if (additionalPayment) {
      setAmountToPay(formatCurrency(additionalPayment));
    }
  }, []);

  useEffect(() => {
    const numberAmount = Number(rawAmountToPay);
    const roundedRemainingBalance = Number(remainingBalance?.toFixed(2));
    setRemainingDebtAfterAmountPaid(_.ceil(roundedRemainingBalance - (numberAmount ?? 0), 2));
  }, [rawAmountToPay]);

  const validateAmount = (): string => {
    let errorMessage = '';

    if (!rawAmountToPay) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg1;
    } else if (rawAmountToPay > remainingBalance) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg2;
    } else if (rawAmountToPay < 100) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg3;
    } else if (remainingDebtAfterAmountPaid !== 0 && remainingDebtAfterAmountPaid < 500) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg4;
    }

    if (errorMessage) {
      setErrorText(errorMessage);
      return;
    }
    setAdditionalPayment(rawAmountToPay.toString());
    return errorMessage;
  };


  const handleNextStep = () => {
    const errorMessage = validateAmount();
    if (errorMessage === '') {
      onNextStep();
    }
  };

  const changeValue = (value: string) => {
    setErrorText('');

    const valueFormatted = value.replace(/[^\d.,-]/g, '').replace(/,(\d{2})\d*/, ',$1');
    const valueWithoutPoints = valueFormatted.replaceAll('.', '');
    const nextRenderedValue =
      numberWithDotAsThousandsSeparator(valueWithoutPoints);
    setAmountToPay(nextRenderedValue);
    setRawAmountToPay(removeCurrencyFormating(nextRenderedValue));
  };

  const handleCancelButton = () => {
    if (hasRedirect) {
      window.close();
    } else {
      closeModal && closeModal();
      window.location.reload();
    }
  };


  return (
    <>
      <div className='step-content'>
        <h1 className='step-title'>
          {strings.partialRedemptionAdditionalPaymentTitle}
        </h1>
        <div className='subtitle'>
          <p>{strings.partialRedemptionAdditionalPaymentSubtitle}</p>
        </div>

        <div className='mb-5'>
          <Label className='input-label'>
            {strings.partialRedemptionAdditionalPaymentInputLabel}
          </Label>
          <input
            type='text'
            placeholder='0,00 kr.'
            className={`input-field ${errorText ? 'input_danger mb-1' : ''}`}
            onChange={(e) => changeValue(e.target.value)}
            value={amountToPay}
            onFocus={(e) => setAmountToPay(e.target.value
              .replace(',00 kr.', '')
              .replace(' kr.', ''))}
            onBlur={(e) => {
              if (e.target.value !== '') {
                const value = e.target.value;
                let amountResult;
                if (value) {
                  const [integerPart, decimalPart] = value.split(',');
                  const decimalLength = decimalPart ? decimalPart.length : 0;

                  amountResult = `${integerPart},${decimalPart || ''}`;
                  amountResult += decimalLength === 0 ? '00 kr.' : decimalLength === 1 ? '0 kr.' : ' kr.';
                }
                setAmountToPay(amountResult);
                validateAmount();
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleNextStep();
              }
            }}
          />
          {errorText && <div className='text-danger'>{errorText}</div>}
        </div>

        <div className='payment-info'>
          <Label>
            {strings.partialRedemptionAdditionalPaymenRemainingDebt}
          </Label>
          <span>{formatCurrencyDaStandard(remainingBalance)}</span>
        </div>
        <div className='payment-info'>
          <Label>
            {strings.partialRedemptionAdditionalPaymenRemainingDebtAfterPayment}
          </Label>
          <span>
            {remainingDebtAfterAmountPaid >= 0 ? formatCurrencyDaStandard(remainingDebtAfterAmountPaid) : formatCurrencyDaStandard(remainingBalance)}
          </span>
        </div>

        <div className='step-actions'>
          {hasCancelButton && (
            <Button
              color='light'
              className='reject'
              onClick={handleCancelButton}
            >
              {strings.partialRedemptionButtonCancel}
            </Button>
          )}
          <Button
            color='blue'
            className='continue'
            onClick={handleNextStep}
            disabled={amountToPay === '' || amountToPay === '0,00 kr.'}
          >
            {strings.partialRedemptionButtonNext}
          </Button>
        </div>
      </div>

    </>
  );
};

export default AdditionalPayment;
