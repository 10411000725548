import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ListGroup, Button } from 'reactstrap';
import Pagination from 'react-js-pagination';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
  thumbVerticalCustomBackgroundStyle,
} from '../../../constants/styleScrollbar';
import {
  VIEW_ALL,
  LEADS_LOADING,
  LEADS_SUCCESS,
  LEADS_EMPTY,
  LEADS_FAILURE,
  moment,
  momentFormat,
} from '../../../constants';
import { strings } from '../../../constants/localization';
import {
  isManager,
  isDentist,
  isAuthenticated,
  normalizePhone,
  getTabObjectFromStorage,
  getCachedSearch,
} from '../../../constants/utils';
import { invoicesStatusMap } from '../../../constants/mappings';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  getLeads,
  searchEmitted,
  filterEmitted,
  setActivePage,
  getUpdatedPatientCreditFacility,
} from '../../../actions/leadActions';
import { leadSidebarActiveStatus } from '../../../actions/statusActions';
import SearchAndFilter from '../../common/SearchAndFilter/SearchAndFilter';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import EmptyMessage from '../../statusMessages/EmptyMessage';
import AddPatientModal from '../../add-patient/AddPatientModal';
import ServerModalError from '../../common/ServerModalError';
import LeadListItem from './LeadListItem';
import LeadsFilterMenu from './LeadsFilterMenu';

const LeadsList = ({ toggleMenu }) => {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector((state) => state.user?.user);
  const leads = useSelector((state) => state.lead.leadsList);
  const leadsFilter = useSelector((state) => state.lead.filters);
  const activeLead = useSelector((state) => state.lead.activeLead);
  const statusActive = useSelector((state) => state.status?.leadStatusActive);
  const storedActivePage = useSelector((state) => state.lead.activePage);
  const searchActive = useSelector((state) => state.lead?.searchActive);
  const loadingStatus = useSelector((state) => state.lead.loading);
  const [abortController, setAbortController] = useState(new AbortController());

  const [previousSearchedKeyword, setPreviousSearchedKeyword] = useState(
    searchActive?.searchInput
  );
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [addNewPatientModal, setAddNewPatientModal] = useState(false);
  const [leadsFilterLabels, setLeadsFilterLabels] = useState([]);
  const [clearFilterValue, setClearFilterValue] = useState('');
  const [triggerClearFilterEffect, setTriggerClearFilterEffect] =
    useState(false);
  const [shouldShowAddPatientButton, setShouldShowAddPatientButton] =
    useState(true);
  const [restoredWindow, setRestoredWindow] = useState(true);
  const brandEnvVariable = process.env.REACT_APP_BRAND;

  // Store session end time when the window is about to be closed
  window.addEventListener('beforeunload', () => {
    localStorage.setItem('lastClosed', Date.now());
  });

  window.addEventListener('load', () => {
    const lastClosed = localStorage.getItem('lastClosed');

    if (lastClosed) {
      const timeSinceClosed = Date.now() - parseInt(lastClosed, 10);

      // If the window was closed for more than 2 seconds, assume it was fully shut down
      if (timeSinceClosed > 2000) {
        console.log(
          'Window restored after being closed. Clearing sessionStorage.'
        );
        sessionStorage.clear();
        setRestoredWindow(true);
      }
    }

    // Reset the lastClosed time (so it only triggers once)
    localStorage.removeItem('lastClosed');
  });

  // when component mounts, search based on query stirng if any, otwerwise get all leads
  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    if (searchParams?.has('q')) {
      const searchedKeyword = searchParams.get('q');
      const values = {
        flag: true,
        searchInput: searchedKeyword,
      };
      searchSubmit(values);
    } else if (!leadsFilter || Object.keys(leadsFilter).length === 0) {
      const tabID = searchParams?.get('tabID');
      setActivePage(1);
      // abort any pending requests
      if (abortController) {
        abortController.abort();
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      dispatch(
        getLeads(
          storedActivePage ? storedActivePage - 1 : 0,
          () => {
            getTabObjectFromStorage(
              tabID,
              'lastSelectedClient',
              (lastStelected) => {
                dispatch(
                  getUpdatedPatientCreditFacility(
                    lastStelected?.creditFacilityInformation?.id
                  )
                );
              }
            );
          },
          newAbortController.signal,
          restoredWindow
        )
      );
    }

    return () => {
      if (searchActive && searchActive?.flag) {
        dispatch(leadSidebarActiveStatus(VIEW_ALL));
      }
      if (isAuthenticated()) {
        dispatch(searchEmitted({ flag: false, searchInput: '' })); // clear search
        dispatch(leadSidebarActiveStatus(VIEW_ALL));
      }
    };
  }, [location]);

  useEffect(() => {
    // for manager and dentist users logged in into non-ACTIVE company hide Add patient btn
    if (isManager(currentUser)) {
      setShouldShowAddPatientButton(
        currentUser?.companyChainStatus === 'ACTIVE'
      );
    }
    if (isDentist(currentUser)) {
      setShouldShowAddPatientButton(
        currentUser?.activeCompanyStatus === 'ACTIVE'
      );
    }
  }, [currentUser]);

  useEffect(() => {
    // when filter changes, then update the array of badges to display
    if (!leadsFilter) return;
    const leadsFilterArray = [];
    const {
      brand,
      clientStatus,
      creditFacilityStatus,
      creditExpired,
      companyReferenceNumber,
      companyName, // to use in the active filters
      installmentLoanStatus,
      dateLowerInterval,
      dateUpperInterval,
    } = leadsFilter || {};

    const getStatusString = (statusArray) => {
      if (Array.isArray(statusArray) && statusArray?.length > 0) {
        statusArray.forEach((value) => {
          if (value === 'ACTIVE') {
            if (creditExpired) {
              leadsFilterArray.push({
                label: strings.expiredCredit,
                value,
              });
            } else {
              leadsFilterArray.push({
                label: strings.activeCredit,
                value,
              });
            }
          } else {
            leadsFilterArray.push({
              label: strings[invoicesStatusMap[value].statusText],
              value,
            });
          }
        });
      }
    };
    getStatusString(brand);
    getStatusString(clientStatus);
    getStatusString(creditFacilityStatus);
    getStatusString(installmentLoanStatus);

    if (dateLowerInterval && dateUpperInterval) {
      const timePeriod = `${moment(dateLowerInterval).format(
        momentFormat
      )} - ${moment(dateUpperInterval).format(momentFormat)}`;
      leadsFilterArray.push({
        label: timePeriod,
        value: 'date',
      });
    }

    if (companyReferenceNumber) {
      leadsFilterArray.push({
        label: companyName || companyReferenceNumber,
        value: companyReferenceNumber,
      });
    }

    setLeadsFilterLabels(leadsFilterArray);
    setActivePage(1);
    dispatch(getLeads(0), () => {
      resetScrollPosition();
    });
  }, [leadsFilter]);

  const searchSubmit = (searchObject) => {
    dispatch(setActivePage(1));
    if (searchObject?.searchInput !== previousSearchedKeyword) {
      const keyword = normalizePhone(searchObject?.searchInput.trim());
      // if (keyword && keyword !== searchObject?.searchInput) {
      dispatch(searchEmitted({ flag: true, searchInput: keyword }));
      setPreviousSearchedKeyword(keyword);
      // abort any pending requests
      if (abortController) {
        abortController.abort();
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      dispatch(
        getLeads(
          0,
          () => {
            dispatch(leadSidebarActiveStatus());
            resetScrollPosition();
          },
          newAbortController.signal
        )
      );
      // }
    }
  };

  const removeFilter = (tag) => {
    if (tag === 'all') {
      setClearFilterValue(tag);
      setTriggerClearFilterEffect((prevTrigger) => !prevTrigger);
    } else {
      // remove the filter from the filter object
      const filterParamsUpdated = { ...leadsFilter };
      const creditExpired = filterParamsUpdated?.creditExpired;
      // check each filter key to see if the tag to remove is in the array
      for (const key in filterParamsUpdated) {
        if (
          Object.prototype.hasOwnProperty.call(filterParamsUpdated, key) &&
          Array.isArray(filterParamsUpdated[key])
        ) {
          // used to update the form menu in order to clear the value
          // if value to remove was found in the filters
          const indexToRemove = filterParamsUpdated[key].findIndex(
            (item) => item === tag?.value
          );
          // if the tag.value is not found, then is either ACTIVE_CREDIT_UNEXPIRED or ACTIVE_CREDIT_EXPIRED
          if (indexToRemove !== -1) {
            const specialValue =
              tag?.value === 'ACTIVE'
                ? creditExpired
                  ? 'ACTIVE_CREDIT_EXPIRED'
                  : 'ACTIVE_CREDIT_UNEXPIRED'
                : tag?.value;

            setClearFilterValue({
              key,
              value: specialValue,
            });
          }

          const updatedArray = filterParamsUpdated[key].filter(
            (item) => item !== tag?.value
          );
          // for the case of creditExpired, if ACTIVE is removed, then also update creditExpired
          if (tag?.value === 'ACTIVE' && filterParamsUpdated?.creditExpired) {
            delete filterParamsUpdated?.creditExpired;
          }
          if (updatedArray?.length === 0) {
            delete filterParamsUpdated[key];
          } else {
            filterParamsUpdated[key] = updatedArray;
          }
        } else {
          setClearFilterValue({
            key,
            value: tag?.value,
          });
          const value = filterParamsUpdated[key];
          if (typeof value === 'string' && value.includes(tag?.value)) {
            delete filterParamsUpdated[key];
            if (key === 'companyReferenceNumber') {
              delete filterParamsUpdated?.companyName;
            }
          }
        }
      }
      dispatch(filterEmitted(filterParamsUpdated));
      setTriggerClearFilterEffect((prevTrigger) => !prevTrigger);
    }
  };

  const renderSearchAndFilter = () => {
    const cachedSearchParams = getCachedSearch('clientSearch') || {};
    return (
      <div
        className={`search-filter-patients ${
          shouldShowAddPatientButton ? 'show-add-button' : ''
        }`}
      >
        <SearchAndFilter
          searchBarPlaceholder={strings.formatString(
            strings.searchPlaceholder,
            strings.clientsPlaceholder
          )}
          storedSearchKeyword={
            searchActive?.flag
              ? searchActive?.searchInput
              : cachedSearchParams?.searchedInput ?? ''
          }
          filterMenuOpen={filterMenuOpen}
          toggleFilterMenu={() => {
            setFilterMenuOpen(!filterMenuOpen);
          }}
          searchSubmit={searchSubmit}
          filterTagsArray={leadsFilterLabels}
          removeFilter={removeFilter}
        >
          <LeadsFilterMenu
            closeFilterMenu={() => setFilterMenuOpen(false)}
            clearFilterValue={clearFilterValue}
            triggerClearFilterEffect={triggerClearFilterEffect} // used as a signal to trigger the update( fix for action not triggering if the tag removed is the same as the old one)
          />
        </SearchAndFilter>
        {shouldShowAddPatientButton && (
          <div
            className='add-patient-button'
            onClick={() => {
              setAddNewPatientModal(true);
            }}
          >
            <i className='lnil lnil-circle-plus icon' />
          </div>
        )}
      </div>
    );
  };

  const resetScrollPosition = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.view.scrollTop = 0;
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== storedActivePage) {
      dispatch(setActivePage(pageNumber));
      dispatch(
        getLeads(pageNumber - 1, () => {
          resetScrollPosition();
        })
      );
    }
  };

  const renderList = () => {
    return (
      <div className='entities-list-group'>
        <Scrollbars
          ref={scrollRef}
          renderTrackVertical={({ style, ...props }) => (
            <div {...props} style={{ ...style, ...trackVerticalStyle }} />
          )}
          renderThumbVertical={({ style, ...props }) =>
            brandEnvVariable === 'Denti' ? (
              <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
            ) : (
              <div
                {...props}
                style={{ ...style, ...thumbVerticalCustomBackgroundStyle }}
              />
            )
          }
        >
          <div className='scrollbar-wrapper'>
            <ListGroup className={leads?.totalPages > 1 ? '' : 'mb-2'}>
              {leads?.content.map((lead) => (
                <LeadListItem
                  key={lead?.id || lead?.leadUuid}
                  lead={Object.assign({}, lead)}
                  active={
                    activeLead
                      ? lead?.id === activeLead?.id &&
                        lead?.leadUuid === activeLead?.leadUuid
                      : false
                  }
                />
              ))}
            </ListGroup>
            {leads.totalPages > 1 && (
              <Pagination
                activePage={leads.pageable.page + 1}
                itemClass='page-item'
                linkClass='page-link'
                itemsCountPerPage={20}
                totalItemsCount={leads.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </Scrollbars>
      </div>
    );
  };

  const renderSituation = () => {
    let result;
    switch (loadingStatus) {
      case LEADS_LOADING:
        result = <LoadingMessage />;
        break;
      case LEADS_SUCCESS:
        result = renderList();
        break;
      case LEADS_EMPTY:
        result = (
          <div className='scrollbar-pseudo-rail'>
            {searchActive !== undefined && searchActive?.flag ? (
              <div className='empty-results-section'>
                <EmptyMessage message={strings.searchEmpty} />
                {shouldShowAddPatientButton && (
                  <Button
                    color='blue'
                    onClick={() => {
                      setAddNewPatientModal(true);
                    }}
                  >
                    <i className='lnil lnil-circle-plus' />
                    {strings.buttonTextAddPatient}
                  </Button>
                )}
              </div>
            ) : (
              <>
                {statusActive === undefined || statusActive === VIEW_ALL ? (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noItems,
                      strings.leadPlaceholder
                    )}
                  />
                ) : (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noCategoryItems,
                      strings.leadsPlaceholder
                    )}
                  />
                )}
              </>
            )}
          </div>
        );
        break;
      case LEADS_FAILURE:
        result = (
          <div className='scrollbar-pseudo-rail'>
            <ServerModalError />
          </div>
        );
        break;
      default:
        result = (
          <div className='scrollbar-pseudo-rail'>
            <EmptyMessage
              message={strings.formatString(
                strings.noItems,
                strings.leadPlaceholder
              )}
            />
          </div>
        );
    }
    return result;
  };

  return (
    <>
      <div className='header'>
        <button
          type='button'
          className='btn btn-white menu-toggler'
          onClick={toggleMenu}
        >
          <div className='menu-toggler-icon'>
            <span />
            <span />
            <span />
          </div>
        </button>
        <h3 className='title'>{strings.clients}</h3>
      </div>
      {renderSearchAndFilter()}

      {renderSituation()}

      {addNewPatientModal && (
        <AddPatientModal
          toggleModal={() => {
            setAddNewPatientModal(!addNewPatientModal);
          }}
        />
      )}
    </>
  );
};

LeadsList.propTypes = {
  setActivePage: PropTypes.func,
  leads: PropTypes.object,
  loadingStatus: PropTypes.string,
  statusActive: PropTypes.string,
  statuses: PropTypes.object,
  activeLead: PropTypes.object,
  activePage: PropTypes.number,
  searchEmitted: PropTypes.func,
  filterEmitted: PropTypes.func,
  search: PropTypes.object,
  getLeads: PropTypes.func,
  setCurrentLead: PropTypes.func,
  toggleMenu: PropTypes.func,
  leadSidebarActiveStatus: PropTypes.func,
  selectedCompany: PropTypes.object,
  displayLeadsForCompany: PropTypes.func,
  user: PropTypes.object,
};

export default LeadsList;
