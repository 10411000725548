import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import BudgetDisplay from './BudgetDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../constants/localization';
import {
  acceptDocumentation,
  getDocumentDetails,
  setCurrentDocumentationItem,
} from '../../../actions/onboardingDocumentationActions';
import InternalNote from './DocumentInternalNote';
import DocumentClientSection from './DocumentClientSection';
import DocumentsDisplay from './DocumentsDisplay';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import DentiModal from '../../common/DentiModal';
import ConfirmActionModal from '../../common/ConfirmationModal';

const DocumentationItem = ({ toggleMenu }) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );

  const documentDetails = useSelector(
    (state) => state.onboardingDocumentation?.documentDetails
  );

  const loadingItem = useSelector(
    (state) => state.onboardingDocumentation?.loadingDocumentationItem
  );

  const { documents, cpr, type } = activeDocListItem;

  useEffect(() => {
    if (documentDetails !== null) {
      setStatus(documentDetails?.data?.status || '');
    }
  }, [documentDetails]);

  useEffect(() => {
    if (documents) {
      setLoading(true);
      if (Array?.isArray(documents) && documents?.length > 0) {
        documents.forEach((doc) => {
          dispatch(getDocumentDetails(cpr, doc.id)).then((res) => {
            if (res.status === 200) {
              setLoading(false);
            }
          });
        });
      }
    }
  }, [documents]);

  const toggleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div className='DocumetationItemContent main-content'>
      {loadingItem && <LoadingMessage />}

      <div className='navigation-buttons'>
        <button
          type='button'
          className='btn btn-white go-back-to-list'
          onClick={() => dispatch(setCurrentDocumentationItem(undefined))}
        >
          <i className='lnil lnil-chevron-left'></i>
          <span>{strings.gotToDocumentList}</span>
        </button>

        <button
          type='button'
          className='btn btn-white menu-toggler'
          onClick={toggleMenu}
        >
          <div className='menu-toggler-icon'>
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
      <Row>
        <Col xs='12' lg='12'>
          <div className='entity-information-card custom-card custom-header'>
            <div className='header-information '>
              <div className='header-text'>
                <div className='card-title'>
                  <h1>{strings[type]}</h1>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='12'>
          <DocumentClientSection />
        </Col>
      </Row>
      <Row>
        <Col xs='12' lg='12'>
          <InternalNote />
        </Col>
      </Row>

      <Row className='budget-wrapper'>
        <Col className='col-12 col-md-7 d-flex flex-column'>
          {loading && <LoadingMessage />}
          <DocumentsDisplay setStatus={setStatus} status={status} />
        </Col>

        <Col className='col-12 col-md-5 d-flex flex-column'>
          <BudgetDisplay />
        </Col>
        {status === 'PENDING' && (
          <Col xs='12' lg='12'>
            <div className='mt-5 mb-5 text-center'>
              <Button
                color='blue'
                className='Btn-approveDocuments'
                onClick={toggleModalIsOpen}
              >
                <i className='lnir lnir-checkmark'></i>
                {strings.documentationBtnApproveDocuments}
              </Button>
            </div>
          </Col>
        )}
      </Row>
      {modalIsOpen && (
        <DentiModal
          key={1}
          close={toggleModalIsOpen}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmActionModal
            loading={loading}
            confirmAction={() => {
              setLoading(true);

              dispatch(acceptDocumentation(null, cpr, type))
                .then((res) => {
                  if (res.status === 200) {
                    setLoading(false);
                    setStatus('APPROVED');
                    setModalIsOpen(false);
                  }
                })
                .catch((err) => {
                  setLoading(false);

                  console.log(err);
                  setModalIsOpen(false);
                });
            }}
            cancelAction={toggleModalIsOpen}
          >
            <div>
              <p className='title'>
                {strings.documentationBtnApproveDocuments}
              </p>
              <p className='confirm-message mb-3'>
                {strings.documentationConfirmApproveDocuments}
              </p>
            </div>
          </ConfirmActionModal>
        </DentiModal>
      )}
    </div>
  );
};

export default DocumentationItem;
