import 'moment/locale/da';

export const SERVER_URL = process.env.REACT_APP_BACKEND_URL;
export const SOCKET_URL = `${SERVER_URL}/ws`;

// onboarding
export const SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA';
export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const SET_PREVIOUS_VISITED_STEP = 'SET_PREVIOUS_VISITED_STEP';
export const TRIGGERED_POP_ALLOWED = 'TRIGGERED_POP_ALLOWED';
export const STORE_ONBOARDING_VISITED_STEPS = 'STORE_ONBOARDING_VISITED_STEPS';
export const ADD_ERROR_LOG = 'ADD_ERROR_LOG';
export const CREATE_MEMBER = 'CREATE_MEMBER';
export const GET_NEW_MEMBER = 'GET_NEW_MEMBER';
export const GET_EXISTING_CLIENT = 'GET_EXISTING_CLIENT';
export const GET_EXISTING_MEMBER = 'GET_EXISTING_MEMBER';
export const CONFIRM_MEMBER_EMAIL = 'CONFIRM_MEMBER_EMAIL';
export const START_ONBOARDING = 'START_ONBOARDING';
export const STORE_BUDGET_TRANSACTION_ID = 'STORE_BUDGET_TRANSACTION_ID';

export const CHECK_ESKAT_CONSENT = 'CHECK_ESKAT_CONSENT';
export const CHECK_ESKAT_CONSENT_NO_CPR = 'CHECK_ESKAT_CONSENT_NO_CPR';
export const GET_USER_ID_LOOKUP_CLIENT_BY_CPR =
  'GET_USER_ID_LOOKUP_CLIENT_BY_CPR';
export const LOOK_UP_CLIENT_CREDIT_BY_CPR = 'LOOK_UP_CLIENT_CREDIT_BY_CPR';
export const GET_USER_ID_LOOKUP_CLIENT_BY_CPR_ERROR =
  'GET_USER_ID_LOOKUP_CLIENT_BY_CPR_ERROR';
export const UPLOAD_DOCUMENTATION_FILE = 'UPLOAD_DOCUMENTATION_FILE';
export const UPLOAD_NO_DOCUMENTS = 'UPLOAD_NO_DOCUMENTS';
export const UPDATE_CLIENT_NOTE = 'UPDATE_CLIENT_NOTE';
export const CONVERT_MEMBER_TO_CLIENT = 'CONVERT_MEMBER_TO_CLIENT';
export const SEND_DISPOSABLE_AMOUNT = 'SEND_DISPOSABLE_AMOUNT';
export const GET_PSD2_BANK_DATA = 'GET_PSD2_BANK_DATA';
export const LEAD_DOUBLER_CALCULATOR = 'LEAD_DOUBLER_CALCULATOR';
export const STORE_MISSING_BUDGET_ITEMS = 'STORE_MISSING_BUDGET_ITEMS';
export const STORE_UPLOADED_DOCUMENTATION = 'STORE_UPLOADED_DOCUMENTATION';
export const GET_COMPANIES_FOR_COMPANY_CHAIN_SMARTLINK =
  'GET_COMPANIES_FOR_COMPANY_CHAIN_SMARTLINK';
export const CREDIT_APPLICATION_DENIED_STEP = 'CREDIT_APPLICATION_DENIED_STEP';
export const CREDIT_APPLICATION_ABORTED_STEP =
  'CREDIT_APPLICATION_ABORTED_STEP';
export const CLEAR_ONBOADING_DATA = 'CLEAR_ONBOADING_DATA';
export const CLEAR_STORED_DOCUMENTS = 'CLEAR_STORED_DOCUMENTS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const GET_PUBLIC_CREDIT_APPLICATION_DATA =
  'GET_PUBLIC_CREDIT_APPLICATION_DATA';
export const SET_ONBOARDING_DATA_BASED_CREDIT_APPLICATION =
  'SET_ONBOARDING_DATA_BASED_CREDIT_APPLICATION';

// onboarding v2
export const ESKAT_V3_ENCRYPT_REQUEST = 'ESKAT_V3_ENCRYPT_REQUEST';
export const CREATE_MOTNHIIO_APPLICATION = 'CREATE_MOTNHIIO_APPLICATION';
export const RETRIEVE_MOTNHIIO_BUDGET = 'RETRIEVE_MOTNHIIO_BUDGET';
export const SEND_MANUAL_INTERVENTION_EMAIL = 'SEND_MANUAL_INTERVENTION_EMAIL';

// onboarding documentation
export const SET_CURRENT_DOCUMENTATION_ITEM = 'SET_CURRENT_DOCUMENTATION_ITEM';
export const ACCEPT_DOCUMENTATION_ITEM = 'ACCEPT_DOCUMENTATION_ITEM';
export const DENY_DOCUMENTATION_ITEM = 'DENY_DOCUMENTATION_ITEM';
export const UPDATE_DOCUMENTATION_INTERNAL_NOTE =
  'UPDATE_DOCUMENTATION_INTERNAL_NOTE';
export const GET_ALL_DOCUMENTS = 'GET_ALL_DOCUMENTS';
export const GET_COUNTER_PENDING_DOCUMENTS = 'GET_COUNTER_PENDING_DOCUMENTS';
export const GET_DOCUMENT_DETAILS = 'GET_DOCUMENT_DETAILS';
export const UPDATE_DOCUMENTATION_FILTER_PARAMS =
  'UPDATE_DOCUMENTATION_FILTER_PARAMS';
export const DOCUMENTATION_FAILURE = 'DOCUMENTATION_FAILURE';
export const DOCUMENTATION_SUCCESS = 'DOCUMENTATION_SUCCESS';
export const DOCUMENTATION_EMPTY = 'DOCUMENTATION_EMPTY';
export const DOCUMENTATION_LOADING = 'DOCUMENTATION_LOADING';
export const UPDATE_DOCUMENTATION_ITEM_FROM_WS =
  'UPDATE_DOCUMENTATION_ITEM_FROM_WS';
export const UPDATE_STORED_DOCUMENTS = 'UPDATE_STORED_DOCUMENTS';
export const UPDATE_STORED_CLIENT_NOTE = 'UPDATE_STORED_CLIENT_NOTE';
export const STORE_IS_GUIDE_VISITED = 'STORE_IS_GUIDE_VISITED';
export const SAVE_FINAL_UPDATED_BUDGET = 'SAVE_FINAL_UPDATED_BUDGET';
export const UPDATE_DOCUMENTATION_INFORMATION =
  'UPDATE_DOCUMENTATION_INFORMATION';
export const DELETE_UPLOADED_DOCUMENT = 'DELETE_UPLOADED_DOCUMENT';
export const SET_DOCUMENTATION_ITEM_LOADING = 'SET_DOCUMENTATION_ITEM_LOADING';
export const SET_UPLOAD_REQUIRED_FILES_FINISHED =
  'SET_UPLOAD_REQUIRED_FILES_FINISHED';
export const SET_DOCUMENTATION_ACTIVE_PAGE = 'SET_DOCUMENTATION_ACTIVE_PAGE';

// onboarding application
export const SET_CURRENT_APPLICATION_ITEM = 'SET_CURRENT_APPLICATION_ITEM';
export const SET_CURRENT_APPLICATION_UUID = 'SET_CURRENT_APPLICATION_UUID';
export const APPLICATIONS_LOADING = 'APPLICATIONS_LOADING';
export const GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS';
export const APPLICATIONS_EMPTY = 'APPLICATIONS_EMPTY';
export const APPLICATIONS_SUCCESS = 'APPLICATIONS_SUCCESS';
export const APPLICATIONS_FAILURE = 'APPLICATIONS_FAILURE';
export const UPDATE_APPLICATIONS_FILTER_PARAMS =
  'UPDATE_APPLICATIONS_FILTER_PARAMS';
export const GET_APPLICATION_DATA = 'GET_APPLICATION_DATA';
export const UPDATE_APPLICATION_IN_APPLICATION_LIST =
  'UPDATE_APPLICATION_IN_APPLICATION_LIST';
export const SET_APPLICATION_LOADING = 'SET_APPLICATION_LOADING';
export const GET_ESKAT_DATA_PREVIEW_LINK = 'GET_ESKAT_DATA_PREVIEW_LINK';
export const UPDATE_BUDGET_ITEM_FINAL = 'UPDATE_BUDGET_ITEM_FINAL';
export const APPROVE_CREDIT_APPLICATION = 'APPROVE_CREDIT_APPLICATION';
export const DENY_CREDIT_APPLICATION = 'DENY_CREDIT_APPLICATION';
export const SET_APPLICATION_ACTIVE_PAGE = 'SET_APPLICATION_ACTIVE_PAGE';
export const SET_APPLICATION_DENY_REASON = 'SET_APPLICATION_DENY_REASON';
export const DOWNLOAD_MONTHIO_PDF = 'DOWNLOAD_MONTHIO_PDF';
export const CONTINUE_CREDIT_APPLICATION = 'CONTINUE_CREDIT_APPLICATION';

// MEMBER PORTAL
export const CREATE_MIT_ID_STATE = 'CREATE_MIT_ID_STATE';
export const CREATE_MIT_ID_SESSION = 'CREATE_MIT_ID_SESSION';
export const GET_CLIENT_CREDIT_DATA_FOR_MEMBER_PORTAL =
  'GET_CLIENT_CREDIT_DATA_FOR_MEMBER_PORTAL';
export const LOADING_CLIENT_PORTAL_DATA = 'LOADING_CLIENT_PORTAL_DATA';
export const CLIENT_IS_AUTHENTICATED = 'CLIENT_IS_AUTHENTICATED';
export const CLEAR_MEMBER_PORTAL_DATA = 'CLEAR_MEMBER_PORTAL_DATA';
export const IS_CLIENT_LOGOUT = 'IS_CLIENT_LOGOUT';
export const IS_NOT_CLIENT = 'IS_NOT_CLIENT';
export const GET_CLIENT_PAYMENT_PLAN = 'GET_CLIENT_PAYMENT_PLAN';
export const ACCEPTANCE_FLOW_MODAL_STATE = 'ACCEPTANCE_FLOW_MODAL_STATE';
export const PARTIAL_REDEMPTION_RESPONSE = 'PARTIAL_REDEMPTION_RESPONSE';
export const MEMBER_SETTLE_LOAN = 'MEMBER_SETTLE_LOAN';

// Users
export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const SET_USER_CLEAN = 'SET_USER_CLEAN';
export const AUTH_USER = 'AUTH_USER';
export const ERROR_AUTH_USER = 'ERROR_AUTH_USER';
export const ERROR_USER_AUTHORITY = 'ERROR_USER_AUTHORITY';
export const ERROR_DETAILS_AUTH_USER = 'ERROR_DETAILS_AUTH_USER';
export const USER_LOCKDOWN = 'USER_LOCKDOWN';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOG_OUT = 'LOG_OUT';
export const LOG_IN = 'LOG_IN';
export const LANGUAGE_UPDATE = 'LANGUAGE_UPDATE';
export const UPDATE_CARD_DETAILS = 'UPDATE_CARD_DETAILS';
export const SEND_SUBSCRIPTION_DATA = 'SEND_SUBSCRIPTION_DATA';
export const GET_PENDING_USER = 'GET_PENDING_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const REINVITE_USER = 'REINVITE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SEND_USER_INVITATION = 'SEND_USER_INVITATION';
export const OPEN_SEND_LINK_APPLICATION_FROM_WELCOME_PAGE =
  'OPEN_SEND_LINK_APPLICATION_FROM_WELCOME_PAGE';

// search
export const PATIENT_SEARCH_EMITTED = 'PATIENT_SEARCH_EMITTED';
export const COMPANY_SEARCH_EMITTED = 'COMPANY_SEARCH_EMITTED';
export const COMPANY_CHAIN_SEARCH_EMITTED = 'COMPANY_CHAIN_SEARCH_EMITTED';

// Leads
export const GET_LEADS = 'GET_LEADS';
export const GET_LEADS_BY_CATEGORY_COUNT = 'GET_LEADS_BY_CATEGORY_COUNT';
export const SET_CURRENT_LEAD = 'SET_CURRENT_LEAD';
export const SET_OPEN_LEAD = 'SET_OPEN_LEAD';
export const UPDATE_LEAD_INFORMATION = 'UPDATE_LEAD_INFORMATION';
export const UPDATE_LEAD_STATUS = 'UPDATE_LEAD_STATUS';
export const UPDATE_LEAD_MEMBERSHIP_STATUS = 'UPDATE_LEAD_MEMBERSHIP_STATUS';
export const SET_LEAD_INTERNAL_NOTE = 'SET_LEAD_INTERNAL_NOTE';
export const SET_LEAD_ACTIVE_PAGE = 'SET_LEAD_ACTIVE_PAGE';
export const DELETE_LEAD = 'DELETE_LEAD';
export const DELETE_ONBOARDING_MEMBER = 'DELETE_ONBOARDING_MEMBER';
export const UPDATE_ACTIVE_PATIENT_INFO = 'UPDATE_ACTIVE_PATIENT_INFO';
export const UPDATE_ACTIVE_PATIENT_LEADS_LIST =
  'UPDATE_ACTIVE_PATIENT_LEADS_LIST';
export const UPDATE_ACTIVE_PATIENT_NOTE = 'UPDATE_ACTIVE_PATIENT_NOTE';
export const SET_LOAN_DETAILS = 'SET_LOAN_DETAILS';
export const SEND_RENEW_LINK_TO_PATIENT = 'SEND_RENEW_LINK_TO_PATIENT';
export const GET_CLIENT_DATA_FOR_PARTIAL_REDEMPTION =
  'GET_CLIENT_DATA_FOR_PARTIAL_REDEMPTION';

// filter
export const PATIENT_UPDATE_FILTER = 'PATIENT_UPDATE_FILTER';
export const COMPANY_UPDATE_FILTER = 'COMPANY_UPDATE_FILTER';
export const COMPANY_CHAIN_UPDATE_FILTER = 'COMPANY_CHAIN_UPDATE_FILTER';

export const loanFilterStatuses = [
  'WAITING_FOR_SIGNATURE',
  'ACCEPTED',
  'PAYED',
  'RESTRUCTURED',
  'LATE_PAYMENT',
  'SETTLED' /* 'DEBT_COLLECTION', */,
];

export const loanFilterStatusesNonAdmins = [
  'WAITING_FOR_SIGNATURE',
  'ACCEPTED',
];

export const patientFilterStatuses = ['ONBOARDING', 'MEMBER', 'CANCELLED'];
export const creditFacilityFilterStatuses = [
  'ACTIVE_CREDIT_UNEXPIRED',
  'ACTIVE_CREDIT_EXPIRED',
  'REJECTED',
  'SUSPENDED',
  'LATE_PAYMENT_CREDIT',
  'DISABLED',
];
export const creditFacilityFilterStatusesNonAdmins = [
  'ACTIVE_CREDIT_UNEXPIRED',
];

export const brandFilterStatuses = ['DENTI', 'SUNDHED'];
export const companyFilterStatuses = [
  'ACTIVE',
  'DEACTIVE',
  'PASSIVE',
  'REJECTED',
  'CLOSED',
];

export const statusArrayForCompanyAndCompanyChain = [
  'CLOSED',
  'PASSIVE',
  'DEACTIVE',
  'REJECTED',
  'ACTIVE',
];
export const smartLinkFilterStatuses = ['TRUE', 'FALSE'];

// documentation filter
export const documentationStatusFilter = ['APPROVED', 'PENDING', 'REJECTED'];
export const documentationTypeFilter = [
  'NET_INCOME_TOTAL',
  'HOUSING_BENEFIT',
  'CHILD_BENEFIT',
  'CHILDREN_CHECK_AMOUNT',
  'ADDITIONAL_INCOME',
  'HOUSE_LOAN_EXPENSES',
  'MORTGAGE_EXPENSES',
  'RENTAL_EXPENSES',
  'COMMON_FIXED_EXPENSES',
  'ELECTRICITY',
  'HEATING_EXPENSES',
  'WATER',
  'MAINTENANCE',
  'REAL_ESTATE_INSURANCE',
  'TRAIN_BUS_CARD',
  'TRANSPORT_FUEL_EXPENSES',
  'CAR_LEASING_COST',
  'CAR_LOAN',
  'CAR_INSURANCE',
  'CAR_MAINTENENCE',
  'CAR_OWNERSHIP_TAXES',
  'FDMAAA',
  'EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN',
  'STUDENT_LOAN_EXPENSES',
  'DebtSinceLastTaxReport',
  'SECURITY_SUBSCRIPTION',
  'CHILDREN_SUPPORT',
  'CHILDREN_COST',
  'HEALTH_INSURANCE',
  'INSURANCE',
  'INTERNET_SUBSCRIPTION',
  'PHONE_SUBSCRIPTION',
  'TV_SUBSCRIPTION',
  'FAGLIGT_KONTIGENT',
  'OTHER_EXPENSES_TO_PUBLIC_AUTHORITIES',
  'ADDITIONAL_EXPENSES',
  'HOUSEHOLD',
];

// items affected by split expenses
export const splitExpensesBudgetItems = [
  'HOUSE_LOAN_EXPENSES',
  'MORTGAGE_EXPENSES',
  'RENTAL_EXPENSES',
  'COMMON_FIXED_EXPENSES',
  'ELECTRICITY',
  'HEATING_EXPENSES',
  'WATER',
  'MAINTENANCE',
  'REAL_ESTATE_INSURANCE',
  'TRANSPORT_FUEL_EXPENSES',
  'CAR_LEASING_COST',
  'CAR_LOAN',
  'CAR_INSURANCE',
  'CAR_MAINTENENCE',
  'CAR_OWNERSHIP_TAXES',
  'FDMAAA',
  'EXPENSES_FOR_BANK_DEBT_FROM_TAX_RETURN',
  'STUDENT_LOAN_EXPENSES',
  'DebtSinceLastTaxReport',
  'SECURITY_SUBSCRIPTION',
  'CHILDREN_SUPPORT',
  'CHILDREN_COST',
  'HEALTH_INSURANCE',
  'INSURANCE',
  'INTERNET_SUBSCRIPTION',
  'PHONE_SUBSCRIPTION',
  'TV_SUBSCRIPTION',
  'FAGLIGT_KONTIGENT',
  'OTHER_EXPENSES_TO_PUBLIC_AUTHORITIES',
  'ADDITIONAL_EXPENSES',
];

// application filter
export const applicationStatusFilter = [
  'IN_PROGRESS',
  'FAILED',
  'REJECTED',
  'DEPRECATED',
  'PENDING_DOCUMENTATION',
  'PENDING_ACTIVATION',
  'PENDING_MANUAL',
  'DONE',
];

// Booking Request

export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const BOOKING_REQUEST_LINK_EXPIRED = 'BOOKING_REQUEST_LINK_EXPIRED';
export const BOOKING_REQUEST_ERROR = 'BOOKING_REQUEST_ERROR';
export const GET_BOOKING_STATUS = 'GET_BOOKING_STATUS';
export const DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST';
export const RESEND_BOOKING_REQUEST = 'RESEND_BOOKING_REQUEST';
export const GET_UNSUBSCRIBE_BOOKING_REQUEST =
  'GET_UNSUBSCRIBE_BOOKING_REQUEST';
export const STOP_BOOKING_REQUEST = 'STOP_BOOKING_REQUEST';

// Companies
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_BY_CATEGORY_COUNT =
  'GET_COMPANIES_BY_CATEGORY_COUNT';
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING';
export const GET_SIMPLE_SEARCH_CLINIC_PAGINATED =
  'GET_SIMPLE_SEARCH_CLINIC_PAGINATED';
export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES';
export const GET_ALL_FILTERED_COMPANIES = 'GET_ALL_FILTERED_COMPANIES';
export const GET_COMPANIES_LIST_FOR_MANAGERS =
  'GET_COMPANIES_LIST_FOR_MANAGERS';
export const GET_ALL_COMPANIES_LIST_FOR_MANAGERS =
  'GET_ALL_COMPANIES_LIST_FOR_MANAGERS';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const SET_CURRENT_MANAGER_COMPANY = 'SET_CURRENT_MANAGER_COMPANY';
export const GET_CURRENT_DENTIST_COMPANY = 'GET_CURRENT_DENTIST_COMPANY';
export const UPDATE_DENTIST_COMPANY_STATUS = 'UPDATE_DENTIST_COMPANY_STATUS';
export const UPDATE_COMPANY_PROFILE_FOR_MANAGER =
  'UPDATE_COMPANY_PROFILE_FOR_MANAGER';
export const UPDATE_ACTIVE_COMPANY_ITEM_LIST =
  'UPDATE_ACTIVE_COMPANY_ITEM_LIST';
export const UPDATE_COMPANY_MANAGER_STATUS = 'UPDATE_COMPANY_MANAGER_STATUS';
export const UPDATE_COMPANY_MANAGER_LIST = 'UPDATE_COMPANY_MANAGER_LIST';
export const UPDATE_COMPANY_INFORMATION = 'UPDATE_COMPANY_INFORMATION';
export const UPDATE_COMPANY_INFORMATION_FOR_MANAGER =
  'UPDATE_COMPANY_INFORMATION_FOR_MANAGER';
export const UPDATE_COMPANY_STATUS = 'UPDATE_COMPANY_STATUS';
export const UPDATE_DUNNING_STATUS = 'UPDATE_DUNNING_STATUS';
export const UPDATE_ASSIGNED_USER = 'UPDATE_ASSIGNED_USER';
export const UPDATE_CREDIT_DUNNING_TYPE = 'UPDATE_CREDIT_DUNNING_TYPE';
export const UPDATE_CREDIT_DETAILS = 'UPDATE_CREDIT_DETAILS';
export const SET_COMPANY_ACTIVE_PAGE = 'SET_COMPANY_ACTIVE_PAGE';
export const SET_COMPANY_INTERNAL_NOTE = 'SET_COMPANY_INTERNAL_NOTE';
export const DISPLAY_LEADS_FOR_COMPANY = 'DISPLAY_LEADS_FOR_COMPANY';
export const UPDATE_CREDIT_STATUS = 'UPDATE_CREDIT_STATUS';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const ADD_TO_BLACKLIST = 'ADD_TO_BLACKLIST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const GRANT_CLINIC_ACCESS = 'GRANT_CLINIC_ACCESS';
export const UPDATE_COMPANY_CHAIN_OF_COMPANY =
  'UPDATE_COMPANY_CHAIN_OF_COMPANY';

export const SET_CURRENT_COMPANY_REF_FROM_ROUTER =
  'SET_CURRENT_COMPANY_REF_FROM_ROUTER';
export const DELETE_COMPANY_FROM_CREDIT_FACILITY =
  'DELETE_COMPANY_FROM_CREDIT_FACILITY';
export const UPDATE_SUPPORT_DECIDED_STATUS = 'UPDATE_SUPPORT_DECIDED_STATUS';
export const UPDATE_CLINIC_DECIDED_STATUS = 'UPDATE_CLINIC_DECIDED_STATUS';
export const UPDATE_ALLOW_BOOKING_REQUESTS_STATUS =
  'UPDATE_ALLOW_BOOKING_REQUESTS_STATUS';
export const UPDATE_LEAD_INTERNAL_NOTE_SUPPORT =
  'UPDATE_LEAD_INTERNAL_NOTE_SUPPORT';
export const GET_PATIENT_DATA_SUPPORT = 'GET_PATIENT_DATA_SUPPORT';
export const ADD_EMAIL_SEGMENT_USER_ASSOCIATION =
  'ADD_EMAIL_SEGMENT_USER_ASSOCIATION';
export const REMOVE_EMAIL_SEGMENT_USER_ASSOCIATION =
  'REMOVE_EMAIL_SEGMENT_USER_ASSOCIATION';

export const UPDATE_CLIENT_INFO = 'UPDATE_CLIENT_INFO';
export const SET_CLIENT_LOADING = 'SET_CLIENT_LOADING';
export const ADD_WS_PATIENT_UPDATE = 'ADD_WS_PATIENT_UPDATE';
export const REMOVE_WS_PATIENT_UPDATE = 'REMOVE_WS_PATIENT_UPDATE';

// loading statuses
export const LEADS_LOADING = 'LEADS_LOADING';
export const LEADS_EMPTY = 'LEADS_EMPTY';
export const LEADS_SUCCESS = 'LEADS_SUCCESS';
export const LEADS_FAILURE = 'LEADS_FAILURE';
export const EDIT_LEAD_LOADING = 'EDIT_LEAD_LOADING';
export const GET_LEAD_BY_CREDIT_FACILITY_ID = 'GET_LEAD_BY_CREDIT_FACILITY_ID';

// companies
export const COMPANIES_LOADING = 'COMPANIES_LOADING';
export const COMPANIES_EMPTY = 'COMPANIES_EMPTY';
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS';
export const COMPANIES_FAILURE = 'COMPANIES_FAILURE';
export const EDIT_COMPANY_LOADING = 'EDIT_COMPANY_LOADING';
export const GET_COMPANIES_FOR_CHAIN = 'GET_COMPANIES_FOR_CHAIN';

// companyChain
export const GET_COMPANY_CHAINS = 'GET_COMPANY_CHAINS';
export const SET_CURRENT_CHAIN = 'SET_CURRENT_CHAIN';
export const SET_CHAIN_ACTIVE_PAGE = 'SET_CHAIN_ACTIVE_PAGE';
export const SET_CHAIN_LOADING = 'SET_CHAIN_LOADING';
export const CHAIN_LOCATIONS_LOADING = 'CHAIN_LOCATIONS_LOADING';
export const CHAIN_USERS_LOADING = 'CHAIN_USERS_LOADING';
export const GET_USERS_ASSOCIATED = 'GET_USERS_ASSOCIATED';
export const SET_LOADING = 'SET_LOADING';
export const GET_COMPANY_CHAIN_LOGS = 'GET_COMPANY_CHAIN_LOGS';
export const GET_ALL_PUBLIC_COMPANY_CATEGORIES =
  'GET_ALL_PUBLIC_COMPANY_CATEGORIES';
export const GET_COMPANY_CHAIN_STATUS = 'GET_COMPANY_CHAIN_STATUS';
export const COMPANY_CHAINS_LOADING = 'COMPANY_CHAINS_LOADING';
export const EDIT_COMPANY_CHAINS_LOADING = 'EDIT_COMPANY_CHAINS_LOADING';
export const COMPANY_CHAINS_SUCCESS = 'COMPANY_CHAINS_SUCCESS';
export const COMPANY_CHAINS_EMPTY = 'COMPANY_CHAINS_EMPTY';
export const COMPANY_CHAINS_FAILURE = 'COMPANY_CHAINS_FAILURE';
export const GET_MARKETING_FEE_DENTI = 'GET_MARKETING_FEE_DENTI';
export const GET_MARKETING_FEE_SUNDHED = 'GET_MARKETING_FEE_SUNDHED';
export const GET_ALL_FILTERED_CHAINS = 'GET_ALL_FILTERED_CHAINS';
export const GET_CHAIN_LOCATIONS = 'GET_CHAIN_LOCATIONS';
export const GET_CHAIN_ASSOCIATED_USERS = 'GET_CHAIN_ASSOCIATED_USERS';
export const ADD_NEW_COMPANY_CHAIN = 'ADD_NEW_COMPANY_CHAIN';
export const DELETE_COMPANY_CHAIN_WITH_COMPANIES =
  'DELETE_COMPANY_CHAIN_WITH_COMPANIES';
export const UPDATE_COMPANY_CHAIN_INFO = 'UPDATE_COMPANY_CHAIN_INFO';
export const ADD_NEW_COMPANY_LOCATION = 'ADD_NEW_COMPANY_LOCATION';
export const UPDATE_COMPANY_CHAIN_STATUS = 'UPDATE_COMPANY_CHAIN_STATUS';
export const UPDATE_COMPANY_CHAIN_STATUS_FROM_COMPANY =
  'UPDATE_COMPANY_CHAIN_STATUS_FROM_COMPANY';
export const UPDATE_COMPANY_STATUS_IN_ACTIVE_CHAIN =
  'UPDATE_COMPANY_STATUS_IN_ACTIVE_CHAIN';
export const UPDATE_CHAIN_INTERNAL_NOTE = 'UPDATE_CHAIN_INTERNAL_NOTE';
export const UPDATE_ACTIVE_CHAIN_IN_LIST = 'UPDATE_ACTIVE_CHAIN_IN_LIST';

// Invoices
export const CREATE_INSTALLMENT_LOAN_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const DELETE_INSTALLMENT_LOAN = 'DELETE_INSTALLMENT_LOAN';
export const RESEND_SMS = 'RESEND_SMS';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const GET_INSTALLMENT = 'GET_INSTALLMENT';
export const GET_LOAN_DETAILS = 'GET_LOAN_DETAILS';
export const CLONE_INVOICE = 'CLONE_INVOICE';

export const GET_DEBT_COLLECTION_LOANS = 'GET_DEBT_COLLECTION_LOANS';
export const REGISTER_DEBT_COLLECTION_LOANS = 'REGISTER_DEBT_COLLECTION_LOANS';
export const SET_LOAN_ACTIVE_PAGE = 'SET_LOAN_ACTIVE_PAGE';
export const GET_DEBT_COLLECTION_ENTRIES = 'GET_DEBT_COLLECTION_ENTRIES';
export const SETTLE_LOAN = 'SETTLE_LOAN';
export const SETTLE_INVOICE = 'SETTLE_INVOICE';

// Register Invoice Flow

export const PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND';
export const PATIENT_FOUNDED = 'PATIENT_FOUNDED';
export const GET_PATIENT_INFORMATION = 'GET_PATIENT_INFORMATION';
export const GET_PATIENT_INFORMATION_ERROR = 'GET_PATIENT_INFORMATION_ERROR';
export const LOADING_PATIENT_INFORMATION = 'LOADING_PATIENT_INFORMATION';
export const SET_SHOW_SMS_MODAL_FOR_REACTIVATION_CREDIT =
  'SET_SHOW_SMS_MODAL_FOR_REACTIVATION_CREDIT';
export const SET_SHOW_SMS_MODAL_FOR_ACCEPTANCE_INVOICE_FLOW =
  'SET_SHOW_SMS_MODAL_FOR_ACCEPTANCE_INVOICE_FLOW';

// accept loan
export const REQUEST_ACCEPT_LOAN_DETAILS = 'REQUEST_ACCEPT_LOAN_DETAILS';
export const DECLINE_ACCEPT_LOAN = 'DECLINE_ACCPET_LOAN';
export const COMFIRM_ACCEPT_LOAN = 'COMFIRM_ACCEPT_LOAN';
export const SEND_AGREEMENT = 'SEND_AGREEMENT';
export const EXISTS_ACCEPT_LOAN = 'EXISTS_ACCEPT_LOAN';
export const CREATE_CUSTOMER_SESSIONID_REEPAY = 'CREATE_USER_SESSIONID_REEPAY';
export const REMOVE_CUSTOMER_REEPAY = 'REMOVE_CUSTOMER_REEPAY';
export const RECALCULATE_VALUES = 'RECALCULARTE_VALUES';
export const GET_LEAD_CLINICS = 'GET_LEAD_CLINICS';
export const GET_LOAN_SIGNATURE_DATA = 'GET_LOAN_SIGNATURE_DATA';

// patients dunning
export const PATIENTS_DUNNING_LOADING = 'PATIENTS_DUNNING_LOADING';
export const PATIENTS_DUNNING_EMPTY = 'PATIENTS_DUNNING_EMPTY';
export const PATIENTS_DUNNING_SUCCESS = 'PATIENTS_DUNNING_SUCCESS';
export const PATIENTS_DUNNING_FAILURE = 'PATIENTS_DUNNING_FAILURE';
export const GET_ALL_PATIENTS = 'GET_ALL_PATIENTS';
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT';
export const GET_GLOBAL_PATIENTS_DATA = 'GET_GLOBAL_PATIENTS_DATA';
export const EXPORT_CSV_FILE_START = 'EXPORT_CSV_FILE_START';
export const EXPORT_CSV_FILE_FINISH = 'EXPORT_CSV_FILE_FINISH';
export const EXPORT_CSV_FILE_FAIL = 'EXPORT_CSV_FILE_FAIL';
export const SET_ACTIVE_PAGE_PATIENTS = 'SET_ACTIVE_PAGE_PATIENTS';
export const GET_NEW_DUNNING_PATIENTS = 'GET_NEW_DUNNING_PATIENTS';
export const GET_PAGINATED_DUNNING_PATIENTS = 'GET_PAGINATED_DUNNING_PATIENTS';
export const UPDATE_PATIENTS_DUNNING_FILTER_PARAMS =
  'UPDATE_PATIENTS_DUNNING_FILTER_PARAMS';
export const DUNNING_PATIENTS_SEARCH_EMITTED =
  'DUNNING_PATIENTS_SEARCH_EMITTED';
export const UPDATE_ACTIVE_PATIENT_DUNNING_LIST =
  'UPDATE_ACTIVE_PATIENT_DUNNING_LIST';

// add patient
export const ADD_PATIENT = 'ADD_PATIENT';

// live agent plugin
export const GET_BOOKING_REQUEST_DATA = 'GET_BOOKING_REQUEST_DATA';

// logs
export const GET_LOGS_BY_CREDIT_FACILITY = 'GET_LOGS_BY_CREDIT_FACILITY';
export const GET_LOANS_BY_CREDIT_FACILITY = 'GET_LOANS_BY_CREDIT_FACILITY';
export const GET_LOGS_BY_COMPANIES = 'GET_LOGS_BY_COMPANIES';

export const GET_ALL_ADMINS = 'GET_ALL_ADMINS';
export const UPDATE_RESTRUCTURE_LOAN = 'UPDATE_RESTRUCTURE_LOAN';

export const minLoanAllowed = 500;
export const maxLoanAllowed = 100000;
export const bonusIncrease = 1.1;
export const expirationTime = 12 * 3600 * 1000; // 12 hours in miliseconds
export const notificationDismissDuration = 4; // seconds

// ui server modal error
export const TOGGLE_MODAL_ERROR = 'TOGGLE_MODAL_ERROR';
export const SET_SERVER_MODAL_ERROR = 'SET_SERVER_MODAL_ERROR';
export const SET_SESSION_EXPIRED_WARNING = 'SET_SESSION_EXPIRED_WARNING';
export const INCREMENT_ONGOING_REQUESTS_COUNTER =
  'INCREMENT_ONGOING_REQUESTS_COUNTER';
export const DECREMENT_ONGOING_REQUESTS_COUNTER =
  'DECREMENT_ONGOING_REQUESTS_COUNTER';

// migrate
export const GET_MIGRATING_CLIENT = 'GET_MIGRATING_CLIENT';
export const GET_USER_DATA_FOR_CARD_UPDATE = 'GET_USER_DATA_FOR_CARD_UPDATE';
export const CREATE_SUNHEDPLUS_USER_AND_GET_SESSION_ID =
  'CREATE_SUNHEDPLUS_USER_AND_GET_SESSION_ID';
export const MIGRATE_USER_FROM_DENTI_TO_SUNDHED =
  'MIGRATE_USER_FROM_DENTI_TO_SUNDHED';

// websocket messages
export const ADD_CLIENT_WEBSOCKET_MESSAGE = 'ADD_CLIENT_WEBSOCKET_MESSAGE';
export const REMOVE_CLIENT_WEBSOCKET_MESSAGE =
  'REMOVE_CLIENT_WEBSOCKET_MESSAGE';
export const ADD_CLINIC_WEBSOCKET_MESSAGE = 'ADD_CLINIC_WEBSOCKET_MESSAGE';
export const REMOVE_CLINIC_WEBSOCKET_MESSAGE =
  'REMOVE_CLINIC_WEBSOCKET_MESSAGE';
export const ADD_DOCUMENTATION_WEBSOCKET_MESSAGE =
  'ADD_DOCUMENTATION_WEBSOCKET_MESSAGE';
export const REMOVE_DOCUMENTATION_WEBSOCKET_MESSAGE =
  'REMOVE_DOCUMENTATION_WEBSOCKET_MESSAGE';
export const ADD_CLIENT_PORTAL_WEBSOCKET_MESSAGE =
  'ADD_CLIENT_PORTAL_WEBSOCKET_MESSAGE';
export const REMOVE_CLIENT_PORTAL_WEBSOCKET_MESSAGE =
  'REMOVE_CLIENT_PORTAL_WEBSOCKET_MESSAGE';

// moment js
// eslint-disable-next-line
export const moment = require('moment');
export const momentFormat = 'DD.MM.YYYY';
export const momentFormatLong = 'DD. MMM YYYY';

// statuses
export const GET_LEAD_STATUSES = 'GET_LEAD_STATUSES';
export const SET_LEAD_ACTIVE_STATUS = 'SET_LEAD_ACTIVE_STATUS';
export const GET_COMPANY_STATUSES = 'GET_COMPANY_STATUSES';
export const SET_COMPANY_ACTIVE_STATUS = 'SET_COMPANY_ACTIVE_STATUS';
export const GET_COMPANY_CHAIN_STATUSES = 'GET_COMPANY_CHAIN_STATUSES';
export const SET_COMPANY_CHAIN_ACTIVE_STATUS =
  'SET_COMPANY_CHAIN_ACTIVE_STATUS';

export const VIEW_ALL = 'VIEW_ALL';
export const REQUIRES_ACTION = 'REQUIRES_ACTION';
export const UNTREATED = 'UNTREATED';
export const DEPRECATED = 'DEPRECATED';
export const CLIENT_MAKES_CONTACT = 'CLIENT_MAKES_CONTACT';
export const TRIED_CONTACTED = 'TRIED_CONTACTED';
export const HAS_BOOKED_TIME = 'HAS_BOOKED_TIME';
export const ONGOING_TREATMENT = 'ONGOING_TREATMENT';
export const COMPLETED_WITH_TREATMENT = 'COMPLETED_WITH_TREATMENT';
export const COMPLETED_WITHOUT_TREATMENT = 'COMPLETED_WITHOUT_TREATMENT';

export const LEAD_STATUS_ORDER = [
  REQUIRES_ACTION,
  UNTREATED,
  CLIENT_MAKES_CONTACT,
  TRIED_CONTACTED,
  HAS_BOOKED_TIME,
  ONGOING_TREATMENT,
  COMPLETED_WITH_TREATMENT,
  COMPLETED_WITHOUT_TREATMENT,
];

export const SMARTLINK = 'SMARTLINK';
export const ACTIVE = 'ACTIVE';
export const DEACTIVE = 'DEACTIVE';
export const PASSIVE = 'PASSIVE';

export const COMPANY_STATUS_ORDER = [SMARTLINK, ACTIVE, PASSIVE, DEACTIVE];

// create Credit
export const CREATE_CREDIT_EMAIL = 'CREATE_CREDIT_EMAIL';
export const CREATE_CREDIT_EMAIL_MODAL = 'CREATE_CREDIT_EMAIL_MODAL';
export const CREATE_CREDIT_SMS = 'CREATE_CREDIT_SMS';
export const CREATE_CREDIT_SMS_MODAL = 'CREATE_CREDIT_SMS_MODAL';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_DENTIST = 'ROLE_DENTIST';
export const ROLE_MANAGER = 'ROLE_MANAGER';

export const CURRENCY_FORMAT = 'kr.';
