import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardBody, CardDeck, Button } from 'reactstrap';
import './style.scss';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { PATIENTS_DUNNING_FAILURE } from '../../constants';
import {
  getPatients,
  exportCsvFile,
  setCurrentPatient,
  getDunningClientsTotals,
  updatePatientDunningFilterParams,
  searchEmitted,
} from '../../actions/patientDunningActions';
import { getLeadStatuses } from '../../actions/statusActions';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import { strings } from '../../constants/localization';
import { currencyFormatDA, isAuthenticated } from '../../constants/utils';
import ServerModalError from '../common/ServerModalError';
import PatientsList from './PatientsList';
import ExportConfirmationModal from '../common/ExportConfirmationModal';
import DentiModal from '../common/DentiModal';
import LoadingGeneratingFile from './LoadingGeneratingFile';
import { ReactComponent as ExportIcon } from '../../resources/icons/export-icon.svg';
import PatientDetails from './PatientDetails';
import SearchAndFilter from '../common/SearchAndFilter/SearchAndFilter';
import PatientsDunningFilterMenu from './PatientsDunningFilterMenu';
import LoadingMessage from '../statusMessages/LoadingMessage';

const PatientsDunning = ({ toggleMenu }) => {
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingTotals, setLoadingTotals] = useState(true);
  const [loadingPatients, setLoadingPatients] = useState(true);
  const [abortController, setAbortController] = useState(new AbortController());
  const [stopRenderingMorePatients, setStopRenderingMorePatients] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fileExporting, setFileExporting] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [resultTotals, setResultTotals] = useState({});
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [clearFilterValue, setClearFilterValue] = useState('');
  const [triggerClearFilterEffect, setTriggerClearFilterEffect] =
    useState(false);
  const loadingStatus = useSelector((state) => state.patientsDunning.loading);
  const [patientsContainer, setPatientsContainer] = useState([]);
  const dunningFilters = useSelector(
    (state) => state.patientsDunning?.filterParams
  );
  const searchActive = useSelector(
    (state) => state.patientsDunning?.searchActive
  );
  const [searchActiveLocal, setSearchActiveLocal] = useState(null);
  const [totalClientsFiltered, setTotalClientsFiltered] = useState(null);
  // get from the filters only an aray of labels that will be used for active filters tags
  let dunningFilterLabels = [];
  if (dunningFilters) {
    dunningFilterLabels = Object.values(dunningFilters).filter((item) =>
      item?.label ? true : false
    );
  }
  const prevFilters = useRef();

  // funtion to get a new set of patients
  const getPatientsRequest = (updatedPage, totalClientFiltered) => {
    try {
      if (updatedPage === 0) {
        // abort any pending requests
        if (abortController) {
          abortController.abort();
        }
      }
      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      dispatch(getPatients(updatedPage, newAbortController.signal))
        .then((response) => {
          setLoadingPatients(false);
          // in the case we have filters, we need the totalFilteredClients in order not to show ?... in the results
          if (totalClientFiltered) {
            setTotalClientsFiltered(response?.totalClientsInDunning);
          }

          // in case there's a request from the page 0, then we need to make sure the previous patients are removed
          const newPatientsList =
            updatedPage === 0
              ? response?.clientInDunningList
              : patientsContainer.concat(response?.clientInDunningList || []);
          const newPatientsListLength = newPatientsList.length;
          setPatientsContainer([...newPatientsList]);

          // if the search is with filters, then we have the totalClientsInDunning and we can check against that
          // oterwise we need to check against the totalClientsInDunning from the totals endpoint
          if (
            (dunningFilters && Object.keys(dunningFilters)?.length > 0) ||
            searchActive?.searchInput
          ) {
            if (newPatientsListLength < response?.totalClientsInDunning) {
              setActivePage(updatedPage + 1);
            } else {
              setStopRenderingMorePatients(true);
            }
          } else if (
            newPatientsListLength < resultTotals?.totalClientsInDunning
          ) {
            setActivePage(updatedPage + 1);
          } else {
            setStopRenderingMorePatients(true);
          }
        })
        .catch(() => {
          setLoadingPatients(false);
        });
    } catch (error) {
      console.error('Error getting more patients:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      // when the page is loaded, get the totals
      dispatch(getDunningClientsTotals()).then((response) => {
        setLoadingTotals(false);
        setResultTotals(response?.data);
        dispatch(getLeadStatuses());
      });
    }
    return () => {
      dispatch(setCurrentPatient());
    };
  }, []);

  // //if the totals are loaded, then get the patients
  useEffect(() => {
    if (!loadingTotals && resultTotals) {
      // in case there are stored filters when page is loaded, get the patients with the filters
      if (
        (dunningFilters && Object.keys(dunningFilters)?.length > 0) ||
        searchActive?.searchInput
      ) {
        getPatientsRequest(0, true);
      } else {
        getPatientsRequest(0);
      }
    }
  }, [resultTotals]);

  useEffect(() => {
    if (!loadingTotals && dunningFilters) {
      setStopRenderingMorePatients(false);
      setLoadingPatients(true);
      setPatientsContainer([]);
      //workaround to fix the issue when clearing all filters that triggered the call with empty filters being called twice
      if (
        JSON.stringify(dunningFilters) !== JSON.stringify(prevFilters.current)
      ) {
        getPatientsRequest(0, true);
      }
      prevFilters.current = dunningFilters;
    }
  }, [dunningFilters]);

  useEffect(() => {
    // this check is to get the previous version, so the search is not triggered on the first render, but only when the searchActive is updated
    if (searchActiveLocal) {
      setStopRenderingMorePatients(false);
      setLoadingPatients(true);
      setPatientsContainer([]);
      getPatientsRequest(0, true);
      setSearchActiveLocal(searchActive);
    }
  }, [searchActiveLocal]);

  useEffect(() => {
    if (activePage > 0) {
      getPatientsRequest(activePage);
    }
  }, [activePage]);

  const toggleModal = () => {
    // if (!showConfirmModal) {
    //   dispatch(getPatients());
    // }
    setShowConfirmModal(!showConfirmModal);
    setLoading(false);
  };

  const confirmAction = () => {
    setFileExporting(true);
    dispatch(
      exportCsvFile(() => {
        //dispatch(getPatients(noFiltersActivePage));
        setShowConfirmModal(false);
        setLoading(false);
        setFileExporting(false);
      })
    );
  };

  const removeFilter = useCallback((tag) => {
    let formValueToRemove;
    setPatientsContainer([]);
    if (tag === 'all') {
      setClearFilterValue(tag);
      setTriggerClearFilterEffect((prevTrigger) => !prevTrigger);
      dispatch(updatePatientDunningFilterParams({}));
    } else if (tag === 'reload') {
      getPatientsRequest(0, true);
    } else {
      const filterParamsUpdated = { ...dunningFilters };

      Object.keys(filterParamsUpdated).forEach((key) => {
        if (filterParamsUpdated[key].label === tag.label) {
          formValueToRemove = key;
          delete filterParamsUpdated[key];
        }
      });

      setClearFilterValue(formValueToRemove);
      setTriggerClearFilterEffect((prevTrigger) => !prevTrigger);
      dispatch(updatePatientDunningFilterParams(filterParamsUpdated));
    }
  }, []);

  const searchSubmit = (searchObject) => {
    dispatch(searchEmitted(searchObject));
    setSearchActiveLocal(searchObject);
  };

  const handleDataCardsRender = () => {
    const {
      totalClientsInDunning,
      totalLoansInDunning,
      totalDunningAmount,
      totalRemainingBalance,
      totalClientsInDebtCollection,
      totalLoansInDebtCollection,
    } = resultTotals || {};
    if (Object.keys(resultTotals).length > 0) {
      return (
        <div className='data-cards'>
          <CardDeck>
            <Card>
              <CardBody className='data-amount'>
                <div className='data-label'>{strings.loansInDunning}</div>
                <div className='data-value'>{totalLoansInDunning || 0}</div>
              </CardBody>
            </Card>
            <Card className='data-amount'>
              <CardBody>
                <div className='data-label'>{strings.personInDunning}</div>
                <div className='data-value'>{totalClientsInDunning || 0}</div>
              </CardBody>
            </Card>

            <Card className='data-amount'>
              <CardBody>
                <div className='data-label'>{strings.amountInDunning}</div>
                <div className='data-value'>
                  {currencyFormatDA(totalDunningAmount || 0, true, true)}
                </div>
              </CardBody>
            </Card>

            <Card className='data-amount'>
              <CardBody>
                <div className='data-label'>{strings.totalDuning}</div>
                <div className='data-value'>
                  {currencyFormatDA(totalRemainingBalance || 0, true, true)}
                </div>
              </CardBody>
            </Card>
          </CardDeck>
          <div>
            <Button
              color='blue'
              className='btn-export-csv'
              rounded='true'
              onClick={() => toggleModal()}
              disabled={
                fileExporting ||
                totalClientsInDebtCollection === 0 ||
                totalLoansInDebtCollection === 0
              }
            >
              <ExportIcon className='icon' />
              <span>{strings.exportDebtCollection}</span>
            </Button>
          </div>
        </div>
      );
    } else return null;
  };

  const renderSearchAndFilter = () => {
    let resultToDisplay;
    if (
      (dunningFilters && Object.keys(dunningFilters)?.length > 0) ||
      searchActive?.searchInput
    ) {
      // If there are dunning filters or an active search
      if (stopRenderingMorePatients) {
        // If stopRenderingMorePatients is true, display the total patients
        resultToDisplay = patientsContainer.length || 0;
      } else {
        // If stopRenderingMorePatients is false, display a placeholder like '?...'
        resultToDisplay = totalClientsFiltered ? totalClientsFiltered : '?...';
      }
    } else {
      // If no dunning filters or active search, display the total clients in dunning
      resultToDisplay = resultTotals?.totalClientsInDunning || 0;
    }

    return (
      <div className='search-filter-dunning'>
        <SearchAndFilter
          searchBarPlaceholder={strings.formatString(
            strings.searchPlaceholder,
            strings.clientsPlaceholder
          )}
          storedSearchKeyword={searchActive?.searchInput}
          filterMenuOpen={filterMenuOpen}
          toggleFilterMenu={() => setFilterMenuOpen(!filterMenuOpen)}
          searchSubmit={searchSubmit}
          filterTagsArray={dunningFilterLabels}
          removeFilter={removeFilter}
        >
          <PatientsDunningFilterMenu
            clearFilterValue={clearFilterValue}
            triggerClearFilterEffect={triggerClearFilterEffect} // used as a signal to trigger the update even if the filter tag has the same name
            closeFilterMenu={() => setFilterMenuOpen(false)}
          />
        </SearchAndFilter>

        {!loadingPatients && (
          <div className='search-results-counter'>
            {strings.formatString(
              strings.patientsDunningsResults,
              patientsContainer.length || 0,
              resultToDisplay
            )}
          </div>
        )}
      </div>
    );
  };

  const patientsListComponent = useMemo(
    () => (
      <PatientsList
        empty={stopRenderingMorePatients && patientsContainer.length === 0}
        loadingPatients={loadingPatients}
      />
    ),
    [patientsContainer]
  );

  const handlePatientListRender = () => {
    if (loadingTotals) return <LoadingMessage />;
    return (
      <>
        <Card className='patient-list'>
          {renderSearchAndFilter()}
          <CardBody>{patientsListComponent}</CardBody>
        </Card>
        <PatientDetails />
        {loadingStatus === PATIENTS_DUNNING_FAILURE && <ServerModalError />}
      </>
    );
  };

  return (
    <>
      <Row className='app-row-height entities-list-section dunning-page '>
        <Col className='p-0 app-col-height'>
          <Scrollbars
            ref={scrollRef}
            style={{
              height: window.innerHeight + 'px',
            }}
            //onScroll={handleScroll}
            // eslint-disable-next-line
            renderTrackVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  ...trackVerticalStyle,
                }}
              />
            )}
            // eslint-disable-next-line
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalStyle,
                }}
              />
            )}
          >
            <div className='header'>
              <button
                type='button'
                className='btn btn-white menu-toggler'
                onClick={toggleMenu}
              >
                <div className='menu-toggler-icon'>
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            </div>
            <div className='patients-dunning-section'>
              <div className='entity-information-card'>
                <Card className='custom-card'>
                  <CardBody>
                    <div className='header-information'>
                      <div className='header-text'>
                        <div className='card-title'>
                          {strings.loansInDunning}
                        </div>
                      </div>
                    </div>

                    {handleDataCardsRender()}
                  </CardBody>
                </Card>
              </div>

              {handlePatientListRender()}
            </div>
          </Scrollbars>
        </Col>
      </Row>
      {loading && (
        <div className='modal confirm-action modal-container denti-modal'>
          <div className='modal-content modalSize m-auto align-items-center justify-content-center'>
            <LoadingGeneratingFile message={strings.generatingFile} />
          </div>
        </div>
      )}

      {showConfirmModal && (
        <DentiModal
          key='export-modal'
          close={() => toggleModal()}
          modalClass='confirm-modal export-modal'
          modalClassName='confirm-action'
        >
          <ExportConfirmationModal
            confirmAction={() => {
              confirmAction();
              setShowConfirmModal(false);
              setLoading(true);
            }}
            cancelAction={() => toggleModal()}
            confirmText={strings.dunningComfirm}
            cancelText={strings.dunningCancel}
          >
            <div>
              <p className='title'>{strings.dunningExportTitle}</p>
              <div className='confirm-message'>
                <p>{strings.dunningExportMessage1}</p>
                <p>
                  {strings.formatString(
                    strings.dunningExportMessage2,
                    resultTotals?.totalLoansInDebtCollection,
                    resultTotals?.totalClientsInDebtCollection,
                    currencyFormatDA(
                      resultTotals?.totalDebtCollectionAmount,
                      true,
                      true
                    )
                  )}
                </p>
                <p>
                  {strings.formatString(
                    strings.dunningExportMessage3,
                    resultTotals?.totalLoansInDebtCollection
                  )}
                </p>
              </div>
            </div>
          </ExportConfirmationModal>
        </DentiModal>
      )}
    </>
  );
};

export default PatientsDunning;
