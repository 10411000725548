import React, { useEffect, useState } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import {
  createMonthioApplication,
  storeOnboardingData,
  addErrorLog,
} from '../../actions/onboardingActions';

const HowManyKids = ({ setNextStep, step, previousStep }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const [children, setChildren] = useState(savedData?.children || []);
  const [addChildren, setAddChildren] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [goBackButtonLoading, setGoBackButtonLoading] = useState(false);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingStepSixChooseAgeTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/hjemmeboende-boern',
        stepTitle: 'onboarding - hjemmeboende boern',
        event: 'onboarding',
      });
    }
  }, []);

  const handleCreateMonthioApplication = () => {
    setButtonLoading(true);
    dispatch(createMonthioApplication())
      .then((res) => {
        const monthioApplicationUuid = res?.data;
        dispatch(
          storeOnboardingData({
            ...savedData,
            ...(monthioApplicationUuid && { monthioApplicationUuid }),
          })
        );
        setButtonLoading(false);
        setNextStep(step + 1);
      })
      .catch((error) => {
        setButtonLoading(false);
        console.error(error);
        dispatch(
          addErrorLog({
            status: error?.response?.status,
            message: error?.response?.data,
          })
        );
      });
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (!addChildren && event.key === 'Enter') {
        event.preventDefault();
        setButtonLoading(true);
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [addChildren]);

  useEffect(() => {
    if (children.length > 0) {
      dispatch(storeOnboardingData({ ...savedData, children }));
    }
  }, [children]);

  const handleSubmit = () => {
    handleCreateMonthioApplication();
  };

  const generateArrayOfNumbers = () => Array.from({ length: 18 }, (_, i) => i);

  return (
    <CardBody className='step-content'>
      <div className='step-title'>
        {!addChildren
          ? strings.onboardingStepSixTitle
          : strings.onboardingStepSixChooseAgeTitle}
      </div>
      {!addChildren ? (
        <>
          <div className='step-subtitle mt-4 mb-4'>
            {strings.onboardingStepSixSubtitle}

            <ul className='list-group mt-4' id='carAndKidsList'>
              {children.map((child, index) => (
                <div className='item' key={index}>
                  <div className='itemIcon'>
                    <i className='lnil lnil-user-alt' />
                  </div>
                  <div className='itemName'>
                    {strings.onboardingStepSixBarn}
                  </div>
                  <div className='itemValue'>
                    {strings.formatString(
                      strings.onboardingStepSixBarnAge,
                      child
                    )}
                  </div>
                  <button
                    className='removeItemBtn'
                    id='removeItem0'
                    type='button'
                    onClick={() => {
                      const newChildren = children.filter(
                        (_child, i) => i !== index
                      );
                      setChildren(newChildren);
                    }}
                  >
                    <i className='lnir lnir-circle-minus' />
                    {strings.onboardingStepSixFjern}
                  </button>
                </div>
              ))}
            </ul>

            <div className='mt-5 actions-buttons align-left d-block mb-5'>
              <Button
                className='btn btn-blue btn-cancel'
                disabled={buttonLoading}
                onClick={() => setAddChildren(!addChildren)}
              >
                {strings.onboardingStepSixButton}
              </Button>
            </div>
          </div>

          <div className='actions-buttons step-buttons'>
            <Button
              className='btn btn-blue btn-cancel goBackButton'
              disabled={goBackButtonLoading}
              onClick={() => {
                setGoBackButtonLoading(true);
                previousStep();
              }}
            >
              {goBackButtonLoading ? (
                <ButtonLoader />
              ) : (
                strings.onboardingGoBack
              )}
            </Button>
            <Button
              className='btn btn-blue btn-success'
              onClick={() => handleSubmit()}
              disabled={buttonLoading}
            >
              {buttonLoading ? <ButtonLoader /> : strings.onboardingContinue}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className='d-flex flex-wrap '>
            {generateArrayOfNumbers().map((item, index) => (
              <button
                key={index}
                className='btn-rounded'
                type='button'
                onClick={() => {
                  setChildren([...children, item]);
                  setAddChildren(false);
                }}
              >
                {item}
              </button>
            ))}
          </div>

          <div className='actions-buttons step-buttons'>
            <Button
              className='btn btn-blue btn-cancel'
              onClick={() => setAddChildren(!addChildren)}
            >
              {strings.cancel}
            </Button>
          </div>
        </>
      )}
    </CardBody>
  );
};

export default HowManyKids;
