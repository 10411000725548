import React, { useEffect, useRef, useState } from 'react';
import { Form, Label, Button, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { debounce } from 'lodash';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';
import {
  confirmEmail,
  createMember,
  addErrorLog,
  storeOnboardingData,
} from '../../actions/onboardingActions';

import { strings } from '../../constants/localization';
import ConfirmEmailError from './stepErrors/ConfirmEmailError';
import LoadingSpinner from './common/LoadingSpinner/LoadingSpinner';

const ConfirmEmail = ({ setNextStep, step, previousStep }) => {
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const codeFromUrl =
    savedData?.confirmEmailCode ||
    savedData?.confirmEmailData?.confirmEmailCode;
  const {
    uuid,
    firstName,
    lastName,
    email,
    phoneNumber,
    remainingConfirmEmailAttempts,
    emailIsConfirmed,
    linkTag,
    companyId,
  } = savedData || {};
  const [emailConfirmationCode, setEmailConfirmationCode] = useState([
    '',
    '',
    '',
    '',
  ]);
  const [emailConfirmationCodeError, setEmailConfirmationCodeError] =
    useState('');
  const [codeErrorRedirect, setCodeErrorRedirect] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [countdownButton, setCountdownButton] = useState(39);
  const [showAttemptsErrorMessage, setShowAttemptsErrorMessage] =
    useState(false);
  const [incorrectCode, setIncorrectCode] = useState('');
  const [goBackButtonLoading, setGoBackButtonLoading] = useState(false);

  const inputRefs = useRef([]);

  // on mount foucs on the firts input field
  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingEmailVerificationTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/bekraeft-emailadresse',
        stepTitle: 'onboarding - bekraeft emailadresse',
        event: 'onboarding',
      });
    }
    if (inputRefs.current[0]) {
      inputRefs.current[0].inputElement.focus();
    }

    // eslint-disable-next-line
  }, []);

  // handle the spread of the code received from url
  useEffect(() => {
    let timer;
    if (codeFromUrl) {
      let codeArray = ['', '', '', ''];
      codeArray = [...codeFromUrl];
      setEmailConfirmationCode(codeArray);
      // if there's codeFromUrl saved to the state, then we should confirm the code automatically
      timer = setTimeout(() => {
        if (codeArray.every((code) => code !== '')) {
          setButtonLoading(true);
          confirmCode(codeArray.join(''));
        }
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [codeFromUrl]);

  const handleSubmit = () => {
    setButtonLoading(true);
    confirmCode(emailConfirmationCode.join(''), true);
  };

  const extractNumberOfAttempts = (errorCause) => {
    if (errorCause) {
      const match = errorCause?.match(/(\d+)\//);

      if (match && match[1]) {
        const number = parseInt(match[1], 10);
        return number;
      }
    }
    return 3;
  };

  const confirmCode = (emailCode, confirmedByTyping) => {
    if (
      remainingConfirmEmailAttempts === undefined ||
      remainingConfirmEmailAttempts > 0
    ) {
      // Debouncing ensures that the event handler is called only once within a specified time period
      const debouncedAction = debounce(() => {
        dispatch(confirmEmail(uuid, emailCode))
          .then(() => {
            dispatch(
              storeOnboardingData({
                ...savedData,
                emailIsConfirmed: true,
                remainingConfirmEmailAttempts: 3,
              })
            );
            // save to local storage if the email is confirmed by the user manually and not by the link
            if (confirmedByTyping) {
              localStorage.setItem('emailConfirmedByTyping', true);
              localStorage.setItem('emailConfirmedByLink', false);
            } else {
              localStorage.setItem('emailConfirmedByTyping', false);
              localStorage.setItem('emailConfirmedByLink', true);
            }

            setNextStep(step + 1);
          })
          .catch((err) => {
            setIncorrectCode(emailCode);
            const errorCause = err?.response?.data?.cause;
            if (err?.response?.status === 500) {
              localStorage.setItem('emailConfirmedByTyping', false);
              localStorage.setItem('emailConfirmedByLink', false);
            } else {
              const numberOfAttempts = extractNumberOfAttempts(errorCause);

              if (numberOfAttempts < 3) {
                setShowAttemptsErrorMessage(true);
                // console me the numbers of attempts left to confirm, the max is 3 attempts should be 3 minus the remainingConfirmEmailAttempts
                const remainingAttempts = !isNaN(numberOfAttempts)
                  ? 3 - numberOfAttempts
                  : 0;
                dispatch(
                  storeOnboardingData({
                    ...savedData,
                    remainingConfirmEmailAttempts: remainingAttempts,
                  })
                );
              } else {
                dispatch(
                  storeOnboardingData({
                    ...savedData,
                    remainingConfirmEmailAttempts: 0,
                  })
                );
                setCodeErrorRedirect(true);
              }
            }

            dispatch(addErrorLog('Confirm member with code did not succeed'));
            setButtonLoading(false);
            setEmailConfirmationCode(['', '', '', '']);
            // set Focus to the index 0
            if (inputRefs.current[0]) {
              inputRefs.current[0].inputElement.focus();
            }
          });
      }, 300);
      debouncedAction();
    } else if (emailIsConfirmed) {
      setNextStep(step + 1);
    } else {
      setCodeErrorRedirect(true);
    }
  };

  useEffect(() => {
    let intervalId;
    if (countdownButton > 0) {
      intervalId = setInterval(() => {
        setCountdownButton((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [countdownButton]);

  const handleChange = (event, index) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...emailConfirmationCode];
      newCode[index] = value;
      setEmailConfirmationCode(newCode);
      setEmailConfirmationCodeError('');
      setShowAttemptsErrorMessage('');
      if (value.length > 0 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].inputElement.focus();
      }

      if (newCode.every((code) => code !== '')) {
        setButtonLoading(true);
        confirmCode(newCode.join(''), true);
      }
    } else {
      setEmailConfirmationCodeError(strings.required);
    }
  };

  const handlePaste = (event) => {
    const value = event.clipboardData.getData('text');
    let codeArray = ['', '', '', ''];
    if (value !== '') {
      codeArray = [...value];
      setEmailConfirmationCode(codeArray);
    }
    // timer = setTimeout(() => {
    //   if (codeArray.every((code) => code !== '')) {
    //     setButtonLoading(true);
    //     confirmCode(codeArray.join(''));
    //   }
    // }, 500);
  };

  const handleBackspace = (event, index) => {
    if (event.key === 'Backspace' && event.target.value === '') {
      event.preventDefault();
      // Focus on the previous input field
      if (index > 0) {
        inputRefs.current[index - 1].inputElement.focus();
        // and clear the value from that input field
        const newCode = [...emailConfirmationCode];
        newCode[index - 1] = '';
        setEmailConfirmationCode(newCode);
      }
    }
  };

  const handleResendCode = (event) => {
    event.preventDefault();
    setCountdownButton(39);
    dispatch(
      createMember(firstName, lastName, email, phoneNumber, linkTag, companyId)
    )
      .then((response) => {
        if (response?.payload?.status === 200) {
          const { cpr } = response.payload.data;
          dispatch(
            storeOnboardingData({
              ...savedData,
              cpr: cpr,
            })
          );
          setButtonLoading(false);
        }
      })
      .catch(() => {
        setButtonLoading(false);
      });
  };

  const mask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 1,
    includeThousandsSeparator: true,
  });

  if (buttonLoading) {
    return <LoadingSpinner title={strings.onboardingSpinnerTitle} />;
  } else if (codeErrorRedirect) {
    return (
      <ConfirmEmailError
        showConfirmErrorStep={setCodeErrorRedirect}
        setButtonLoading={setButtonLoading}
        setNextStep={setNextStep}
        setEmailConfirmationCode={setEmailConfirmationCode}
      />
    );
  } else {
    return (
      <CardBody className='step-content confirm-step'>
        <div className='step-title'>
          {strings.onboardingEmailVerificationTitle}
        </div>
        <div className='step-content-subtitle text-left'>
          <p className='mb-2'>
            {strings.formatString(
              strings.onboardingEmailVerificationSubtitle,
              <b>{email || '-'}</b>
            )}
          </p>
          <p className='mb-4'>{strings.onboardingEmailVerificationP}</p>

          <div className='resend-code'>
            {/* <span>{strings.onboardingEmailVerificationResendCode1}</span> */}
            {countdownButton > 0 ? (
              <span>
                {strings.onbordingEmailVerificationCodeText1}

                <button
                  type='button'
                  className='countdownActive'
                  disabled={countdownButton > 0}
                >
                  ({Math.floor(countdownButton / 60)} :{' '}
                  {countdownButton % 60 < 10
                    ? `0${countdownButton % 60}`
                    : countdownButton % 60}
                  )
                </button>
              </span>
            ) : (
              <span>
                {strings.onboardingEmailVerificationCodeText2}
                <button
                  type='button'
                  className='resend-code-link pl-0'
                  onClick={handleResendCode}
                >
                  {strings.formatString(
                    strings.onboardingEmailVerificationSendNewCode,
                    email
                  )}
                </button>
              </span>
            )}
          </div>
        </div>
        <Form className='form-details mt-5'>
          <div className='form-group'>
            <div className='input-details'>
              <div className='label-wrapper'>
                <Label>{strings.onboardingEmailVerificationErrorLabel}</Label>
                {emailConfirmationCodeError && (
                  <span className='error'>{emailConfirmationCodeError}</span>
                )}
              </div>
              <div className='d-flex'>
                {Array.from({ length: 4 }, (_, index) => (
                  <MaskedInput
                    key={index}
                    ref={(input) => {
                      inputRefs.current[index] = input;
                      return inputRefs.current[index];
                    }}
                    mask={mask}
                    value={emailConfirmationCode[index]}
                    type='tel'
                    className={`center mr-2 ${
                      emailConfirmationCodeError ? 'input_danger' : ''
                    }`}
                    id={`emailConfirmationCode${index}`}
                    guide={false}
                    showMask={false}
                    autoFocus={index === 0}
                    onChange={(event) => handleChange(event, index)}
                    onPaste={(event) => handlePaste(event, index)}
                    onKeyDown={(event) => handleBackspace(event, index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </Form>
        <div className='d-flex justify-content-start'>
          <div className='resend-code'>
            <div className='label-wrapper'>
              {showAttemptsErrorMessage &&
                remainingConfirmEmailAttempts < 3 && (
                  <span className='error'>
                    {strings.formatString(
                      strings.onboardingEmailConfirmationErrorMessage,
                      incorrectCode
                    )}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className='actions-buttons step-buttons'>
          <Button
            className='btn btn-blue btn-cancel goBackButton'
            disabled={goBackButtonLoading}
            onClick={() => {
              setGoBackButtonLoading(true);
              previousStep();
            }}
          >
            {goBackButtonLoading ? <ButtonLoader /> : strings.onboardingGoBack}
          </Button>
          <Button
            className='btn btn-blue btn-success'
            onClick={() => handleSubmit()}
            disabled={
              !emailConfirmationCode.every((code) => code !== '') ||
              codeErrorRedirect ||
              buttonLoading
            }
          >
            {buttonLoading ? <ButtonLoader /> : strings.confirm}
          </Button>
        </div>
      </CardBody>
    );
  }
};

export default ConfirmEmail;
