import { debounce } from 'lodash';
import { creditMinDurationMin, creditMaxDuration } from '../../../config';
import { strings } from '../../../constants/localization';
import Slider from 'rc-slider';
import React, { useEffect, useState } from 'react';
import { Button, Label } from 'reactstrap';
import {
  formatCurrencyDaStandard,
  paymentListMomentRestructureLoans,
} from '../../../constants/utils';

import { moment } from '../../../constants';
import { useSelector } from 'react-redux';

type AdjustMonthlyPaymentProps = {
  onNextStep: () => void;
  additionalPayment: string;
  setNewPaymentDetails: (details: PaymentDetail | undefined) => void;
  handlePreviousStep: () => void;
  newPaymentDetails?: PaymentDetail;
};

interface clientDataFromReduxProps {
  lead: {
    clientDataForPartialRedemption: {
      creditFacility: {
        interestRate: number;
        remainingBalance: number;
        maxMonthlyPayment: number;
        numberOfRemainingPayments: number;
      };
    };
  };
}

interface PaymentDetail {
  monthlyPayment: number;
  apr: number;
  firstPaymentDate: string;
  lastPaymentDate: string;
  duration: number;
  extraPayment: number;
  newRemainingBalance: number;
  interestUntilFirstPayment: number;
}

const AdjustMonthlyPayment: React.FC<AdjustMonthlyPaymentProps> = ({
  onNextStep,
  additionalPayment,
  setNewPaymentDetails,
  handlePreviousStep,
  newPaymentDetails,
}) => {
  const clientDataFromRedux = useSelector(
    (state: clientDataFromReduxProps) =>
      state?.lead?.clientDataForPartialRedemption
  );

  const {
    interestRate,
    remainingBalance,
    maxMonthlyPayment,
    numberOfRemainingPayments,
  } = clientDataFromRedux.creditFacility;

  const [duration, setDuration] = useState(creditMinDurationMin + 5);
  const [minDuration, setMinDuration] = useState(creditMinDurationMin);

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetail>();

  useEffect(() => {
    calculateMinDuration();
  }, []);

  const calculateMinDuration = () => {
    let tempDuration =
      numberOfRemainingPayments < creditMinDurationMin
        ? numberOfRemainingPayments
        : creditMinDurationMin;
    let calculatedPayment = null;

    while (tempDuration <= creditMaxDuration) {
      calculatedPayment = paymentListMomentRestructureLoans(
        firstPaymentDate,
        interestRate,
        remainingBalance - parseFloat(additionalPayment),
        tempDuration
      ).monthlyPayment;


      if (calculatedPayment <= maxMonthlyPayment) {
        setMinDuration(tempDuration);
        setDuration(tempDuration + 5);
        handleMonthlyPayment(tempDuration + 5);
        break;
      }
      tempDuration++;
    }
  };

  const firstPaymentDate = moment()
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  const handlePayments = (
    firstPaymentDate: string,
    interestRate: string | number,
    loanAmount: number,
    rangeDuration: number,
    establishmentFee: number
  ) => {
    const paymentResults = paymentListMomentRestructureLoans(
      firstPaymentDate,
      parseFloat(interestRate.toString()),
      loanAmount + establishmentFee,
      rangeDuration
    );

    const numberOfDays = moment(firstPaymentDate).diff(
      moment().startOf('day'),
      'days'
    );

    // Correct interest calculation
    const interestRateDiv100 = Number(interestRate) / 100;
    const interestUntilFirstPayment =
      (interestRateDiv100 * loanAmount * numberOfDays) / 360;

    // Remaining balance after additional payment
    const newRemainingBalance =
      remainingBalance - parseFloat(additionalPayment);

    setPaymentDetails({
      monthlyPayment: paymentResults.monthlyPayment,
      apr: paymentResults.apr,
      firstPaymentDate: firstPaymentDate,
      lastPaymentDate: lastPaymentDate,
      duration: rangeDuration,
      extraPayment: establishmentFee,
      newRemainingBalance,
      interestUntilFirstPayment,
    });
  };

  useEffect(() => {
    if (newPaymentDetails) {
      setDuration(newPaymentDetails?.duration);
    }
  }, [newPaymentDetails]);

  const handleMonthlyPayment = (rangeDuration) => {
    let debouncedFn;

    if (!debouncedFn) {
      debouncedFn = debounce(() => {
        handlePayments(
          firstPaymentDate,
          interestRate,
          remainingBalance - parseFloat(additionalPayment),
          rangeDuration,
          // this value below represents setupFee which is agreed to be set to 0
          0
        );
      }, 10);
    }

    debouncedFn();
  };

  const onSliderChange = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setDuration(newValue);
    handleMonthlyPayment(newValue);
  };

  const lastPaymentDate = moment(firstPaymentDate)
    .add(duration, 'months')
    .format('DD.MM.YYYY');

  return (
    <div className='step-content'>
      <h1 className='step-title'>
        {strings.partialRedemptionAdjustMonthlyPaymentTitle}
      </h1>
      <div className='subtitle'>
        <p>{strings.partialRedemptionAdjustMonthlyPaymentSubtitle}</p>
        <p>
          {strings.formatString(
            strings.partialRedemptionAdjustMonthlyPaymentSubtitle2,
            minDuration
          )}
        </p>
      </div>
      <Label className='input-label center'>
        {strings.partialRedemptionAdjustMonthlyPaymentInputLabel}
      </Label>
      <div className='slider-container'>
        <div className='container-content'>
          <div className='amount-info'>
            {formatCurrencyDaStandard(paymentDetails?.monthlyPayment)}
          </div>
          <div className='slider'>
            <Slider
              // tipFormatter={percentFormatter}
              value={duration}
              step={1}
              min={minDuration}
              max={creditMaxDuration}
              onChange={(value) => onSliderChange(value)}
              dots={false}
              styles={{
                rail: {
                  backgroundColor: '#E2DBD9',
                  height: 10,
                  borderColor: '#ffffff',
                  borderWidth: 2,
                  borderStyle: 'solid',
                },
                handle: {
                  backgroundColor: '#D8BD98',
                  borderColor: '#ffffff',
                  height: 36,
                  width: 36,
                  borderWidth: 3,
                  marginTop: -12,
                  zIndex: 2999,
                  opacity: 1,
                },
                track: {
                  backgroundColor: '#D8BD98',
                  height: 10,
                  borderColor: '#ffffff',
                  borderWidth: 2,
                  borderStyle: 'solid',
                },
              }}
            />
          </div>
          <div className='slider-information'>
            {strings.partialRedemptionAdjustMonthlyPaymentSliderInformation}
          </div>
        </div>
      </div>
      <div className='payment-info'>
        <Label>
          {strings.partialRedemptionAdjustMonthlyPaymentPeriodMonths}
        </Label>
        <span>
          {strings.formatString(
            strings.partialRemptionPaymentDuration,
            duration
          )}
        </span>
      </div>
      <div className='payment-info'>
        <Label>
          {strings.partialRedemptionAdjustMonthlyPaymentLastPaymentDate}
        </Label>
        <span>{lastPaymentDate}</span>
      </div>

      <div className='step-actions'>
        <Button
          color='light'
          className='reject'
          onClick={() => handlePreviousStep()}
        >
          {strings.partialRedemptionButtonBack}
        </Button>
        <Button
          color='blue'
          className='continue'
          onClick={() => {
            setNewPaymentDetails(paymentDetails);
            onNextStep();
          }}
        >
          {strings.partialRedemptionButtonNext}
        </Button>
      </div>
    </div>
  );
};

export default AdjustMonthlyPayment;
