import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers/allReducers';
import initialState from './initialState';

// eslint-disable-next-line
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
    actionsDenylist: [/^suiteux-extensibility\./], // Exclude all actions starting with "suiteux-extensibility."
  }) || compose;
const middleware = [thunk, promise];

const persistConfig = {
  key: 'onboarding',
  storage: storage,
  whitelist: ['onboarding', 'memberPortal'], // only onboarding will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export { store, persistor };
