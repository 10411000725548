import { requestAxios as axios, convertAgesToGroups } from '../constants/utils';
import { store } from '../store';
import {
  SERVER_URL,
  SET_ONBOARDING_DATA,
  SET_ONBOARDING_STEP,
  SET_PREVIOUS_VISITED_STEP,
  TRIGGERED_POP_ALLOWED,
  CREATE_MEMBER,
  GET_NEW_MEMBER,
  GET_EXISTING_CLIENT,
  GET_EXISTING_MEMBER,
  CONFIRM_MEMBER_EMAIL,
  STORE_BUDGET_TRANSACTION_ID,
  ESKAT_V3_ENCRYPT_REQUEST,
  CHECK_ESKAT_CONSENT,
  CHECK_ESKAT_CONSENT_NO_CPR,
  SEND_DISPOSABLE_AMOUNT,
  GET_USER_ID_LOOKUP_CLIENT_BY_CPR,
  LOOK_UP_CLIENT_CREDIT_BY_CPR,
  UPLOAD_DOCUMENTATION_FILE,
  UPLOAD_NO_DOCUMENTS,
  UPDATE_CLIENT_NOTE,
  UPDATE_STORED_CLIENT_NOTE,
  START_ONBOARDING,
  CONVERT_MEMBER_TO_CLIENT,
  STORE_ONBOARDING_VISITED_STEPS,
  ADD_ERROR_LOG,
  LEAD_DOUBLER_CALCULATOR,
  STORE_MISSING_BUDGET_ITEMS,
  STORE_UPLOADED_DOCUMENTATION,
  UPDATE_DOCUMENTATION_ITEM_FROM_WS,
  UPDATE_STORED_DOCUMENTS,
  DELETE_UPLOADED_DOCUMENT,
  SET_UPLOAD_REQUIRED_FILES_FINISHED,
  STORE_IS_GUIDE_VISITED,
  GET_COMPANIES_FOR_COMPANY_CHAIN_SMARTLINK,
  CREDIT_APPLICATION_DENIED_STEP,
  CREDIT_APPLICATION_ABORTED_STEP,
  CLEAR_ONBOADING_DATA,
  CLEAR_STORED_DOCUMENTS,
  CLEAR_NOTIFICATIONS,
  GET_PUBLIC_CREDIT_APPLICATION_DATA,
  SET_ONBOARDING_DATA_BASED_CREDIT_APPLICATION,
  CREATE_MOTNHIIO_APPLICATION,
  RETRIEVE_MOTNHIIO_BUDGET,
  SEND_MANUAL_INTERVENTION_EMAIL,
} from '../constants';
import { onboardingTestData } from '../config';

export const generateUniqueId = () => {
  //  Generating unique ID for skatDataConsentId
  return (
    parseInt(Math.random() * 900 + 100, 10) + new Date().getTime().toString()
  );
};

export function clearOnboardingData() {
  return {
    type: CLEAR_ONBOADING_DATA,
    payload: '',
  };
}

export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS,
    payload: '',
  };
}

export function clearStoredDocuments() {
  return {
    type: CLEAR_STORED_DOCUMENTS,
    payload: '',
  };
}

export function storeOnboardingData(onboardingData) {
  return {
    type: SET_ONBOARDING_DATA,
    payload: onboardingData,
  };
}

export function storeLastStepVisited() {
  const { currentStep } = store.getState().onboarding || {};
  return {
    type: SET_PREVIOUS_VISITED_STEP,
    payload: currentStep,
  };
}

export function storeOnboardingStep(step) {
  store.dispatch(storeLastStepVisited());
  return {
    type: SET_ONBOARDING_STEP,
    payload: step,
  };
}

export function storeTriggeredPopAllowedAction(step) {
  return {
    type: TRIGGERED_POP_ALLOWED,
    payload: step,
  };
}

export function storeOnboardingVisitedSteps(step) {
  return {
    type: STORE_ONBOARDING_VISITED_STEPS,
    payload: step,
  };
}

export function storeIsGuideVisited(visited) {
  return {
    type: STORE_IS_GUIDE_VISITED,
    payload: visited,
  };
}

export function addErrorLog(logMessage) {
  const { currentStep } = store.getState().onboarding || {};
  return {
    type: ADD_ERROR_LOG,
    payload: {
      step: currentStep,
      error: logMessage,
    },
  };
}

export function createMember(
  firstName,
  lastName,
  email,
  phone,
  linkTag,
  companyId
) {
  const { currentStep } = store.getState().onboarding || {};

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/public/v1/member/createMember`,
        data: {
          firstName,
          lastName,
          phone,
          email,
          tag: linkTag,
          companyId,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const status = response.data?.memberStatus;
            switch (status) {
              case 'NEW_MEMBER':
                dispatch({
                  type: GET_NEW_MEMBER,
                  payload: response,
                });
                break;
              case 'EXISTING_CLIENT':
                dispatch({
                  type: GET_EXISTING_CLIENT,
                  payload: response,
                });
                break;
              case 'EXISTING_MEMBER':
                dispatch({
                  type: GET_EXISTING_MEMBER,
                  payload: response,
                });
                break;

              default:
                dispatch({
                  type: CREATE_MEMBER,
                  payload: response,
                });
            }
          }
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: ADD_ERROR_LOG,
            payload: {
              step: currentStep,
              error: {
                status: err?.response?.status,
                message: err?.response?.data,
              },
            },
          });
          reject(err);
        });
    });
}

export function confirmEmail(uuid, emailCode) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/member/confirmEmail`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      uuid,
      emailCode,
    },
  });

  return {
    type: CONFIRM_MEMBER_EMAIL,
    payload: request,
  };
}

export function checkEskatConsent(uuid, cpr) {
  const request = axios({
    method: 'GET',
    url: `${SERVER_URL}/public/v1/eSkat/checkEskatConsent?leadUuid=${uuid}&cpr=${cpr}`,
  });

  return {
    type: CHECK_ESKAT_CONSENT,
    payload: request,
  };
}

export function checkEskatConsentNoCpr(uuid, creditApplicationUuid) {
  const request = axios({
    method: 'GET',
    url: `${SERVER_URL}/api/v2/eSkat/checkEskatConsentNoCpr?leadUuid=${uuid}&creditApplicationUuid=${creditApplicationUuid}&compareCreditApplications=true`,
    withCredentials: true,
  });

  return {
    type: CHECK_ESKAT_CONSENT_NO_CPR,
    payload: request,
  };
}

export function getBisnodeIdLookupClientByCpr(cpr, uuid) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/public/v1/bisnode/lookupClientByCpr/${cpr}/${uuid}`,
  });

  return {
    type: GET_USER_ID_LOOKUP_CLIENT_BY_CPR,
    payload: request,
  };
}

export function lookupClientCreditByCpr(
  bisnodeId,
  creditApplicationUuid,
  leadUuid
) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v2/bisnode/lookupClientCreditByCpr`,
    data: {
      bisnodeId,
      creditApplicationUuid,
      leadUuid,
    },
  });

  return {
    type: LOOK_UP_CLIENT_CREDIT_BY_CPR,
    payload: request,
  };
}

export function startOnboarding(bisnodeId, companyReferenceNumber, tag) {
  const { memberStatus, uuid } = store.getState().onboarding?.data || {};
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/member/startOnboarding`,
    data: {
      bisnodeCprLookupId: bisnodeId || null,
      leadUuid: memberStatus === 'EXISTING_CLIENT' ? uuid : null,
      memberUuid:
        memberStatus === 'EXISTING_MEMBER' || memberStatus === 'NEW_MEMBER'
          ? uuid
          : null,
      companyReferenceNumber: companyReferenceNumber || 'X001',
      tag: tag || 'sundhedplus.dk',
    },
  });

  return {
    type: START_ONBOARDING,
    payload: request,
  };
}

export function storeBudgetTransactionId(budgetTransactionId) {
  return {
    type: STORE_BUDGET_TRANSACTION_ID,
    payload: budgetTransactionId,
  };
}

export function sendRequestedAmount(requestedAmount) {
  const { uuid, creditApplicationUuid, linkTag } =
    store.getState().onboarding?.data || {};
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/creditApplication/setReferenceAndRequestedCreditAmount`,
    data: {
      creditApplicationUuid: creditApplicationUuid,
      reference: linkTag,
      requestedCreditAmount: requestedAmount ?? 0,
      uuid: uuid || null,
    },
  });

  return {
    type: SEND_DISPOSABLE_AMOUNT,
    payload: request,
  };
}

export function uploadDocumentationFile(
  cpr,
  leadUuid,
  file,
  docType,
  fileDescription
) {
  const { testMode } = store.getState().onboarding?.data || {};
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  formData.append('type', docType);
  formData.append('clientNote', fileDescription);
  const request = axios.post(
    `${SERVER_URL}/public/v1/creditApplication/${
      testMode ? onboardingTestData.cpr : cpr
    }/documents/upload?leadUuid=${
      testMode ? onboardingTestData.leadUuid : leadUuid
    }`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // onUploadProgress(progressEvent) {
      //   const { loaded, total } = progressEvent;
      //   const percent = Math.floor((loaded * 100) / total);
      //   if (percent < 100) {
      //     console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
      //   }
      // },
    }
  );

  return {
    type: UPLOAD_DOCUMENTATION_FILE,
    payload: request,
  };
}

export function uploadNoDocuments(cpr, type, clientNote, leadUuid) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/creditApplication/${cpr}/noDocuments/upload?type=${type}&clientNote=${clientNote}&leadUuid=${leadUuid}`,
  });

  return {
    type: UPLOAD_NO_DOCUMENTS,
    payload: request,
  };
}

export function updateUploadedNote(creditApplicationId, docUuuid, newNote) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/creditApplicationDocument/updateNote`,
    data: {
      creditApplicationUuid: creditApplicationId,
      documentationUuid: docUuuid,
      note: newNote,
    },
  });

  return {
    type: UPDATE_CLIENT_NOTE,
    payload: request,
  };
}

export function deleteUploadedDocument(creditApplicationUuid, docUuuid) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/creditApplicationDocument/delete?documentationUuid=${docUuuid}&creditApplicationUuid=${creditApplicationUuid}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return {
    type: DELETE_UPLOADED_DOCUMENT,
    payload: request,
  };
}

export function updateStoredDocuments(
  creditApplicationUuid,
  documentationUuid
) {
  return {
    type: UPDATE_STORED_DOCUMENTS,
    payload: {
      creditApplicationUuid,
      documentationUuid,
    },
  };
}

export function storeUploadedDocumentation(
  creditApplicationUuid,
  documentationType,
  documentationId,
  documentationUuid,
  fileName,
  publicLink,
  clientNote,
  supportNote = '',
  status,
  _final
) {
  return {
    type: STORE_UPLOADED_DOCUMENTATION,
    payload: {
      creditApplicationUuid,
      documentationType,
      documentationId,
      documentationUuid,
      fileName,
      publicLink,
      clientNote,
      supportNote,
      status,
      _final,
    },
  };
}

export function updateDocumentationFromWs(wsObject) {
  return {
    type: UPDATE_DOCUMENTATION_ITEM_FROM_WS,
    payload: wsObject,
  };
}

export function updateStoredClientNote(
  creditApplicationUuid,
  documentationType,
  newNote
) {
  return {
    type: UPDATE_STORED_CLIENT_NOTE,
    payload: {
      creditApplicationUuid,
      documentationType,
      clientNote: newNote,
    },
  };
}

export function convertMemberToClient(
  bisnodeId,
  memberUuid,
  signupCompanyReference,
  tag,
  creditApplicationUuid
) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/member/convertMemberToClient`,
    data: {
      bisnodeCprLookupId: bisnodeId || null,
      memberUuid: memberUuid || null,
      companyReferenceNumber: signupCompanyReference || 'X001',
      tag: tag || 'sundhedplus.dk',
      creditApplicationUuid: creditApplicationUuid || null,
    },
  });
  return {
    type: CONVERT_MEMBER_TO_CLIENT,
    payload: request,
  };
}

export function storeMissingBudgetItems(missingBudgetItems) {
  return {
    type: STORE_MISSING_BUDGET_ITEMS,
    payload: missingBudgetItems,
  };
}

export function leadDoublerCalculator(cpr, leadUuid) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/creditApplication/leadDoublerCalculator?cpr=${cpr}&leadUuid=${leadUuid}`,
  });

  return {
    type: LEAD_DOUBLER_CALCULATOR,
    payload: request,
  };
}

export function getCompaniesForCompanyChain(companyChainId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: `${SERVER_URL}/public/companyChain/${companyChainId}`,
      })
        .then((response) => {
          dispatch({
            type: GET_COMPANIES_FOR_COMPANY_CHAIN_SMARTLINK,
            payload: response?.data,
          });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export function creditApplicationDenied(creditApplicationUuid, uuid, reason) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/public/v2/creditApplication/denied`,
        data: {
          creditApplicationUuid,
          uuid,
          reason,
        },
      })
        .then((response) => {
          dispatch({
            type: CREDIT_APPLICATION_DENIED_STEP,
            payload: true,
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: CREDIT_APPLICATION_DENIED_STEP,
            payload: false,
          });
          reject(err);
        });
    });
  };
}

export function creditApplicationAborted(creditApplicationUuid, uuid, reason) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/public/v2/creditApplication/aborted`,
        data: {
          creditApplicationUuid,
          uuid,
          reason,
        },
      })
        .then((response) => {
          dispatch({
            type: CREDIT_APPLICATION_ABORTED_STEP,
            payload: true,
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: CREDIT_APPLICATION_ABORTED_STEP,
            payload: false,
          });
          reject(err);
        });
    });
  };
}

export function getPublicCreditApplicationData(creditApplicationUuid, email) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v1/creditApplication/getCreditApplicationData`,
    data: {
      caUuid: creditApplicationUuid,
      email,
    },
  });

  return {
    type: GET_PUBLIC_CREDIT_APPLICATION_DATA,
    payload: request,
  };
}

export function getPublicCreditApplicationDataForMonthio(
  creditApplicationUuid
) {
  const request = axios({
    method: 'get',
    url: `${SERVER_URL}/public/v1/getCreditApplicationDataForMonthio/${creditApplicationUuid}`,
  });

  return {
    type: GET_PUBLIC_CREDIT_APPLICATION_DATA,
    payload: request,
  };
}

export function populateOnboardinDataBasedCreditApplication(
  creditApplicationData
) {
  return {
    type: SET_ONBOARDING_DATA_BASED_CREDIT_APPLICATION,
    payload: creditApplicationData,
  };
}

export function getDocumentationDataAndMapToStore(
  creditApplicationUuid,
  documentationData
) {
  // first clear the store of uploaded documentation
  store.dispatch(clearStoredDocuments());
  // check if is set
  if (JSON.stringify(documentationData) !== '{}') {
    Object.entries(documentationData).forEach((entry) => {
      const [key, value] = entry;
      if (value.length > 0) {
        value.forEach((document) => {
          const {
            documentationId,
            documentationUuid,
            fileName,
            publicLink,
            clientNote,
            supportNote,
            status,
            _final,
          } = document;
          if (status !== 'CANCELLED') {
            store.dispatch(
              storeUploadedDocumentation(
                creditApplicationUuid,
                key,
                documentationId,
                documentationUuid,
                fileName,
                publicLink,
                clientNote || '',
                supportNote || '',
                status,
                _final
              )
            );
          }
        });
      }
    });
  }
}

export function uploadedRequiredFilesFinished(finished = false) {
  return {
    type: SET_UPLOAD_REQUIRED_FILES_FINISHED,
    payload: finished,
  };
}

// Onboarding V2
export function eSkatV3GetConsentRequest() {
  const uniqueId = generateUniqueId();
  const { memberStatus, uuid, creditApplicationUuid } =
    store.getState().onboarding?.data || {};
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/public/v3/eSkat/encrypt`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      leadUuid: memberStatus === 'EXISTING_CLIENT' ? uuid : null,
      memberUuid:
        memberStatus === 'EXISTING_MEMBER' || memberStatus === 'NEW_MEMBER'
          ? uuid
          : null,
      redirectUrl: `${window.location.origin}/onboarding?step=10&caUuid=${creditApplicationUuid}`,
      skatDataConsentId: uniqueId,
      transactionId: uniqueId,
    },
  });

  return {
    type: ESKAT_V3_ENCRYPT_REQUEST,
    payload: request,
  };
}

export function createMonthioApplication() {
  const { housingStatus, civilStatus, children, uuid, creditApplicationUuid } =
    store.getState().onboarding?.data || {};
  const { currentStep } = store.getState().onboarding || {};

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: `${SERVER_URL}/public/api/v1/monthio/createHeadlessApplication`,
        data: {
          childrenAges: convertAgesToGroups(children),
          civilStatus: civilStatus,
          creditApplicationUuid: creditApplicationUuid || null,
          housingStatus: housingStatus,
          leadUuid: uuid || null,
        },
      })
        .then((response) => {
          dispatch({
            type: CREATE_MOTNHIIO_APPLICATION,
            payload: response?.data,
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: ADD_ERROR_LOG,
            payload: {
              step: currentStep,
              error:
                {
                  status: err?.response?.status,
                  message: err?.response?.data,
                } || err,
            },
          });
          reject(err);
        });
    });
  };
}

export function retrieveMonthioBudget(creditApplicationUuid) {
  const { currentStep } = store.getState().onboarding || {};

  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${SERVER_URL}/public/v2/budget/${creditApplicationUuid}`,
      });

      dispatch({
        type: RETRIEVE_MOTNHIIO_BUDGET,
        payload: response?.data,
      });

      return response; // Resolve with the response object
    } catch (err) {
      dispatch({
        type: ADD_ERROR_LOG,
        payload: {
          step: currentStep,
          error:
            {
              status: err?.response?.status,
              message: err?.response?.data,
            } || err,
        },
      });

      throw err; // Reject with the error object
    }
  };
}

export function sendManualInterventionEmail(uuid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: `${SERVER_URL}/public/v1/creditApplication/sendToManualIntervention/${uuid}`,
      })
        .then((response) => {
          dispatch({
            type: SEND_MANUAL_INTERVENTION_EMAIL,
            payload: true,
          });
          resolve(response);
        })
        .catch((err) => {
          dispatch({
            type: SEND_MANUAL_INTERVENTION_EMAIL,
            payload: false,
          });
          reject(err);
        });
    });
  };
}
