import { success, error as notifError } from 'react-notification-system-redux';
import {
  requestAxios as axios,
  addNotification,
  saveCachedData,
  getCachedSearch,
} from '../constants/utils';
import { strings } from '../constants/localization';
import { getLoggedInUserCredentials } from './userActions';
import {
  SERVER_URL,
  GET_COMPANY_CHAINS,
  SET_CURRENT_CHAIN,
  SET_CHAIN_ACTIVE_PAGE,
  SET_CHAIN_LOADING,
  CHAIN_LOCATIONS_LOADING,
  CHAIN_USERS_LOADING,
  GET_USERS_ASSOCIATED,
  COMPANY_CHAINS_LOADING,
  EDIT_COMPANY_CHAINS_LOADING,
  GET_ALL_PUBLIC_COMPANY_CATEGORIES,
  COMPANY_CHAINS_SUCCESS,
  COMPANY_CHAINS_EMPTY,
  COMPANY_CHAINS_FAILURE,
  COMPANY_CHAIN_SEARCH_EMITTED,
  GET_MARKETING_FEE_DENTI,
  GET_MARKETING_FEE_SUNDHED,
  COMPANY_CHAIN_UPDATE_FILTER,
  GET_CHAIN_LOCATIONS,
  ADD_NEW_COMPANY_CHAIN,
  DELETE_COMPANY_CHAIN_WITH_COMPANIES,
  UPDATE_COMPANY_CHAIN_INFO,
  ADD_NEW_COMPANY_LOCATION,
  UPDATE_COMPANY_CHAIN_STATUS,
  UPDATE_COMPANY_CHAIN_STATUS_FROM_COMPANY,
  UPDATE_COMPANY_STATUS_IN_ACTIVE_CHAIN,
  UPDATE_CHAIN_INTERNAL_NOTE,
  UPDATE_ACTIVE_CHAIN_IN_LIST,
  notificationDismissDuration,
} from '../constants';

export function getCompanyChains(
  page = 0,

  search = null,

  id = null,

  callback = undefined,
  filter = null,
  signal = null
) {
  const paramsRequest = {};
  const cachedSearchParams = getCachedSearch('companyChainSearch');

  if (search !== null && search !== '' && search.length < 12) {
    paramsRequest.searchInput = search
      .trim()
      .replace(/\s/g, '')
      .split('-')
      .join('');
  } else {
    paramsRequest.searchInput = search;
  }
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(paramsRequest.searchInput)) {
    paramsRequest.searchInput = search;
  }

  // pagination
  if (page > 0) {
    paramsRequest.page = page;
  } else {
    paramsRequest.page = 0;
  }

  // add filter params
  if (filter !== null && Object.keys(filter).length > 0) {
    Object.assign(paramsRequest, { ...filter });
  }

  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { globalLoading } = getState().companyChain;
      dispatch({
        type: COMPANY_CHAINS_LOADING,
        payload: COMPANY_CHAINS_LOADING,
      });

      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/companyChain/`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: cachedSearchParams ?? paramsRequest,
        ...(signal && { signal }), // add the signal to the request if it exists, used to abort the request
      })
        .then((response) => {
          const companiesChain = response.data;

          saveCachedData(
            'companyChainSearch',
            cachedSearchParams ?? paramsRequest
          );
          dispatch({
            type: GET_COMPANY_CHAINS,
            payload: companiesChain,
          });
          if (id && id !== 'REMOVED') {
            let preselected = null;
            companiesChain.content.forEach((item) => {
              if (item.id === id) {
                preselected = item;
              }
            });
            dispatch({
              type: SET_CURRENT_CHAIN,
              payload: preselected,
            });
          } else if (id === 'REMOVED') {
            dispatch({
              type: SET_CURRENT_CHAIN,
              payload: null,
            });
          } else if (companiesChain.content.length === 1) {
            const singleChainId = companiesChain.content[0].id;
            dispatch(getChainLocations(singleChainId));
            dispatch(setCurrentChain(companiesChain.content[0]));
          }

          if (companiesChain.content.length > 0) {
            dispatch({
              type: COMPANY_CHAINS_SUCCESS,
              payload: COMPANY_CHAINS_SUCCESS,
            });
          } else {
            dispatch({
              type: COMPANY_CHAINS_EMPTY,
              payload: COMPANY_CHAINS_EMPTY,
            });
          }
          if (globalLoading) {
            // dispatch global loading
            dispatch({
              type: EDIT_COMPANY_CHAINS_LOADING,
              payload: !globalLoading,
            });
          }
          if (callback) {
            callback();
          }
          resolve(response);
        })
        .catch((e) => {
          dispatch({
            type: COMPANY_CHAINS_FAILURE,
            payload: COMPANY_CHAINS_FAILURE,
          });
          if (globalLoading) {
            // dispatch global loading
            dispatch({
              type: EDIT_COMPANY_CHAINS_LOADING,
              payload: !globalLoading,
            });
          }
          reject(e);
        });
    });
}

export function filterEmitted(filter, deleteFilter = false) {
  let newFilter = {};
  if (!deleteFilter) {
    newFilter = filter;
  }

  return {
    type: COMPANY_CHAIN_UPDATE_FILTER,
    payload: newFilter,
  };
}

export function addNewCompanyLocation(data) {
  const response = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/companyChain/createCompanyForCompanyChain`,
    data,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: ADD_NEW_COMPANY_LOCATION,
    payload: response,
  };
}

export function updateCompanyChainStatus(id, companyStatus) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/companyChain/changeCompanyChainStatus`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: {
          id,
          companyStatus,
        },
      })
        .then((response) => {
          const { activeChain } = getState().companyChain;
          let newObj = activeChain;
          newObj.status = companyStatus;
          dispatch({
            type: UPDATE_COMPANY_CHAIN_STATUS,
            payload: newObj,
          });

          dispatch(updateChainItemInList(Object.assign({}, activeChain)));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function updateCompanyChainStatusFromCompany(id, companyStatus) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/companyChain/changeCompanyChainStatus`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: {
          id,
          companyStatus,
        },
      })
        .then((response) => {
          dispatch({
            type: UPDATE_COMPANY_CHAIN_STATUS_FROM_COMPANY,
            payload: response,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function updateCompanyStatusInActiveChain(id, status) {
  return {
    type: UPDATE_COMPANY_STATUS_IN_ACTIVE_CHAIN,
    payload: {
      id,
      status,
    },
  };
}

export function searchEmitted(search = false) {
  return {
    type: COMPANY_CHAIN_SEARCH_EMITTED,
    payload: { search },
  };
}

export function setCurrentChain(chain) {
  saveCachedData('lastSelectedCompanyChain', chain);

  return {
    type: SET_CURRENT_CHAIN,
    payload: chain,
  };
}
export function setActivePage(activePage) {
  return {
    type: SET_CHAIN_ACTIVE_PAGE,
    payload: activePage,
  };
}

export function setChainLoading(loadingStatus) {
  return {
    type: SET_CHAIN_LOADING,
    payload: loadingStatus,
  };
}

export function createNewCompanyChain(data) {
  const response = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/companyChain/create`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data,
  });
  return {
    type: ADD_NEW_COMPANY_CHAIN,
    payload: response,
  };
}

export function getPublicCompanyCategories() {
  const response = axios({
    method: 'get',
    url: `${SERVER_URL}/api/v1/company/getAllPublicCompanyCategories/`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: GET_ALL_PUBLIC_COMPANY_CATEGORIES,
    payload: response,
  };
}

export function getMarketingFeeByBrand(brand) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/getFeeByBrand/${brand}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      });
      if (brand === 'Denti') {
        dispatch({
          type: GET_MARKETING_FEE_DENTI,
          payload: response.data,
        });
      }
      if (brand === 'Sundhed+') {
        dispatch({
          type: GET_MARKETING_FEE_SUNDHED,
          payload: response.data,
        });
      }
    } catch (e) {
      if (brand === 'Denti') {
        dispatch({
          type: GET_MARKETING_FEE_DENTI,
          payload: {},
        });
      }
      if (brand === 'Sundhed+') {
        dispatch({
          type: GET_MARKETING_FEE_SUNDHED,
          payload: {},
        });
      }

      throw new Error(e);
    }
  };
}

export function getChainLocations(chainId) {
  return async (dispatch) => {
    dispatch({
      type: CHAIN_LOCATIONS_LOADING,
      payload: true,
    });
    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/companyChain/${chainId}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      });
      dispatch({
        type: GET_CHAIN_LOCATIONS,
        payload: response.data?.companyChains[0]?.companies,
      });
    } catch (e) {
      dispatch({
        type: GET_CHAIN_LOCATIONS,
        payload: [],
      });

      throw new Error(e);
    } finally {
      dispatch({
        type: CHAIN_LOCATIONS_LOADING,
        payload: false,
      });
    }
  };
}

export function updateCompanyChainInternalNote(companyChainId, internalNote) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/companyChain/changeChainCompanyInternalNote`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: {
          companyChainId,
          internalNote,
        },
      })
        .then((response) => {
          const { activeChain } = getState().companyChain;
          let newObj = activeChain;
          newObj.internal_note = internalNote;
          dispatch({
            type: UPDATE_CHAIN_INTERNAL_NOTE,
            payload: newObj,
          });

          dispatch(
            success(
              addNotification(
                strings.companyChainInternalNoteSuccessMessage,
                strings.editCompanyChainSuccessTitle,
                notificationDismissDuration
              )
            )
          );

          dispatch(updateChainItemInList(Object.assign({}, activeChain)));
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            notifError(
              addNotification(
                strings.companyChainInternalNoteErrorMessage,
                strings.errorEditCompanyChainTitle,
                notificationDismissDuration
              )
            )
          );
          reject(error);
        });
    });
}

export function deleteCompanyChainAndAssociatedCompanies(data) {
  const request = axios({
    method: 'post',
    url: `${SERVER_URL}/api/v1/companyChain/deleteCompanyChainAndAssociatedCompanies`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data,
  });

  return {
    type: DELETE_COMPANY_CHAIN_WITH_COMPANIES,
    payload: request,
  };
}

export function getChainAssociatedUsers(chainId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: CHAIN_USERS_LOADING,
        payload: true,
      });
      axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/companyChain/${chainId}/users`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
      })
        .then((response) => {
          dispatch({
            type: GET_USERS_ASSOCIATED,
            payload: response?.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          dispatch({
            type: CHAIN_USERS_LOADING,
            payload: false,
          });
        });
    });
}

export function updateCompanyChainInfo(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${SERVER_URL}/api/v1/companyChain/updateCompanyChainInfo`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data,
      })
        .then((response) => {
          dispatch({
            type: UPDATE_COMPANY_CHAIN_INFO,
            payload: data,
          });

          dispatch(
            updateChainItemInList(
              Object.assign({}, getState().companyChain?.activeChain)
            )
          );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function updateChainItemInList(newChainInfo) {
  return {
    type: UPDATE_ACTIVE_CHAIN_IN_LIST,
    payload: newChainInfo,
  };
}
