import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Label } from 'reactstrap';
import { ReactComponent as BillsIcon } from '../../../resources/icons/lni_lni-bills-1.svg';
import { ReactComponent as UserMoney } from '../../../resources/icons/user-money.svg';
import { ReactComponent as MobileCoin } from '../../../resources/icons/lni_lni-mobile-coin.svg';
import { ReactComponent as UserIcon } from '../../../resources/icons/user-alt-5.svg';
import { ReactComponent as CarIcon } from '../../../resources/icons/car-alt.svg';
import { ReactComponent as Condominium } from '../../../resources/icons/lni_lni-condominium.svg';
import './style.scss';
import { strings } from '../../../constants/localization';
import { formatCurrencyDaStandard } from '../../../constants/utils';
import { setCurrentApplicationItem } from '../../../actions/onboardingApplicationsActions';
import { applicationPayslipsMap } from '../../../constants/mappings';

import ApplicationMasterData from './ApplicationMasterData';
import ApplicantDetails from './ApplicantDetails';
import ApplicantWarnings from './ApplicantWarnings';
import BudgetDisplay from './BudgetDisplay';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import ApplicationDataCard from './ApplicationDataCard';
import ApplicationDataCardRow from './ApplicationDataCardRow';
import PayslipsModal from './modals/PayslipsModal';

const ApplicationItem = ({ toggleMenu }) => {
  const dispatch = useDispatch();
  const applicationLoading = useSelector(
    (state) => state.onboardingApplication?.applicationLoading
  );
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const { creditApplication, payslips } = activeApplicationListItem || {};
  const {
    creditApplicationType,
    accomodationType,
    shareExpensesPercentage,
    debtSinceLastTaxReport,
    debt,
    debtRatio,
    sixMonthsAverageSalary,
    lastSalaryType,
    maxCredit,
    requestedCreditAmount,
    interestRate,
    children,
    cars,
    properties,
  } = creditApplication || {};
  const [showPayslispsModal, setShowPayslipsModal] = useState(false);

  const renderIcomeAndDebt = () => {
    return (
      <ApplicationDataCard
        icon={<UserMoney />}
        headline={strings.applicationIncomeHeadline}
      >
        <ApplicationDataCardRow
          type='income'
          value={
            <>
              {lastSalaryType ? (
                <div className='salary-group'>
                  <Label>{strings.applicationIncomePay}</Label>
                  <div className='counter has-tooltip top'>
                    <span className='tooltip'>
                      {
                        strings[
                          applicationPayslipsMap[lastSalaryType]
                            ?.payslipsSalaryTypeText
                        ]
                      }
                    </span>

                    <span>{lastSalaryType}</span>
                  </div>
                </div>
              ) : (
                <Label>{strings.applicationIncomePay}</Label>
              )}
              <span>
                {sixMonthsAverageSalary ? (
                  payslips && payslips?.length > 0 ? (
                    <u
                      onClick={() => setShowPayslipsModal(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      {formatCurrencyDaStandard(sixMonthsAverageSalary)}
                    </u>
                  ) : (
                    formatCurrencyDaStandard(sixMonthsAverageSalary)
                  )
                ) : (
                  '-'
                )}
              </span>
            </>
          }
        />
        <ApplicationDataCardRow
          label={strings.applicationIncomeDebt}
          value={debt ? formatCurrencyDaStandard(debt) : '-'}
        />
        <ApplicationDataCardRow
          label={strings.applicationIncomeDebtRation}
          value={debtRatio ? debtRatio.toString().replace('.', ',') : '-'}
        />
      </ApplicationDataCard>
    );
  };

  const renderMiscellaneousCard = () => {
    const shareExpensesPercentageString = shareExpensesPercentage
      ? `${(shareExpensesPercentage * 100).toFixed()} %`
      : '-';

    const getAccomodationString = () => {
      switch (accomodationType) {
        case 'Rent':
          return strings.onboardingAcommodationRent;
        case 'Living at home':
          return strings.onboardingAcommodationLivingAtHome;
        case 'Owner occupied housing':
          return strings.onboardingAcommodationOwnerOccupied;
        case 'Housing cooperative':
          return strings.onboardingAcommodationHousingCooperative;
        default:
          return '-';
      }
    };
    const accomodationTypeString =
      creditApplicationType === null || creditApplicationType === 'MONTHIO'
        ? getAccomodationString() || '-'
        : strings[`onboardingStepFiveOption${accomodationType}`] || '-';

    return (
      <ApplicationDataCard
        icon={<BillsIcon />}
        headline={strings.applicationCreditMiscellaneous}
      >
        <ApplicationDataCardRow
          label={strings.applicationCreditHouseType}
          value={accomodationTypeString}
        />
        <ApplicationDataCardRow
          label={strings.applicationCreditCostSharing}
          value={shareExpensesPercentageString}
        />
        <ApplicationDataCardRow
          label={strings.applicationCreditDebtMonth}
          value={
            debtSinceLastTaxReport
              ? formatCurrencyDaStandard(debtSinceLastTaxReport)
              : '-'
          }
        />
      </ApplicationDataCard>
    );
  };

  const renderCreditCard = () => {
    const interestRateString = interestRate
      ? `${interestRate.toString().replace('.', ',')} %`
      : '-';
    return (
      <ApplicationDataCard
        icon={<MobileCoin />}
        headline={strings.applicationCreditHeadline}
      >
        <ApplicationDataCardRow
          label={strings.applicationCreditMaxCredit}
          value={maxCredit ? formatCurrencyDaStandard(maxCredit) : '-'}
        />
        <ApplicationDataCardRow
          label={strings.applicationCreditRequestedAmount}
          value={
            requestedCreditAmount
              ? formatCurrencyDaStandard(requestedCreditAmount)
              : '-'
          }
        />
        <ApplicationDataCardRow
          label={strings.applicationCreditInterestRate}
          value={interestRateString}
        />
      </ApplicationDataCard>
    );
  };

  const renderChildrenCard = () => {
    // children section, check if Monthio to show in a different way
    let childrenSectionExpandableProps = {};
    let stringOfAges = '-';
    if (creditApplicationType !== 'MONTHIO') {
      const childrenArray = children?.map((element) => {
        return `${element} ${strings.applicationChildrenYears}`;
      });

      if (childrenArray) {
        stringOfAges = childrenArray.join(', ');
      }
      childrenSectionExpandableProps.value = stringOfAges;
    } else {
      childrenSectionExpandableProps.childrenArray = children;
    }

    return (
      <ApplicationDataCard
        icon={<UserIcon />}
        headline={strings.applicationChildrenHeadline}
      >
        <ApplicationDataCardRow
          type='children'
          label={strings.applicationChildrenSubheadline}
          counter={children?.length || 0}
          {...childrenSectionExpandableProps}
        />
      </ApplicationDataCard>
    );
  };

  const renderCarsCard = () => {
    return (
      <ApplicationDataCard
        icon={<CarIcon />}
        headline={strings.applicationCarHeadline}
      >
        <ApplicationDataCardRow
          type='cars'
          label={strings.applicationCarSubheadline}
          counter={cars?.length || 0}
          carsArray={cars}
        />
      </ApplicationDataCard>
    );
  };

  const renderPropertiesCard = () => {
    return (
      <ApplicationDataCard
        icon={<Condominium />}
        headline={strings.applicationProperties}
      >
        <ApplicationDataCardRow
          type='properties'
          label={strings.applicationPropertiesAddress}
          counter={properties?.length || 0}
          properties={properties}
        />
      </ApplicationDataCard>
    );
  };

  return (
    <div
      className={`application-data-item ${
        creditApplicationType === 'MONTHIO' || creditApplicationType === null
          ? 'monthio'
          : 'noitso'
      } main-content`}
    >
      {applicationLoading && <LoadingMessage />}

      <div className='navigation-buttons'>
        <button
          type='button'
          className='btn btn-white go-back-to-list'
          onClick={() => dispatch(setCurrentApplicationItem(undefined))}
        >
          <i className='lnil lnil-chevron-left'></i>
          <span>{strings.applicationsGoToList}</span>
        </button>

        <button
          type='button'
          className='btn btn-white menu-toggler'
          onClick={toggleMenu}
        >
          <div className='menu-toggler-icon'>
            <span />
            <span />
            <span />
          </div>
        </button>
      </div>
      {/* FIRST ROW */}
      <Row>
        <Col xs='12' lg='12'>
          <ApplicationMasterData />
        </Col>
      </Row>

      {/* SECOND ROW */}
      <Row>
        <Col xs='12' lg='12'>
          <ApplicantDetails />
        </Col>
      </Row>

      {/* THIRD ROW */}
      <Row>
        <Col xs='12' lg='12'>
          <ApplicantWarnings />
        </Col>
      </Row>

      {/* FOURTH ROW */}
      <Row>
        <Col xs='12' lg='4'>
          {renderIcomeAndDebt()}
        </Col>
        <Col xs='12' lg='4'>
          {renderMiscellaneousCard()}
        </Col>
        <Col xs='12' lg='4'>
          {renderCreditCard()}
        </Col>
      </Row>

      {/* FIFTH ROW */}
      <Row>
        <Col xs='12' lg='4'>
          {renderChildrenCard()}
        </Col>
        <Col xs='12' lg='4'>
          {renderCarsCard()}
        </Col>
        <Col xs='12' lg='4'>
          {renderPropertiesCard()}
        </Col>
      </Row>

      {/* SIXTH ROW */}
      {/* render the budget only if not monthio application */}
      {creditApplicationType !== 'MONTHIO' && (
        <Row>
          <Col xs='12' lg='12'>
            <BudgetDisplay />
          </Col>
        </Row>
      )}

      {showPayslispsModal && (
        <PayslipsModal
          payslips={payslips}
          sixMonthsAverageSalary={sixMonthsAverageSalary}
          closeModal={() => setShowPayslipsModal(false)}
        />
      )}
    </div>
  );
};

export default ApplicationItem;
