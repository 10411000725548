import React from 'react';
import { Col, Row } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ChainList from './ChainList/ChainList';
import ChainItem from './ChainItem/ChainItem';
import { setCurrentChain } from '../../actions/companyChainActions';
import PropTypes from 'prop-types';
import WelcomePage from '../common/WelcomePage';

const CompanyChains = (props) => {
  const chainList =
    useSelector((state) => state.companyChain?.companyChainList?.content) || [];

  const activeChain = useSelector((state) => state.companyChain.activeChain);
  const globalLoading = useSelector(
    (state) => state.companyChain.globalLoading
  );
  const dispatch = useDispatch();

  const handleSetCurrentChain = (chain) => {
    dispatch(setCurrentChain(chain));
  };

  const renderDetails = () => {
    if (chainList && chainList.length > 0) {
      return activeChain ? (
        <ChainItem
          activeChain={activeChain}
          setCurrentChain={handleSetCurrentChain}
          toggleMenu={props.toggleMenu}
        />
      ) : (
        <WelcomePage
          toggleModal={props.toggleModal}
          modalShow={props.modalShow}
          toggleCreateNewInvoice={props.toggleCreateNewInvoice}
          createNewInvoice={props.createNewInvoice}
        />
      );
    }
    return (
      <WelcomePage
        toggleModal={props.toggleModal}
        modalShow={props.modalShow}
        toggleCreateNewInvoice={props.toggleCreateNewInvoice}
        createNewInvoice={props.createNewInvoice}
      />
    );
  };

  return (
    <>
      {globalLoading && <LoadingMessage />}
      <Row className='app-row-height'>
        <Col
          xs='12'
          md='12'
          xl='4'
          className='entities-list-section p-0 app-col-height'
        >
          <ChainList
            chains={chainList}
            toggleMenu={props.toggleMenu}
            displayChain={props.location.state}
            activeChain={activeChain}
            location={props.location}
            history={props.history}
          />
        </Col>
        <Col
          xs='12'
          md='12'
          xl='8'
          className={`entity-details-section h-100 ${
            activeChain ? 'active' : ''
          }`}
        >
          {renderDetails()}
        </Col>
      </Row>
    </>
  );
};

CompanyChains.propTypes = {
  toggleMenu: PropTypes.func.isRequired, // Assuming toggleMenu is required
};

export default CompanyChains;
