import { useEffect } from 'react';

import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isDentist, isManager } from '../../constants/utils';
import { strings } from '../../constants/localization';
import { ROUTE_HOME, ROUTE_SETTINGS } from '../../constants/routes';
import { userLogout } from '../../actions/userActions';
import { useHistory } from 'react-router';
import { config } from '../../config';
import {
  clearMemberPortalData,
  isClientLogout,
} from '../../actions/memberPortalActions';

function UserInformation({ toggleUserInformation, isDashboard }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentDomain = window.location.hostname;
  const isLocalhost = currentDomain === 'localhost';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.userInfo-wrapper')) {
        toggleUserInformation();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleUserInformation]);

  const logoutUser = (event) => {
    if (isDashboard) {
      dispatch(isClientLogout(true));
      localStorage.removeItem('loginTimeMit');
      if (isLocalhost) {
        history.push(ROUTE_HOME);
        dispatch(clearMemberPortalData());
      } else {
        dispatch(clearMemberPortalData());
        setTimeout(() => {
          window.location.href = config.damAppMainPageUrl;
        }, 200);
      }
    } else {
      event.preventDefault();
      sessionStorage.removeItem('lastVisitedPath');
      dispatch(userLogout());
    }
  };

  return (
    <div className={`menu ${isDashboard ? 'menuDashboard' : ''}`}>
      <div className='list-group'>
        {!isDashboard ? (
          <>
            <div
              className={`list-group-item ${
                isDashboard ? 'list-group-dashboard' : ''
              }`}
            >
              <div className='user-info'>
                {user && (
                  <>
                    <div className='name_icon'>{`${user.companyName[0]}`}</div>
                    <div className='clinicInfo'>
                      <div className='companyname'>{`${user.companyName}`}</div>
                      <div className='username'>{`${user.username}`}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr />
            <div className='list-group-item'>
              {user && (isManager(user) || isDentist(user)) && (
                <button
                  type='button'
                  className='common-btn '
                  onClick={() => {
                    history.push(ROUTE_SETTINGS);
                    toggleUserInformation();
                  }}
                >
                  <i className='lnil lnil-cog' />
                  {strings.settings}
                </button>
              )}
            </div>
            <div className='list-group-item'>
              <button
                type='button'
                className='common-btn'
                onClick={(event) => logoutUser(event)}
              >
                <i className='lnil lnil-exit' />
                {strings.logout}
              </button>
            </div>
          </>
        ) : (
          <div className='list-group-item'>
            <button
              type='button'
              className='common-btn'
              onClick={(event) => logoutUser(event)}
            >
              <i className='lnil lnil-exit' />
              {strings.logout}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserInformation;
