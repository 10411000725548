import React, { useEffect, useRef } from 'react';
import {
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { moment, momentFormat } from '../../../constants';
import { strings } from '../../../constants/localization';
import {
  setCurrentApplicationUuid,
  setCurrentApplicationItem,
  getNoitsoApplicationData,
  getMonthioApplicationData,
  setApplicationLoading,
} from '../../../actions/onboardingApplicationsActions';
import { applicationStatusMap } from '../../../constants/mappings';

const ApplicationListItem = (props) => {
  const { application, active } = props;
  const caUuid = application?.creditApplicationUuid;
  const creditApplicationType = application?.creditApplicationType || null;
  const activeUuid = useSelector(
    (state) => state.onboardingApplication?.currentApplicationUuid
  );
  const dispatch = useDispatch();
  const { creditApplicationId, createdDate } = application || {};
  const abortControllerRef = useRef(null);
  const localTime = moment.utc(createdDate).local();
  const applicationCreatedDate = localTime.format(momentFormat);

  useEffect(() => {
    if (activeUuid !== caUuid) {
      // cleanup function to abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    }
  }, [activeUuid]);

  const getCreditApplicationData = () => {
    dispatch(setApplicationLoading(true));
    if (caUuid) {
      dispatch(setCurrentApplicationUuid(caUuid));

      // abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      if (
        creditApplicationType === null ||
        creditApplicationType === 'MONTHIO'
      ) {
        dispatch(getMonthioApplicationData(caUuid, newAbortController.signal))
          .then((res) => {
            dispatch(setCurrentApplicationItem(res?.data || {}));
            dispatch(setApplicationLoading(false));
          })
          .catch((err) => {
            console.log(err);
            if (err.name !== 'CanceledError') {
              dispatch(setApplicationLoading(false));
            }
          });
      } else {
        dispatch(getNoitsoApplicationData(caUuid, newAbortController.signal))
          .then((res) => {
            dispatch(setCurrentApplicationItem(res?.data || {}));
            dispatch(setApplicationLoading(false));
          })
          .catch((err) => {
            console.log(err);
            if (err.name !== 'CanceledError') {
              dispatch(setApplicationLoading(false));
            }
          });
      }
    }
  };

  return (
    <ListGroupItem active={active} onClick={() => getCreditApplicationData()}>
      <div className={`${active ? 'active' : ''} single-list-item`}>
        <div className='logo'>
          <div
            className={`d-logo ${
              applicationStatusMap[application?.creditApplicationStatus]
                ?.className
            }`}
          >
            <i className='di di-sundhedplus' />
          </div>
        </div>
        <div>
          <ListGroupItemHeading>{application?.name}</ListGroupItemHeading>
          <ListGroupItemText tag='div'>
            {
              strings[
                applicationStatusMap[application?.creditApplicationStatus]
                  ?.statusText
              ]
            }
          </ListGroupItemText>
        </div>
        <div className='info-location application'>
          <span>
            {strings.applicationItemID} {creditApplicationId || ''}
          </span>
          <span>
            {strings.applicationItemDate} {applicationCreatedDate || ''}
          </span>
        </div>
      </div>
    </ListGroupItem>
  );
};

export default ApplicationListItem;
