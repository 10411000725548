import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    Container,
    Row,
    Col,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Table,
} from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { sortBy } from 'lodash';
import { strings } from '../../constants/localization';
import { moment, momentFormat } from '../../constants';
import { invoicesStatusMap } from '../../constants/mappings';
import { currencyFormatDA, formatInterestRate } from '../../constants/utils';
import {
    thumbVerticalStyle,
    trackVerticalStyleForPaymentPlan,
} from '../../constants/styleScrollbar';
import { getDebtCollectionEntries } from '../../actions/installmentLoanActions';
import { toggleServerModalError } from '../../actions/uiElementsActions';

const LoanDetails = ({ loanNumber, loanDetails, isClientPortal }) => {
    const [activeTab, setActiveTab] = useState('2');
    const [debtCollectionEntries, setDebtCollectionEntries] = useState([]);
    const [sortedLoanPayments, setSortedLoanPayments] = useState([]);
    const [sortedLatePayments, setSortedLatePayments] = useState([]);
    const [totalAdditionalCosts, setTotalAdditionalCosts] = useState(0);
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    useEffect(() => {
        let additionalCosts = 0;
        if (loanDetails?.status === 'LATE_PAYMENT') {
            dispatch(getDebtCollectionEntries(loanNumber)).then((res) => {
                if (res.payload.status !== 200) {
                    dispatch(toggleServerModalError(false));
                } else {
                    setDebtCollectionEntries(res.payload.data);
                    setActiveTab('1');
                }
            });
        }

        const loadDetailsPayments = sortBy(
            loanDetails?.payments,
            (payment) => new Date(payment?.paymentDate)
        );
        loadDetailsPayments.map((payment, index, array) => {
            const result = calculateDaysSincePayed(payment)?.nextPaymentPenalty;
            if (result && index < array.length - 1) {
                array[index + 1].penalty = result;
                additionalCosts = additionalCosts + result;
            }
            return payment;
        });

        setSortedLoanPayments(loadDetailsPayments);
        setTotalAdditionalCosts(additionalCosts);
    }, [loanDetails]);

    useEffect(() => {
        if (debtCollectionEntries?.length > 0) {
            setSortedLatePayments(
                sortBy(
                    debtCollectionEntries,
                    (payment) => new Date(payment?.actionDate)
                )
            );
        }
    }, [debtCollectionEntries]);

    const resetScrollPosition = () => {
        if (scrollRef.current !== null) {
            scrollRef.current.view.scrollTop = 0;
        }
    };

    useEffect(() => {
        resetScrollPosition();
    }, [activeTab]);

    const toggle = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const calculateDaysSincePayed = (payment) => {
        const dateToPay = moment(payment?.paymentDate);
        const dateWhenPayed = moment(payment?.paidDate);
        let diffAsString: string;
        let nextPaymentPenalty: number;
        let className: string;
        if (payment?.paidDate) {
            const diffDays = Number(dateWhenPayed.diff(dateToPay, 'days'));
            diffAsString = `${diffDays}`;
            if (diffDays <= 0) {
                className = 'green';
            } else if (diffDays > 0 && diffDays < 9) {
                className = 'grey';
            } else if (diffDays >= 9 && diffDays < 19) {
                className = 'yellow';
            } else if (diffDays >= 19) {
                className = 'red';
            }

            if (diffDays < 0) {
                diffAsString = `${diffDays}`;
            } else {
                diffAsString = `+${diffDays}`;
            }

            if (diffDays >= 9 && diffDays < 19) {
                nextPaymentPenalty = 100;
            } else if (diffDays >= 19) {
                nextPaymentPenalty = 200;
            } else {
                nextPaymentPenalty = 0;
            }

            return {
                diffAsString,
                nextPaymentPenalty,
                className,
            };
        }
        return;
    };

    const displayPaymentStatus = (payment) => {
        const status = payment.status;
        switch (status) {
            case 'WAITING':
                if (moment(payment.paymentDate).isAfter()) {
                    return (
                        <td style={{ color: '#969696' }}>
                            {strings.loanDetailsFuturePayment}
                        </td>
                    );
                }
                return (
                    <td className={invoicesStatusMap[payment.status].className}>
                        {strings.overdue}
                    </td>
                );

            case 'REEPAY':
                if (moment(payment.paymentDate).isAfter()) {
                    return (
                        <td style={{ color: '#969696' }}>
                            {strings.loanDetailsFuturePayment}
                        </td>
                    );
                }
                return (
                    <td className={invoicesStatusMap[payment.status].className}>
                        {strings.overdue}
                    </td>
                );

            case 'DUNNING':
            case 'FAILED':
                return (
                    <td className={invoicesStatusMap[payment.status].className}>
                        {strings.overdue}
                    </td>
                );
            case 'PAYED':
                if (payment.paymentMethod === 'CARD_DIBS') {
                    return (
                        <td className={invoicesStatusMap[payment.status].className}>
                            {strings.paidReepay}
                        </td>
                    );
                }
                if (
                    payment.paymentMethod === 'ACCOUNT_TRANSFER' ||
                    payment.paymentMethod === 'MOBILEPAY'
                ) {
                    return (
                        <td className={invoicesStatusMap[payment.status].className}>
                            {strings.paidManual}
                        </td>
                    );
                }
                break;
            default:
                return (
                    <td className={invoicesStatusMap[payment.status].className}>
                        {strings[invoicesStatusMap[payment.status].statusText]}
                    </td>
                );
        }
    };

    return (
        <Container fluid>
            <Row className='mb-4'>
                <Col xs='12' md='6'>
                    <h4 className='font-weight-bold'>{strings.loanDetails}</h4>
                </Col>
                <Col xs='12' md='6' className='text-right'>
                    <small className='align-middle'>{loanNumber}</small>
                </Col>
            </Row>
            <Row className='details'>
                <Col xs='12' md='6' className='border-right'>
                    <div className='d-flex flex-row justify-content-between mb-1'>
                        <span>{strings.loanAmount}</span>
                        <span>{currencyFormatDA(loanDetails?.amount, true, true)}</span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{strings.setupFee}</span>
                        <span>{currencyFormatDA(loanDetails?.setupFee, true, true)}</span>
                    </div>
                </Col>
                <Col xs='12' md='6'>
                    <div className='d-flex flex-row justify-content-between mb-1'>
                        <span>{strings.interestRate}</span>
                        <span>
                            {`${formatInterestRate(
                                loanDetails?.loanInterestRate
                            )} / ${formatInterestRate(loanDetails?.apr)}`}
                        </span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{strings.loanDuration}</span>
                        <span>{`${loanDetails?.duration} ${strings.months}`}</span>
                    </div>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col xs='12' md='12' className='loan-table'>
                    {!isClientPortal && (
                        <Nav tabs>
                            {loanDetails?.status === 'LATE_PAYMENT' && (
                                <NavItem>
                                    <NavLink
                                        className={activeTab === '1' ? 'active' : ''}
                                        onClick={() => toggle('1')}
                                    >
                                        {strings.latePaymentCredit}
                                    </NavLink>
                                </NavItem>
                            )}
                            <NavItem>
                                <NavLink
                                    className={activeTab === '2' ? 'active' : ''}
                                    onClick={() => toggle('2')}
                                >
                                    {strings.installments}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    )}
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId='2'>
                            <div className='loan-details-scroll'>

                                <div>
                                    <Table>
                                        <thead>
                                            <tr className='table-header'>
                                                <th></th>
                                                <th>{strings.loanDetailsHeaderHash}</th>
                                                <th>{strings.loanDetailsHeaderDueDate}</th>
                                                <th>{strings.loanDetailsHeaderMonthlyPayment}</th>
                                                <th className='rightSide'>
                                                    {strings.loanDetailsHeaderStatus}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody className='table-body'>
                                            <tr>
                                                <td className='scrollbar-wrapper' colSpan={6} style={{ padding: 0, border: 'none' }}>
                                                    <Scrollbars ref={scrollRef}
                                                        renderTrackVertical={({ style, ...props }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...style,
                                                                    ...trackVerticalStyleForPaymentPlan,
                                                                }}
                                                            />
                                                        )}
                                                        renderThumbVertical={({ style, ...props }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...style,
                                                                    ...thumbVerticalStyle,
                                                                }}
                                                            />
                                                        )}
                                                        style={{ height: 440 }}
                                                    >
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                {sortedLoanPayments.map((payment, index) => {
                                                                    const diffPayedDate =
                                                                        calculateDaysSincePayed(payment);
                                                                    return (
                                                                        <tr key={payment.id}>
                                                                            <td></td>
                                                                            <td>{index + 1}.</td>
                                                                            <td className='payed-cell'>
                                                                                <span>
                                                                                    {moment(payment.paymentDate).format(
                                                                                        momentFormat
                                                                                    )}
                                                                                </span>
                                                                                {!isClientPortal && diffPayedDate && (
                                                                                    <span
                                                                                        className={`payed-diff ${diffPayedDate?.className}`}
                                                                                    >
                                                                                        {diffPayedDate?.diffAsString}
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                            <td className='payed-cell'>
                                                                                {currencyFormatDA(payment.amount, true, true)}
                                                                                {!isClientPortal && (
                                                                                    <span className='penalty'>
                                                                                        {payment?.penalty
                                                                                            ? `+ ${payment?.penalty}`
                                                                                            : ''}
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                            {displayPaymentStatus(payment)}
                                                                            <td></td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </Scrollbars>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                            </div>
                        </TabPane>
                    </TabContent>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId='1'>
                            <div className='loan-details-scroll'>

                                <div>
                                    <Table>
                                        <thead>
                                            <tr className='table-header dbt-collection '>
                                                <th></th>
                                                <th>{strings.loanDetailsHeaderDate}</th>
                                                <th>{strings.loanDetailsHeaderAction}</th>
                                                <th>{strings.loanDetailsHeaderOther}</th>
                                                <th className='rightSide'>
                                                    {strings.loanDetailsHeaderOutstandingDebt}
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-body'>
                                            <tr>
                                                <td className='scrollbar-wrapper' colSpan={6} style={{ padding: 0, border: 'none' }}>
                                                    <Scrollbars ref={scrollRef}
                                                        renderTrackVertical={({ style, ...props }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...style,
                                                                    ...trackVerticalStyleForPaymentPlan,
                                                                }}
                                                            />
                                                        )}
                                                        renderThumbVertical={({ style, ...props }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...style,
                                                                    ...thumbVerticalStyle,
                                                                }}
                                                            />
                                                        )}
                                                        style={{ height: 440 }}
                                                    >
                                                        <table style={{ width: '100%' }}>
                                                            <tbody>
                                                                {sortedLatePayments.map((payment, index) => (
                                                                    <tr key={index}>
                                                                        <td></td>
                                                                        <td>
                                                                            {moment(payment.actionDate).format(momentFormat)}
                                                                        </td>
                                                                        <td>
                                                                            <span className='has-tooltip rightArrowCenterLeft '>
                                                                                <i className='di di-help' />
                                                                                <span className='tooltip'>
                                                                                    {payment.description}
                                                                                </span>
                                                                            </span>
                                                                            {
                                                                                // do not show what is inside of parentheses on description
                                                                                payment.description?.includes('(')
                                                                                    ? payment.description?.substring(
                                                                                        0,
                                                                                        payment.description?.indexOf('(')
                                                                                    )
                                                                                    : payment.description
                                                                            }
                                                                        </td>
                                                                        {payment.type === 'DEBIT' && (
                                                                            <td className='status-red'>
                                                                                {currencyFormatDA(payment.amount, true, true)}
                                                                            </td>
                                                                        )}
                                                                        {payment.type === 'CREDIT' &&
                                                                            !payment.description?.includes('Afskrivning') && (
                                                                                <td className='status-credit-green'>
                                                                                    -
                                                                                    {currencyFormatDA(payment.amount, true, true)}
                                                                                </td>
                                                                            )}
                                                                        {payment.type === 'CREDIT' &&
                                                                            // first world of string is 'Late payment fee'
                                                                            payment.description?.includes('Afskrivning') && (
                                                                                <td className='status-yellow'>
                                                                                    -
                                                                                    {currencyFormatDA(payment.amount, true, true)}
                                                                                </td>
                                                                            )}
                                                                        <td>
                                                                            {currencyFormatDA(
                                                                                payment.newPrincipal,
                                                                                true,
                                                                                true
                                                                            )}
                                                                            {payment.fee > 0 && ' + ' + payment.fee}
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Scrollbars>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            <Row className='details'>
                <Col xs='12' md='5'>
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{strings.paidInTotal}</span>
                        <span className='font-weight-bold'>
                            {currencyFormatDA(loanDetails?.paymentSum, true, true)}
                        </span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{strings.repayments}</span>
                        <span>
                            {currencyFormatDA(loanDetails?.repaymentSum, true, true)}
                        </span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{strings.interests}</span>
                        <span>
                            {currencyFormatDA(loanDetails?.interestSum, true, true)}
                        </span>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <span>{strings.additionalCosts}</span>
                        <span>{currencyFormatDA(totalAdditionalCosts, true, true)}</span>
                    </div>
                </Col>
                <Col xs='12' md='7' className='pt-2 pt-md-0'>
                    <div className='d-flex flex-row justify-content-end'>
                        {activeTab === '1' &&
                            strings.formatString(
                                strings.principalAsOf,
                                moment(
                                    debtCollectionEntries[debtCollectionEntries.length - 1]
                                        ?.actionDate
                                ).format(momentFormat)
                            )}
                        {activeTab === '2' &&
                            strings.formatString(
                                strings.principalAsOf,
                                moment().format(momentFormat)
                            )}
                        :
                    </div>
                    <div className='d-flex flex-row justify-content-end'>
                        <span className='total-as'>
                            {activeTab === '1' &&
                                currencyFormatDA(
                                    debtCollectionEntries[debtCollectionEntries.length - 1]
                                        ?.newPrincipal,
                                    true,
                                    true
                                )}
                            {activeTab === '2' &&
                                currencyFormatDA(loanDetails?.remainingPrincipal, true, true)}
                        </span>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default LoanDetails;
