import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearMemberPortalData,
  createMitIdState,
  isClientLogout,
  loadingClientPortalData,
  // eslint-disable-next-line
} from '../../actions/memberPortalActions';
import { useCriiptoVerify } from '@criipto/verify-react';
import LoadingMessage from '../statusMessages/LoadingMessage';
import { config } from '../../config';
import { AnyAction } from 'redux';
import { moment } from '../../constants';
// interface clientDataProps {
//   clientData: {
//     firstName: string;
//     lastName: string;
//   };
// }

const ClientPortalIntermediate: React.FC = () => {
  const currentDomain = window.location.hostname;
  const dispatch = useDispatch();
  const { isLoading, isInitializing, loginWithRedirect, result, error } =
    useCriiptoVerify();
  const [isMitIdStateCreated, setIsMitIdStateCreated] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const mitIdState = useSelector(
    (state: { memberPortal: { mitIdState: string } }) =>
      state?.memberPortal?.mitIdState
  );

  // const memberData = useSelector(
  //   (state: { memberPortal: { clientDataInformation: clientDataProps } }) =>
  //     state.memberPortal?.clientDataInformation
  // );
  // const authenticated = useSelector(
  //   (state: { memberPortal: { clientIsAuthenticated: boolean } }) =>
  //     state.memberPortal?.clientIsAuthenticated
  // );

  // const history = useHistory();

  // useEffect(() => {
  //   if (authenticated && memberData) {
  //     history.push({ pathname: ROUTE_MIT_SUNDHEDPLUS });
  //   } else if (!authenticated && !memberData && mitIdState) {
  //     dispatch(clearMemberPortalData());
  //   }
  // }, [authenticated, history]);

  // first when load this component we need to clear the member portal data
  useEffect(() => {
    dispatch(clearMemberPortalData());
    dispatch(isClientLogout(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadingClientPortalData(true));

    const createMitId = async () => {
      if (currentDomain === process.env.REACT_APP_CLIENT_PORTAL_DOMAIN) {
        await dispatch(createMitIdState() as AnyAction)
          .then(() => {
            setIsMitIdStateCreated(true);
            localStorage.setItem('loginTimeMit', JSON.stringify(moment.now()));
          })
          .catch((error: string) => {
            console.log('error', error);
            window.location.href = config.damAppMainPageUrl;
          });
      }
    };

    if (!isMitIdStateCreated && !mitIdState) {
      createMitId();
    }
  }, [dispatch, currentDomain, isMitIdStateCreated, mitIdState]);

  useEffect(() => {
    if (
      isMitIdStateCreated &&
      !isLoading &&
      !result &&
      !isInitializing &&
      mitIdState
    ) {
      // check if localStorage has the mitIdSession
      setTimeout(() => {
        loginWithRedirect().then(() => {
          localStorage.setItem('loginTimeMit', JSON.stringify(moment.now()));
        });
      }, 2000);
    }
  }, [
    isMitIdStateCreated,
    isLoading,
    loginWithRedirect,
    result,
    isInitializing,
    mitIdState,
  ]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setLoading(false);
      dispatch(clearMemberPortalData());
      // window.location.href = config.damAppMainPageUrl;
    }
  }, [error, dispatch]);

  return loading && <LoadingMessage />;
};

export default ClientPortalIntermediate;
