import React, { Component } from 'react';
import { ListGroup, Form, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Field, reduxForm } from 'redux-form';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import {
  VIEW_ALL,
  COMPANIES_LOADING,
  COMPANIES_SUCCESS,
  COMPANIES_EMPTY,
  COMPANIES_FAILURE,
} from '../../../constants';
import { ROUTE_COMPANIES } from '../../../constants/routes';
import { strings } from '../../../constants/localization';
import {
  getCompanies,
  setActivePage,
  searchEmitted,
  setCurrentCompany,
  filterEmitted,
} from '../../../actions/companyActions';
import { clearClinicWebsocketMessage } from '../../../actions/websocketActions';
import {
  normalizePhone,
  isAuthenticated,
  required,
  getTabObjectFromStorage,
  getCachedSearch,
  saveCachedData,
} from '../../../constants/utils';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
  thumbVerticalCustomBackgroundStyle,
} from '../../../constants/styleScrollbar';
import { companySidebarActiveStatus } from '../../../actions/statusActions';
import CompaniesListItem from './CompanyListItem';

import LoadingMessage from '../../statusMessages/LoadingMessage';
import EmptyMessage from '../../statusMessages/EmptyMessage';
//import ErrorMessage from '../../statusMessages/ErrorMessage';
import ServerModalError from '../../common/ServerModalError';
import CompaniesFilter from './CompaniesFilter';
import { isAdmin } from '../../../constants/utils';

class CompaniesList extends Component {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();

    this.state = {
      searchedInput:
        this.props.search?.flag === true ? this.props.search.searchInput : '',
      activePage: 1,
      filterPayload: null,
      scrollTimeout: null,
    };

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetScrollPosition = this.resetScrollPosition.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.resetView = this.resetView.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.restoreSearch = this.restoreSearch.bind(this);
  }

  renderDone() {
    const brandEnvVariable = process.env.REACT_APP_BRAND;
    return (
      <div className='entities-list-group'>
        <Scrollbars
          ref={this.scrollRef}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) =>
            brandEnvVariable === 'Denti' ? (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalStyle,
                }}
              />
            ) : (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalCustomBackgroundStyle,
                }}
              />
            )
          }
        >
          <div className='scrollbar-wrapper'>
            <ListGroup className='company-list-group'>
              {this.props.companies.content.map((company) => (
                <CompaniesListItem
                  key={company.id}
                  company={company}
                  active={
                    this.props.activeCompany
                      ? company.id === this.props.activeCompany.id
                      : false
                  }
                />
              ))}
            </ListGroup>
            {this.props.companies.totalPages > 1 && (
              <Pagination
                activePage={this.props.companies.pageable.page + 1}
                itemClass='page-item'
                linkClass='page-link'
                itemsCountPerPage={20}
                totalItemsCount={this.props.companies.total}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            )}
          </div>
        </Scrollbars>
      </div>
    );
  }

  resetView() {
    this.props.setActivePage(1);
    this.props.getCompanies();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: normalizePhone(value),
    });
  }

  resetScrollPosition() {
    if (this.scrollRef.current !== null) {
      this.scrollRef.current.view.scrollTop = 0;
    }
  }

  static renderField(field) {
    const { input, refName, placeholder } = field;

    return (
      <input
        className='search-query form-control'
        id='searchQuery'
        ref={refName}
        type={field.type}
        {...input}
        placeholder={placeholder}
      />
    );
  }

  handleSearchSubmit(values) {
    this.props.setActivePage(1);
    if (values.searchedInput !== undefined) {
      const keyword = normalizePhone(values.searchedInput.trim());
      if (keyword !== '') {
        this.props.searchEmitted({ flag: true, searchInput: keyword });
        this.input.blur();
        this.props.getCompanies(
          undefined,
          0,
          keyword,
          () => {
            this.props.companySidebarActiveStatus();
            this.resetScrollPosition();
            this.props.setCurrentCompany(this.props.companies.content[0]);
          },
          Object.keys(this.props.filters).length > 0 ? this.props.filters : null
        );
      }
    }
  }

  handlePageChange(pageNumber) {
    const { activePage, statusActive, search } = this.props;
    if (pageNumber !== activePage) {
      this.props.setActivePage(pageNumber);
      this.setState({ activePage: pageNumber }, () => {
        this.props.getCompanies(
          statusActive,
          this.state.activePage - 1,
          search && search.flag === true ? this.state.searchedInput : '',
          () => {
            this.resetScrollPosition();
          },
          this.state.filterPayload
        );
      });
    }
  }

  filterSubmit(filterPayload, deleteFlag) {
    this.setState({ filterPayload }, () => {
      if (isAuthenticated()) {
        this.props.setActivePage(1);
        this.props.filterEmitted(filterPayload, deleteFlag);
        this.props.getCompanies(
          null,
          0,
          this.state.searchedInput !== '' ? this.state.searchedInput : null,
          () => {
            this.resetScrollPosition();
          },
          filterPayload
        );
      }
    });
  }

  restoreSearch() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.props.getCompanies(
      undefined,
      this.props.activePage !== undefined ? this.props.activePage - 1 : 0,
      this.props.search.searchInput,
      () => {
        that.props.setCurrentCompany(this.props.activeCompany);
      }
    );
    this.props.searchEmitted({
      flag: true,
      searchInput: this.state.searchedInput,
    });
    this.props.change('searchedInput', this.state.searchedInput);
  }

  componentDidMount() {
    if (isAuthenticated()) {
      //if there is a query in the location url, display company based on that
      const searchParams = new URLSearchParams(this.props.location?.search);
      if (searchParams?.has('q')) {
        const query = queryString.parse(this.props.location.search);
        const values = {
          searchedInput: query.q,
        };

        this.handleSearchSubmit(values);
        this.props.change('searchedInput', values.searchedInput);
      }

      //if there's some company from the router Link location, load that
      else if (this.props.displayCompany) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.props.companySidebarActiveStatus();
        this.props.getCompanies(
          undefined,
          undefined,
          this.props.displayCompany.ref,
          () => {
            that.props.setCurrentCompany(that.props.companies.content[0]);
          }
        );
      }
      //if there was a search keyword saved to state, use that to display companies
      else if (this.props.search && this.props.search.flag === true) {
        this.restoreSearch();
      }
      // if there was filter, restore companies based on that
      else if (Object.keys(this.props.filters).length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.props.getCompanies(
          undefined,
          this.props.activePage !== undefined ? this.props.activePage - 1 : 0,
          undefined,
          () => {
            that.props.setCurrentCompany(this.props.activeCompany);
          },
          this.props.filters
        );
      }
      // otherwise show all companies
      else {
        this.props.getCompanies(
          this.props.statusActive,
          this.props.activePage !== undefined ? this.props.activePage - 1 : 0
        );
        getTabObjectFromStorage(
          searchParams?.get('tabID'),
          'lastSelectedClinic',
          (lastStelected) => {
            this.props.setCurrentCompany(lastStelected);
            const { searchedInput } = getCachedSearch('clinicSearch') || {};
            if (searchedInput) {
              this.props.change('searchedInput', searchedInput);
              this.props.searchEmitted({
                flag: true,
                searchInput: searchedInput,
              });
            }
          }
        );
      }
    }
  }

  // check the ws messages for clinics
  componentDidUpdate(prevProps) {
    if (prevProps.clinicWsMessages !== this.props.clinicWsMessages) {
      // get the ws messages that match visible compnaies in list
      let wsClinicsUpdated = this.props.clinicWsMessages.filter((clinicWs) =>
        this.props.companies.content.some(
          (company) =>
            clinicWs.companyReferenceNumber === company.referenceNumber
        )
      );

      if (wsClinicsUpdated.length > 0) {
        wsClinicsUpdated.forEach((message) => {
          // reload only if the triggering user is not the current user
          if (message?.triggeringUser !== this.props.user?.username) {
            // if there was a search, update companies based on that
            if (this.props.search && this.props.search.flag === true) {
              this.restoreSearch();
            }
            // otherwise show all companies
            else {
              this.props.getCompanies(
                this.props.statusActive,
                this.props.activePage !== undefined
                  ? this.props.activePage - 1
                  : 0
              );
            }
            this.props.clearClinicWebsocketMessage();
          }
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.scrollTimeout);
  }

  clearSearch() {
    saveCachedData('clinicSearch', null);
    this.resetScrollPosition();
    this.props.setActivePage(1);
    this.props.reset();
    this.props.searchEmitted({ flag: false, searchInput: '' });
    this.setState({
      searchedInput: '',
    });

    this.props.getCompanies(
      this.props.statusActive,
      0,
      '',
      () => {
        this.props.companySidebarActiveStatus(VIEW_ALL);
        this.props.setCurrentCompany();
        this.resetScrollPosition();
      },
      Object.keys(this.props.filters).length > 0 ? this.props.filters : null
    );

    if (this.props.location.search !== '') {
      const searchParams = new URLSearchParams(this.props?.location?.search);
      if (searchParams.has('tabID')) {
        this.props.history.replace(
          `${ROUTE_COMPANIES}?tabID=${searchParams.get('tabID')}`
        );
      } else {
        this.props.history.replace(ROUTE_COMPANIES);
      }
    }
  }

  render() {
    let renderSituation;
    const { handleSubmit, search, loadingStatus, statusActive } = this.props;

    switch (loadingStatus) {
      case COMPANIES_LOADING:
        renderSituation = <LoadingMessage />;
        break;
      case COMPANIES_SUCCESS:
        renderSituation = this.renderDone();
        break;
      case COMPANIES_EMPTY:
        renderSituation = (
          <div className='scrollbar-pseudo-rail'>
            {search !== undefined && search.flag ? (
              <EmptyMessage message={strings.searchEmpty} />
            ) : (
              <>
                {statusActive === undefined || statusActive === VIEW_ALL ? (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noItems,
                      strings.companiesPlaceholder
                    )}
                  />
                ) : (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noCategoryItems,
                      strings.companiesPlaceholder
                    )}
                  />
                )}
              </>
            )}
          </div>
        );
        break;
      case COMPANIES_FAILURE:
        renderSituation = (
          <div className='scrollbar-pseudo-rail'>
            <ServerModalError />
            {/* <ErrorMessage message={strings.errorLoading} /> */}
          </div>
        );
        break;
      default:
        renderSituation = (
          <div className='scrollbar-pseudo-rail'>
            <EmptyMessage
              message={strings.formatString(
                strings.noItems,
                strings.companiesPlaceholder
              )}
            />
          </div>
        );
    }

    return (
      <>
        <div className='header'>
          <button
            type='button'
            className='btn btn-white menu-toggler'
            onClick={this.props.toggleMenu}
          >
            <div className='menu-toggler-icon'>
              <span />
              <span />
              <span />
            </div>
          </button>
          <h3 className='title'>{strings.companies}</h3>
          <Form
            className='search-form'
            onSubmit={handleSubmit(this.handleSearchSubmit)}
            autoComplete='off'
          >
            {search !== undefined && search.flag === true ? (
              <button
                type='button'
                className='btn-clear-search'
                onClick={this.clearSearch}
              >
                <span className='clear-search di di-close' />
              </button>
            ) : (
              <button type='submit' className='btn-search'>
                <span className='lnir lnir-search-alt' />
              </button>
            )}
            <FormGroup className='mb-0'>
              <Field
                // eslint-disable-next-line
                refName={(ref) => (this.input = ref)}
                name='searchedInput'
                onChange={this.handleChange}
                component={CompaniesList.renderField}
                validate={[required]}
                placeholder={strings.formatString(
                  strings.searchPlaceholder,
                  strings.companiesPlaceholder
                )}
              />
            </FormGroup>
          </Form>
        </div>

        {this.props.user && isAdmin(this.props.user) && (
          <CompaniesFilter
            searchKeyword={this.state.searchedInput}
            filterSubmit={this.filterSubmit}
            searchStatus={loadingStatus}
            search={search}
            resetView={this.resetView}
          />
        )}

        {renderSituation}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    companies: state.company.companiesList,
    companiesFilteredBySearch: state.company.companiesFilteredBySearch,
    loadingStatus: state.company.loading,
    statusActive: state.status.companyStatusActive,
    statuses: state.status.companyStatuses,
    activeCompany: state.company.activeCompany,
    activePage: state.company.activePage,
    search: state.company.searchActive,
    filters: state.company.filters,
    user: state.user.user,
    clinicWsMessages: state.websocketMessages.clinic,
  };
}

CompaniesList.propTypes = {
  setActivePage: PropTypes.func,
  companies: PropTypes.object,
  loadingStatus: PropTypes.string,
  statusActive: PropTypes.string,
  statuses: PropTypes.object,
  activeCompany: PropTypes.object,
  activePage: PropTypes.number,
  searchEmitted: PropTypes.func,
  search: PropTypes.object,
  filterEmitted: PropTypes.func,
  getCompanies: PropTypes.func,
  setCurrentCompany: PropTypes.func,
  toggleMenu: PropTypes.func,
  companySidebarActiveStatus: PropTypes.func,
  displayCompany: PropTypes.object,
  user: PropTypes.object,
};

export default reduxForm({
  form: 'searchForm',
})(
  connect(mapStateToProps, {
    getCompanies,
    searchEmitted,
    filterEmitted,
    setActivePage,
    setCurrentCompany,
    companySidebarActiveStatus,
    clearClinicWebsocketMessage,
  })(CompaniesList)
);
