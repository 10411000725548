import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  requestAcceptLoanDetails,
  statusAcceptLoan,
  comfirmAcceptLoan,
  sendAgreement,
  recalculateValues,
} from '../../actions/installmentLoanActions';

import { moment } from '../../constants';
import { getGeolocation, getBrowserType } from '../../constants/utils';
import happyGuy from '../../resources/images/happy-guy.svg';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ErrorPage from '../common/ErrorPage';
import { ROUTE_HOME } from '../../constants/routes';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
//eslint-disable-next-line
import { LoanDataOutput, AcceptanceLoanData } from '../interfaces';

import StepOne from './StepOne';
//eslint-disable-next-line
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import StepAccept from './StepAccept';
import StepDecline from './StepDecline';
import StepError from './StepError';
import Fade from '../common/Fade';
import StepDeclineRest from './StepDeclineRest';
const brandEnvVariable = process.env.REACT_APP_BRAND;

interface UserEnteredData {
  duration: number;
  firstVisit: boolean;
  acceptCurrentCard: boolean;
  registerLater: boolean;
  paymentMethod: null | object;
}

const LoanAcceptance: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [source, setSource] = useState("");
  const [hasRedirect, setHasRedirect] = useState<boolean>(false);
  const [hasLogo, setHasLogo] = useState<boolean>(true);
  const [loanData, setLoanData] = useState<AcceptanceLoanData | null>(null);
  const [totalSteps, setTotalSteps] = useState<number>(4);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [showErrorContent, setShowErrorContent] = useState<boolean>(false);
  const [isFlowOver, setIsFlowOver] = useState<boolean>(false);
  const [agreement, setAgreement] = useState({});
  const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<LoanDataOutput>(null);
  const [skipSliderStep, setSkipSliderStep] = useState<boolean>(false);
  const [processData, setProcessData] = useState<UserEnteredData>({
    duration: 24, firstVisit: true, acceptCurrentCard: false, registerLater: false, paymentMethod: null,
  });
  const reepayInvoiceSettledInterval = useRef(null);

  const requestLoanDetails = () => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');
    const device = queryParams.get('device');
    const loanId = queryParams.get('id');

    if (source === 'portal' && device === 'mobile') {
      setSource('portal');
      setHasRedirect(true);
      setHasLogo(false);
    } else if (source === 'portal' && device === 'desktop') {
      setSource('portal');
      setHasRedirect(false);
      setHasLogo(false);
      document.body.classList.add('iframe-acceptance-flow');
    }

    if (loanId) {
      dispatch(requestAcceptLoanDetails(loanId))
        .then((result) => {
          const { data } = result.payload;
          if (data.status === 'WAITING_FOR_SIGNATURE') {
            setLoanData(data);
            setTotalSteps(data.showQuestion ? 5 : 4);
            setActiveStep(data.restructured ? 2 : 1);
            if (data.restructured) {
              document.body.classList.add('restructured-flow');
            }

            // this is a fix for a step 2 animation
            setTimeout(() => {
              setIsLoading(false);
              document.body.classList.remove('loading');
            }, 1000);
          } else {
            setLoanData(data);
            setShowErrorContent(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          document.body.classList.remove('loading');
          triggerErrorStep();
        }).finally(() => {
          setIsLoading(false);
        });
    } else {
      history.push(ROUTE_HOME);
    }
  };

  useEffect(() => {
    if (isIOS()) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    document.body.classList.add('public-flow');
    document.body.classList.add('public-content');
    document.body.classList.add('loading');

    requestLoanDetails();

    setAgreement({
      coordinates: {
        latitude: null,
        longitude: null,
        accuracy: null,
      }
    });

    if (window.navigator.geolocation) {
      setAgreement({
        coordinates: getGeolocation(window.navigator)
      });
    }

    return () => {
      document.body.classList.remove('public-flow');
      document.body.classList.remove('public-content');
    };
  }, []);


  useEffect(() => {
    if (loanData) {
      // request every 30 seconds the loan details to update the reepay settled status
      reepayInvoiceSettledInterval.current = setInterval(() => {
        if (
          loanData.status === 'WAITING_FOR_SIGNATURE' &&
          loanData.restructured &&
          loanData.reepayInvoiceSettled === false
        ) {
          requestLoanDetails();
        }
      }, 30000);

      setAgreement({
        device: getBrowserType(
          window.navigator.userAgent
        ).name.toLocaleLowerCase(),
        installmentLoanNumber: loanData?.loanNumber
      });
    }

    return () => {
      clearInterval(reepayInvoiceSettledInterval.current);
    };
  }, [loanData]);



  const isIOS = (): boolean => {
    if ('userAgentData' in navigator) {
      const platform = (navigator.userAgentData as { platform?: string }).platform;
      return platform === 'iOS';
    }
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  };

  const triggerErrorStep = () => {
    setActiveStep(-2);
    setIsFlowOver(true);
    setIsLoading(false);
    document.body.classList.remove('loading');
  };

  const rejectLoan = () => {
    setActiveStep(-1);
    setIsFlowOver(true);
    dispatch(statusAcceptLoan(loanData.loanNumber, {
      status: 'NOT_ACCEPTED',
    }));
  }

  const renderActiveStep = () => {
    window.scroll(0, 0);

    switch (activeStep) {
      case 1:
        return (
          <StepOne
            acceptLoan={loanData}
            nextStep={nextStep}
            rejectLoan={rejectLoan}
          />
        );
      case 2:
        return (
          <StepTwo
            acceptLoan={loanData}
            nextStep={nextStep}
            previousStep={previousStep}
            rejectLoan={rejectLoan}
            updateProcessData={updateProcessData}
            setSkipSliderStep={setSkipSliderStep}
          />
        );
      case 3:
        return (
          <StepThree
            acceptLoan={loanData}
            processData={paymentDetails}
            nextStep={nextStep}
            previousStep={previousStep}
            rejectLoan={rejectLoan}
            skipSliderStep={skipSliderStep}
          />
        );
      case 4:
        return (
          <StepFour
            acceptLoan={loanData}
            nextStep={nextStep}
          />
        );
      case 5:
        return (
          <StepFive
            acceptLoan={loanData}
            nextStep={nextStep}
            processData={paymentDetails}
          />
        );
      case 6:
        return (
          <StepSix
            acceptLoan={loanData}
            nextStep={nextStep}
          />
        );
      case 7:
        return (
          <StepAccept
            acceptLoan={loanData}
            processData={paymentDetails}
            acceptCurrentCard={processData.acceptCurrentCard}
            registerLater={processData.registerLater}
            restructured={loanData.restructured}
            hasRedirect={hasRedirect}
          />
        );
      case -1:
        if (loanData.restructured) {
          return (
            <StepDeclineRest
              acceptLoan={loanData}
              hasRedirect={hasRedirect}
            />
          );
        }
        return (
          <StepDecline
            acceptLoan={loanData}
            hasRedirect={hasRedirect}
          />
        );
      case -2:
        return <StepError hasRedirect={hasRedirect} />;
      default:
        return <div />;
    }
  }

  const previousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const nextStep = (updateData = null) => {
    let acceptCurrentCard = false;
    let registerLater = false;
    let paymentMethod = null;

    if (updateData !== null) {
      setProcessData(prevState => ({
        ...prevState,
        ...updateData
      }));

      if (updateData.acceptCurrentCard) {
        acceptCurrentCard = updateData.acceptCurrentCard;
      }

      if (updateData.registerLater) {
        registerLater = updateData.registerLater;
      }

      if (updateData.paymentMethod) {
        paymentMethod = updateData.paymentMethod;
      }
    }

    switch (activeStep) {
      case 3: {
        if (loanData.showQuestion) {
          setActiveStep(4);
        } else if (loanData.creditCardNumber !== null) {
          setActiveStep(5);
        } else {
          setActiveStep(6);
        }
        break;
      }
      case 4: {
        if (loanData.creditCardNumber !== null) {
          setActiveStep(5);
        } else {
          setActiveStep(6);
        }
        break;
      }
      case 5: {
        if (acceptCurrentCard) {
          setActiveStep(7);
          setIsFlowOver(true);
          setPaymentIsLoading(true);
          sendPayment(null, 'source');
        } else {
          setActiveStep(6);
        }
        break;
      }
      case 6: {
        setPaymentIsLoading(true);
        if (registerLater) {
          sendPayment(null, 'email');
        }

        if (paymentMethod) {
          sendPayment(paymentMethod, 'source');
        }
        setActiveStep(7);
        setIsFlowOver(true);
        break;
      }
      case 7: {
        setIsFlowOver(true);
        break;
      }
      default: {
        setActiveStep(activeStep + 1);
        break;
      }
    }
  };

  const sendPayment = (token: string | number, source: string) => {
    dispatch(recalculateValues({
      loanNumber: loanData?.loanNumber,
      duration: processData.duration,
    }))
      .then(() => {
        setPaymentIsLoading(true);
        return dispatch(comfirmAcceptLoan({
          reepayToken: {
            token,
            installmentLoanNumber: loanData.loanNumber,
            signupMethod: source,
            creditFacilityNumber: loanData.creditFacilityNumber,
          },
          clientFirstTimeAtClinic: null,
          duration: processData.duration,
        }))
          .then(() => {
            dispatch(sendAgreement(agreement));
          });
      })
      .then(() => {
        dispatch(statusAcceptLoan(loanData.loanNumber, {
          status: 'ACCEPTED',
        }));
        //show the last step only when everthing was succesfull
        setPaymentIsLoading(false);
        setActiveStep(7);
        setIsFlowOver(true);
      })
      .catch(failureCallback);
  };

  const failureCallback = () => {
    setPaymentIsLoading(false);
    triggerErrorStep();
  };

  const updateProcessData = (data: LoanDataOutput) => {
    setPaymentDetails(data);
  }

  const renderErrorContent = (loanStatus: string) => {
    const phoneNumber =
      strings.getLanguage() === 'en'
        ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
        : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');
    switch (loanStatus) {
      case 'EXPIRED':
        document.title = strings.exceptionExpiredTitle;
        return (
          <ErrorPage>
            <h1>{strings.exceptionExpiredTitle}</h1>
            <p>{strings.exceptionExpiredText1}</p>
            <p>
              {strings.formatString(
                strings.exceptionExpiredText2,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,

                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber}</a>
              )}
            </p>
            {hasRedirect && (
              <Button
                type='button'
                className='btn btn-blue w-100 mt-5'
                onClick={() => window.close()}
              >
                {strings.close}
              </Button>
            )}
          </ErrorPage>
        );
      case 'DELETED':
      case 'NOT_ACCEPTED':
        document.title = strings.exceptionDeletedTitle;
        return (
          <ErrorPage>
            <h1>{strings.exceptionDeletedTitle}</h1>
            <p>{strings.exceptionDeletedText1}</p>
            <p>{strings.exceptionDeletedText2}</p>
            <p>{strings.exceptionDeletedText3}</p>
            <p>
              {strings.formatString(
                strings.exceptionDeletedText4,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber}</a>
              )}
            </p>
            {hasRedirect && (
              <Button
                type='button'
                className='btn btn-blue w-100 mt-5'
                onClick={() => window.close()}
              >
                {strings.close}
              </Button>
            )}
          </ErrorPage>
        );
      case 'NOT_FOUND':
        return (
          <ErrorPage>
            <h1>{strings.exceptionNotFoundTitle}</h1>
            <p>{strings.exceptionNotFoundText1}</p>
            <p>
              {strings.formatString(
                strings.exceptionNotFoundText2,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber} </a>
              )}
            </p>
            {hasRedirect && (
              <Button
                type='button'
                className='btn btn-blue w-100 mt-5'
                onClick={() => window.close()}
              >
                {strings.close}
              </Button>
            )}
          </ErrorPage>
        );
      case 'ACCEPTED':
      case 'PAYED':
      case 'SETTLED':
      case 'CONSOLIDATED':
        document.title = strings.exceptionComfirmedTitle;
        return (
          <ErrorPage>
            <h1>{strings.exceptionComfirmedTitle}</h1>
            <p>{strings.exceptionComfirmedText1}</p>
            <p>{strings.exceptionComfirmedText2}</p>
            <p>
              {strings.formatString(
                strings.exceptionComfirmedText3,
                <a href={`mailto:${brandConfig.contactEmail}`}>
                  {brandConfig.contactEmail}
                </a>,
                <a href={`tel:${brandConfig.contactPhone}`}>{phoneNumber} </a>
              )}
            </p>
            {hasRedirect && (
              <Button
                type='button'
                className='btn btn-blue w-100 mt-5'
                onClick={() => window.close()}
              >
                {strings.close}
              </Button>
            )}
          </ErrorPage>
        );
      default:
        if (brandEnvVariable === 'Denti') {
          document.title = 'Denti';
        }
        if (brandEnvVariable === 'Sundhedplus') {
          document.title = 'Sundhed+';
        }
        return <div />;
    }
  }

  const iframeStyle = source === 'portal';
  const directionClassName = activeStep > 0 ? 'move-next' : 'move-prev';
  if (showErrorContent) {
    return renderErrorContent(loanData.status);
  } else {
    return (
      <div
        id='acceptance-flow'
        className={`flow-wrapper ${iframeStyle ? 'iframe-acceptance-styles' : ''
          }`}
      >
        {isLoading ? (
          <LoadingMessage />
        ) : (
          <TransitionGroup
            appear
            timeout={600}
            className={`slide-group ${directionClassName} step-${activeStep
              } ${paymentIsLoading ? 'loading-payment' : ''}`}
          >
            <CSSTransition
              key={activeStep}
              appear
              timeout={300}
              classNames='slide'
              className='transition-container'
            >
              <Fade show={!isLoading}>
                {brandEnvVariable === 'Denti' && (
                  <Card className='flow-container'>
                    <CardHeader>
                      {activeStep > 0 ? (
                        <div className='d-logo logo-green'>
                          <i className='di di-denti' />
                        </div>
                      ) : (
                        <div className='d-logo logo-red'>
                          <i className='di di-cancel' />
                        </div>
                      )}
                    </CardHeader>
                    <CardBody>
                      {paymentIsLoading ? (
                        <LoadingMessage message={strings.loadingMessage} />
                      ) : (
                        renderActiveStep()
                      )}
                    </CardBody>
                    <CardFooter>
                      {paymentIsLoading ? null : (
                        <div className='steps'>
                          {!isFlowOver ? (
                            Array.from(
                              { length: totalSteps },
                              (_, index) => index
                            ).map((el) => (
                              <div
                                key={el}
                                className={`bullet-step ${activeStep - 1 >= el
                                  ? 'active'
                                  : ''
                                  }`}
                              />
                            ))
                          ) : (
                            <p>{moment().format('DD-MM-YYYY HH:mm:ss')}</p>
                          )}
                        </div>
                      )}
                    </CardFooter>
                    {activeStep === 4 && (
                      <div className='visit-guy-container'>
                        <img
                          src={happyGuy}
                          alt='happyguy'
                          className='image-visit-guy'
                        />
                      </div>
                    )}
                  </Card>
                )}
                {brandEnvVariable === 'Sundhedplus' && (
                  <div className='sundhed-wrapper'>
                    {hasLogo && (
                      <div className='sundhed-plus-logo'>
                        <img
                          className='sundhed-logo'
                          src={sundhedLogo}
                          alt='Sundhed+'
                        />
                      </div>
                    )}
                    <Card className='flow-container'>
                      <CardBody>
                        {paymentIsLoading ? (
                          <LoadingMessage message={strings.loadingMessage} />
                        ) : (
                          renderActiveStep()
                        )}
                      </CardBody>
                      <CardFooter>
                        {!paymentIsLoading && (
                          <div className='steps'>
                            {!isFlowOver ? (
                              Array.from(
                                { length: totalSteps },
                                (_, index) => index
                              ).map((el) => (
                                <div
                                  key={el}
                                  className={`bullet-step ${activeStep - 1 >= el
                                    ? 'active'
                                    : ''
                                    }`}
                                />
                              ))
                            ) : (
                              <p>{moment().format('DD-MM-YYYY HH:mm:ss')}</p>
                            )}
                          </div>
                        )}
                      </CardFooter>
                      {activeStep === 4 && (
                        <div className='visit-guy-container'>
                          <img
                            src={happyGuy}
                            alt='happyguy'
                            className='image-visit-guy'
                          />
                        </div>
                      )}
                    </Card>
                  </div>
                )}
              </Fade>
            </CSSTransition>
          </TransitionGroup>
        )}
      </div>
    );
  }
}
export default LoanAcceptance;
