import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { strings } from '../../constants/localization';
import { isManager, isDentist } from '../../constants/utils';
import { setCurrentManagerCompany } from '../../actions/companyActions';
import CompaniesSettings from './companies/CompaniesSettings';

const Settings = ({ toggleMenu }) => {
  const [displayComponent, setDisplayComponent] = useState('companies');
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.user);

  const renderSubmenuListItem = (element, index) => {
    return (
      <li
        key={index}
        className={
          displayComponent === element.sectionName
            ? 'active'
            : element.sectionName === 'users'
            ? 'default'
            : ''
        }
        onClick={() => setDisplayComponent(element.sectionName)}
      >
        {element.linkText}
      </li>
    );
  };

  const renderSubmenu = (headlineText, linksArray, linkCallback) => {
    return (
      <div className='subnav'>
        <h4 className='settings-headline'>{headlineText}</h4>
        <menu onClick={linkCallback !== undefined ? linkCallback : false}>
          {linksArray.map(renderSubmenuListItem)}
        </menu>
      </div>
    );
  };

  return (
    <div id='SettingsComponent'>
      <Row>
        <Col xs='12' md='12' xl='3' className='entities-list-section p-0'>
          <div className='header'>
            <button
              type='button'
              className='btn btn-white menu-toggler'
              onClick={toggleMenu}
            >
              <div className='menu-toggler-icon'>
                <span />
                <span />
                <span />
              </div>
            </button>
          </div>
          <aside>
            {/* {renderSubmenu(strings.settingsHeadline1, [
              {
                sectionName: 'profile',
                linkText: strings.settingsHeadline1Link1,
              },
              {
                sectionName: 'users',
                linkText: strings.settingsHeadline1Link2,
              },
            ])} */}

            {isManager(currentUser) &&
              renderSubmenu(
                strings.settingsHeadline2,
                [
                  // {
                  //   sectionName: 'masterData',
                  //   linkText: strings.settingsHeadline2Link1,
                  // },
                  {
                    sectionName: 'companies',
                    linkText: strings.settingsHeadline2Link2,
                  },
                ],
                () => dispatch(setCurrentManagerCompany(undefined))
              )}

            {isDentist(currentUser) &&
              renderSubmenu(
                strings.settingsHeadline2,
                [
                  // {
                  //   sectionName: 'masterData',
                  //   linkText: strings.settingsHeadline2Link1,
                  // },
                  {
                    sectionName: 'companies',
                    linkText: strings.settingsHeadline2Link2Single,
                  },
                ],
                () => dispatch(setCurrentManagerCompany(undefined))
              )}

            {/* {renderSubmenu(strings.settingsHeadline3, [
              {
                sectionName: 'smartLink',
                linkText: strings.settingsHeadline3Link1,
              },
              {
                sectionName: 'advertise',
                linkText: strings.settingsHeadline3Link2,
              },
            ])} */}
          </aside>
        </Col>
        <Col xs='12' md='12' xl='9' className='entity-details-section active'>
          <Scrollbars
            renderTrackHorizontal={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  display: 'none',
                }}
              />
            )}
          >
            <div className='animation-wrapper'>
              {/* PROFILE */}
              {/* {displayComponent === 'profile' && (
              <Row>
                <Col className='col-lg-12'>
                  <h1 className='headline'>{strings.settingsHeadline1Link1}</h1>
                  <Card className='custom-card'>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row>
            )} */}

              {/* USERS */}
              {/* {displayComponent === 'users' && (
              <Row>
                <Col className='col-lg-12'>
                  <h1 className='headline'>{strings.settingsHeadline1Link2}</h1>
                  <Card className='custom-card'>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row>
            )} */}
              {/* MASTER DATA */}
              {/* {displayComponent === 'masterData' && (
              <Row>
                <Col className='col-lg-12'>
                  <h1 className='headline'>{strings.settingsHeadline2Link1}</h1>
                  <Card className='custom-card'>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row>
            )} */}
              {/* COMPANIES */}
              <CompaniesSettings
                displayComponent={displayComponent}
                toggleMenu={toggleMenu}
              />

              {/* SMARTLINK */}
              {/* {displayComponent === 'smartLink' && (
              <Row>
                <Col className='col-lg-12'>
                  <h1 className='headline'>{strings.settingsHeadline3Link1}</h1>
                  <Card className='custom-card'>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row>
            )} */}
              {/* ADVERTISE */}
              {/* {displayComponent === 'advertise' && (
              <Row>
                <Col className='col-lg-12'>
                  <h1 className='headline'>{strings.settingsHeadline3Link2}</h1>
                  <Card className='custom-card'>
                    <CardBody></CardBody>
                  </Card>
                </Col>
              </Row>
            )} */}
            </div>
          </Scrollbars>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
